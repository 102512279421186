import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import RadioCard from '@separate/components/CTA/RadioCard';
import { ADDING_DENTAL_PRODUCT } from '@separate/constants/features';
import { PREMIUM_MEMBERSHIP_PRICE } from '@separate/constants/premium';
import { useTranslation } from '@separate/hooks/translation';
import { isFeatureEnabled } from '@separate/utilities/features';
import { asPercent } from '@separate/utilities/string';
import styles from '../SubscriptionOptionRadioCard/subscriptionOptionRadioCard.module.scss';
import PaymentPricing from './PaymentPricing';

export default function SubscriptionBundleRadioCard({
  subscriptionPrice,
  className,
  disabled,
  onClick,
  selected,
  subscriptionName,
  subscriptionInterval,
  subscriptionIntervalCount,
  bundleGroupDiscountPercentage,
  ...extraProps
}) {
  const { t } = useTranslation();
  const isFeatureDentalProduct = isFeatureEnabled(ADDING_DENTAL_PRODUCT);

  function handleClick() {
    if (!disabled && !selected && onClick) {
      onClick();
    }
  }

  const isAnnual = subscriptionInterval === 'year';
  const isCustomInterval = subscriptionIntervalCount && subscriptionIntervalCount !== 1;
  const annualPrice = isAnnual ? subscriptionPrice : subscriptionPrice * 12;

  // TODO: Hardcoding this for now to preserve discount display, will eventually
  // want to base this on the grouped bundles to determine the discount for the annual v monthly price
  const monthlyPrice = PREMIUM_MEMBERSHIP_PRICE;
  const discountPercentage = bundleGroupDiscountPercentage || asPercent(1 - (annualPrice / (monthlyPrice * 12)));

  const heading = React.useMemo(() => {
    return (
      isCustomInterval ? (
        <>
          {subscriptionName}
          {!isFeatureDentalProduct && (
            <div className={cx('flair', styles.flair)}>
              {t('premium.annual.discount_percentage', { discount: discountPercentage })}
            </div>
          )}
        </>
      ) : isAnnual ? (
        <>
          {!onClick ? 'ㅤ' : subscriptionName}
          {!isFeatureDentalProduct && (
            <div className={cx('flair', styles.flair)}>
              {t('premium.annual.discount_percentage', { discount: discountPercentage })}
            </div>
          )}
        </>
      ) : (
        <>
          {subscriptionName}
          {!isFeatureDentalProduct && (
            <span className={cx('payFullPrice', styles.payFullPrice)}>
              {t('premium.annual.pay_full_price')}
            </span>
          )}
        </>
      )
    );
  }, [isCustomInterval, subscriptionName, isFeatureDentalProduct, t, discountPercentage, isAnnual, onClick]);

  const footerText = () => {
    if(isCustomInterval) {
      return t('premium.annual.billed_custom', { intervalCount: subscriptionIntervalCount });
    }
    else if(isAnnual) {
      return t('premium.annual.billed_annually');
    }
    else {
      return t('premium.annual.billed_monthly');
    }
  };

  return (
    <RadioCard
      className={cx(
        className,
        'subscriptionOptionRadioCard',
        styles.subscriptionOptionRadioCard,
        {
          [styles.selected]: selected,
          'selected': selected,
          [styles.disabled]: disabled,
          'disabled': disabled,
        },
      )}
      handleClick={handleClick}
      selected={selected}
      {...extraProps}
    >
      <div className={cx('heading', styles.heading, 'd-flex flex-row, justify-content-between mb-2')}>
        {heading}
      </div>
      <PaymentPricing
        subscriptionPrice={subscriptionPrice}
        className={cx({ [styles.paymentPricing]: disabled })}
        isAnnual={isAnnual}
        subscriptionIntervalCount={subscriptionIntervalCount}
      />
      <div
        className={cx('footer', styles.footer)}
        dangerouslySetInnerHTML={{ __html: footerText() }}
      />
    </RadioCard>
  );
}

SubscriptionBundleRadioCard.propTypes = {
  subscriptionPrice: PropTypes.number,
  subscriptionInterval: PropTypes.string,
  subscriptionName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  subscriptionIntervalCount: PropTypes.number,
  bundleGroupDiscountPercentage: PropTypes.string,
};
