const address = {
  address_line_1: "Address",
  address_line_2: "Apt, Suite, or Unit",
  city: "City",
  state: "State",
  zipcode: "ZIP",
  verification: {
    is_this_correct: "Is this correct?",
    not_found_title: "Address not valid",
    not_found_description: "We couldn’t locate your address. Please try again.",
    yes: "Next",
    no_edit_address: "Edit",
    edit_address: "Edit",
  },
};

export default address;
