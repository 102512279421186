import useInputFormState from '@separate/hooks/useInputFormState';
import { required, confirmation } from '@separate/validations';

const VALIDATIONS = {
  confirmation: {
    translationKey: 'login.code',
    rules: [required, confirmation],
  },
};

export default function useConfirmationInputFormState() {
  return useInputFormState(VALIDATIONS);
}
