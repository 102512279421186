import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from '@separate/hooks/translation';
import { Meatballs } from '@separate/icons';
import brandMapping from './brandMapping';
import styles from './paymentMethodInfoCard.module.scss';

export default function PaymentMethodInfoCard({ method, onClick, primary, selected }) {
  const { t } = useTranslation();

  function formatExpirationDate(card) {
    return `${formatMonth(card.exp_month)}/${formatYear(card.exp_year)}`;
  }

  function formatMonth(month) {
    return String(month).padStart(2, '0');
  }

  function formatYear(year) {
    return String(year).slice(-2);
  }

  function handleClick() {
    if (primary) {
      return;
    }

    onClick?.(method.id);
  }

  const isWallet = !!method.card.wallet;
  const brandKey = isWallet ? method.card.wallet.type : method.card.brand;

  const brand = brandMapping[brandKey];
  const BrandIcon = brand.icon;

  return (
    <div
      className={cx(styles.infoCard, { [styles.primary]: primary, [styles.selected]: selected })}
      onClick={handleClick}
    >
      {primary && (
        <Badge pill className={styles.pill}>
          {t('user.profile.default')}
        </Badge>
      )}
      <div className={styles.cardIcon}>{BrandIcon}</div>
      {isWallet ? (
        <div className={styles.wallet}>
          {`${brand.name}`}
        </div>
      ) : (
        <div>
          <div className={styles.cardNumber}>
            {`${brand.name} xxxx ${method.card.last4}`}
          </div>
          <div className={styles.expiration}>
            {`${t('user.profile.expires_on')} ${formatExpirationDate(method.card)}`}
          </div>
        </div>
      )}
      {!primary && !!onClick && (
        <Meatballs className={styles.meatballs} />
      )}
    </div>
  );
}

PaymentMethodInfoCard.propTypes = {
  method: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  selected: PropTypes.bool,
};
