import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

export function compact(object) {
  return omitBy(object, isNil);
}

export function slice(object, desiredKeys) {
  return pick(object, desiredKeys);
}

export function extractDataProps(object) {
  const dataKeys = Object.keys(object).filter(key => key.startsWith('data-'));
  return slice(object, dataKeys);
}
