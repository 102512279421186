import { IS_ZENDESK, IS_WELCOME4WORK } from '@separate/env';

const checkFrontendsProjectName = () => {
  if(IS_ZENDESK) return 'zendesk';
  else if(IS_WELCOME4WORK) return 'welcomeForWork';
  else return 'sep';
};

export async function importApi(moduleName) {
  const importedApi = await import(`@separate/api/${checkFrontendsProjectName()}/${moduleName}`);
  return importedApi;
}

export function isProjectNameWelcomeForWork(){
  return IS_WELCOME4WORK ? true: false;
}
