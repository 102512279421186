import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';

export default function Modal(props) {
  const { loading, children, close, show, title, onHide, bodyClassName, ...extraProps } = props;

  return (
    <BootstrapModal show={show} onHide={onHide} centered {...extraProps}>
      {(title || close) && (
        <BootstrapModal.Header closeButton={close}>
          {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
        </BootstrapModal.Header>
      )}
      <BootstrapModal.Body className={bodyClassName}>
        {children}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}

Modal.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  close: PropTypes.bool,
  show: PropTypes.bool,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  bodyClassName: PropTypes.string,
};

Modal.defaultProps = {
  close: true,
};
