import useInputFormState from '@separate/hooks/useInputFormState';
import { required, phone, name } from '@separate/validations';

const VALIDATIONS = {
  first_name: {
    translationKey: 'user.first_name',
    rules: [required, name],
  },
  last_name: {
    translationKey: 'user.last_name',
    rules: [required, name],
  },
  phone_number: {
    translationKey: 'user.phone_number',
    rules: [required, phone],
  },
};

export default function useRegistrationInputFormState() {
  return useInputFormState(VALIDATIONS);
}
