const qa = {
  title: "Your Trusted Advisor",
  delivered: "Delivered",
  error: "We encountered a problem retrieving your history. Please refresh your page.",
  no_authn: "Do you have questions? We have answers! Our experts are here to help you through our free service. Get started using the form below.",
  no_history: "Ask a question. We’ve got the answers you need.",
  input_placeholder: "Send message...",
  today: "Today",
  yesterday: "Yesterday",
};

export default qa;
