import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import { CloseCircle } from '@separate/icons';
import { idType } from '@separate/types';
import styles from './index.module.scss';
import Login from './Login';

export default function NewLoginModal({ show, onHide, onLoginSuccess, onAbandon, providedPhoneNumber }) {
  const handleLoginSuccess = () => {
    onLoginSuccess?.();
    onHide();
  };

  const handleAbandon = () => {
    onAbandon?.();
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={handleAbandon}
      bodyClassName={styles.body}
      className={styles.modal}
      close={false}
      fullscreen="md-down"
    >
      <div className={styles.headerBackground}>
        <img
          src={require('@images/header-background.png').default.src}
          alt="header background"
        />
      </div>
      <Button
        iconButton
        onClick={handleAbandon}
        className={styles.closeButton}
      >
        <CloseCircle />
      </Button>
      <Login
        onSuccess={handleLoginSuccess}
        providedPhoneNumber={providedPhoneNumber}
      />
    </Modal>
  );
}

NewLoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func,
  onAbandon: PropTypes.func,
  providedPhoneNumber: idType,
};

NewLoginModal.defaultProps = {
  providedPhoneNumber: "",
};
