import React from 'react';
import PropTypes from 'prop-types';
import MissedPaymentBanner from './MissedPaymentBanner';
import MissedPaymentModal from './MissedPaymentModal';

export default function MissedPayment(props) {
  const {
    userHasMissedPayment,
    showMissedPaymentModal,
    setShowMissedPaymentModal,
    showMissedPaymentBanner,
    setShowMissedPaymentBanner,
  } = props;

  return (
    <>
      {(showMissedPaymentBanner && userHasMissedPayment) && (
        <MissedPaymentBanner />
      )}
      <MissedPaymentModal
        show={showMissedPaymentModal}
        onHide={() => setShowMissedPaymentModal(false)}
        setShowMissedPaymentBanner={setShowMissedPaymentBanner}
      />
    </>
  );
}

MissedPayment.propTypes = {
  userHasMissedPayment: PropTypes.bool,
  showMissedPaymentModal: PropTypes.bool,
  setShowMissedPaymentModal: PropTypes.func,
  showMissedPaymentBanner: PropTypes.bool,
  setShowMissedPaymentBanner: PropTypes.func,
};
