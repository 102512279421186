import * as React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import BootstrapButton from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

export default function Button(props) {
  const { loading, disabled, onClick, className, children, ...extraProps } =
    props;

  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <BootstrapButton
      disabled={loading || disabled}
      onClick={handleClick}
      className={cx(className)}
      {...extraProps}
    >
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>{children}</>
      )}
    </BootstrapButton>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
