import React from 'react';
import PropTypes from 'prop-types';
import InfoRow from './InfoRow';

export default function Name(props) {
  const { firstName, lastName } = props;

  return (
    <>
      <InfoRow label="user.first_name">
        <span>{firstName}</span>
      </InfoRow>
      <InfoRow label="user.last_name">
        <span>{lastName}</span>
      </InfoRow>
    </>
  );
}

Name.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
