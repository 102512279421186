import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import AddressInput from '@/components/address/AddressInput';
import Button from '@/components/Button';
import FieldErrorsList from '@/components/FieldErrorsList';
import GenderSelect from '@/components/GenderSelect';
import { inputFormStateType, addressVerificationStateType } from '@/types';
import TextInput from '@separate/components/TextInput';
import { useTranslation } from '@separate/hooks/translation';
import styles from '../editViews.module.scss';

export default function InputForm(props) {
  const {
    isPoderCardUser,
    loading,
    inputFormState,
    addressVerificationState,
    onSubmit,
  } = props;

  const { t } = useTranslation();
  const disabled = loading || isPoderCardUser;

  return (
    <div>
      <div>
        <TextInput
          type="text"
          name="first_name"
          disabled={disabled}
          inputFormState={inputFormState}
          className='mt-2'
        />

        <TextInput
          type="text"
          name="last_name"
          disabled={disabled}
          inputFormState={inputFormState}
          className='mt-2'
        />

        <TextInput
          type="tel"
          name="phone_number"
          mask="+1 (999) 999-9999"
          unmasked
          disabled={true}
          inputFormState={inputFormState}
          placeholder={t('user.phone_number')}
          className='mt-2'
        />

        <TextInput
          type="email"
          name="email"
          disabled={disabled}
          inputFormState={inputFormState}
          className='mt-2'
        />

        <GenderSelect
          name="gender"
          disabled={disabled}
          inputFormState={inputFormState}
          className='mt-2'
        />

        <AddressInput
          disabled={disabled}
          inputFormState={inputFormState}
          addressVerificationState={addressVerificationState}
          className="mt-2"
        />

        <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      </div>

      <div className='w-100 mt-3 d-flex justify-content-center'>
        <Button
          onClick={onSubmit}
          disabled={!inputFormState.isValid || disabled}
          loading={loading}
          className={cx(
            styles.submitCTA,
            styles.ctaMargin,
          )}
        >
          {t('user.profile.submit_cta')}
        </Button>
      </div>
    </div>
  );
}

InputForm.propTypes = {
  isPoderCardUser: PropTypes.bool,
  loading: PropTypes.bool,
  inputFormState: inputFormStateType,
  addressVerificationState: addressVerificationStateType,
  hasFullInfo: PropTypes.bool,
  onSubmit: PropTypes.func,
};

InputForm.defaultProps = {
  inputFormState: {},
};
