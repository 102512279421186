import baseAuthAdminQuery from "@separate/api/sep/baseAuthAdminQuery";

export async function addDependent({ data, locale }) {
  const path = `/dependents`;

  const response = await baseAuthAdminQuery({
    method: 'POST',
    path,
    data,
    locale,
  });

  return response?.data;
}

export async function getDependents() {
  const path = `/dependents`;

  const response = await baseAuthAdminQuery({
    method: 'GET',
    path,
  });

  return response?.data;
}
