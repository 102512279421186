import address from './address';
import benefits from './benefits';
import common from './common';
import login from './login';
import premium from './premium';
import user from './user';
import validations from './validations';

const en = {
  translation: {
    ...common,
    common,
    address,
    benefits,
    login,
    premium,
    user,
    validations,
  },
};

export default en;
