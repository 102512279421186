import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Link from 'next/link';

export default function FooterLinks(props) {
  const { title, links } = props;

  return (
    <div className={styles.footerLinks}>
      <div>
        <h3 className={styles.title}>{title}</h3>
        {links?.map((item, index) => (
          <Link href={item.href} key={index}>
            <a target={item.target}>{item.text}</a>
          </Link>
        ))}
      </div>
    </div>
);
}

FooterLinks.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array
};
