import React from 'react';
import { useRouter } from 'next/router';
import { UPDATE_PAYMENT } from '@/constants/profile';
import { useCurrentUser } from '@separate/hooks/user';

export const useDeepLinkUpdatePaymentModal = () => {
  const [showDeepLinkUpdatePaymentModal, setShowDeepLinkUpdatePaymentModal] = React.useState(false);
  const { query } = useRouter();
  const { user } = useCurrentUser();
  const userIsPremiumMember = user?.is_premium_member;

  React.useEffect(() => {
    if (userIsPremiumMember && query.hasOwnProperty(UPDATE_PAYMENT)) {
      setShowDeepLinkUpdatePaymentModal(true);
    }
  }, [query, userIsPremiumMember]);

  const hideDeepLinkUpdatePaymentModal = () => {
    setShowDeepLinkUpdatePaymentModal(false);
  };

  return { showDeepLinkUpdatePaymentModal, hideDeepLinkUpdatePaymentModal };
};
