const common = {
  back: 'Atrás',
  close: "Cerrar",
  dismiss: "Cerrar",
  down: "Abajo",
  hello: "Hola",
  next: "Siga",
  up: "Hasta",
};

export default common;
