import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import v4_en from './locales/en';
import v4_es from './locales/es';
import en from '@/locales/en';
import es from '@/locales/es';

en.translation["v4"] = v4_en.translation;
es.translation["v4"] = v4_es.translation;

i18n
  .use(initReactI18next)
  .init({
    resources: { en, es },
    fallbackLng: 'es',
    debug: false,
    compatibilityJSON: 'v3',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span', 'sup'],
    },
  });

export default i18n;
