const surveys = {
  access: {
    back: "Return to",
    error_subtitle: "Thank you for applying to this survey. Don't hesitate to try out another survey in Rewards.",
    footer: "Get access to your funds faster with the PODERcard bank account",
    inactive: "We are sorry, this survey is no longer available. We recommend answering surveys as soon as possible after receiving to ensure participation. You will be receiving more survey opportunities soon. Thank you!",
    inaccessible: "Sorry, you did not qualify for this survey.",
    rewards: "Rewards",
    notFound: "Survey not found.",
    other_surveys: "Please try again with another survey below",
    untakeable: "You already completed this survey.",
  },
  copy: "Start earning cash",
  cta: "Take survey",
  header: "Survey Center",
  insert_text: "Insert Text",
  numeric_freeform: {
    placeholder: "Enter Amount",
    error_any_number: "Please enter any positive number or zero",
    error_with_range: "Please submit an answer between {{ min }} and {{ max }}",
  },
  qualify_title: "Available surveys",
  qualify_subtitle: "Earn rewards for sharing your opinion!",
  reward: "Discover if you qualify for paid surveys at different marketplaces we work with!",
  spotlight: "Survey spotlight",
  text_freeform: {
    placeholder: "Enter your response",
  },
  time: "10-15 min",
  next_question: "Next",
  completed: {
    title: "Well done, {{ name }}",
    title_insights: "Thank you, {{ name }}!",
    subtitle: "You completed",
    added_balance: "added to balance",
    added_to_podercard: "added to your PODERcard",
    view_in_app: "View in the app",
  },
  declined: {
    title: "Thank you,",
  },
  footer_default_value: "Questions or need help? Call us at 844-447-2237",
  lucid_modal: {
    title: "Attention!",
    subtitle: "You’re leaving SABEResPODER and being redirected to Lucid, a partner survey marketplace. There you’ll have the opportunity to earn more rewards, but keep in mind:",
    content: {
      text_one: "We don’t control who qualifies for Lucid’s surveys, and the earnings are not guaranteed until you answer a set of questions to get qualified to participate in each survey.",
      text_two: "Our integration with Lucid allows you to collect their rewards here in our Rewards Center.",
    },
    cta: "Continue to Lucid",
    back: "Go back",
  },
};

export default surveys;
