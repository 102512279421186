import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@v4/src/components/Button';
import { inputFormStateType } from '@separate/types';
import styles from './index.module.scss';
import TextInput from '@separate/v4/components/TextInput';
import FieldErrorsList from '@separate/v4/components/FieldErrorsList';
import { useTranslation } from '@separate/hooks/translation';

export default function InputForm({ children, inputFormState, onSubmit, loading, disabled }) {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        type="text"
        inputId="first_name"
        inputFormState={inputFormState}
        className={styles.input}
      />

      <TextInput
        type="text"
        inputId="last_name"
        inputFormState={inputFormState}
        className={styles.input}
      />

      <TextInput
        type="tel"
        mask="(999) 999-9999"
        unmasked
        inputId="phone_number"
        inputFormState={inputFormState}
        className={styles.input}
      />

      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      { children }
      <div className="my-2 text-center">
        <Button
          loading={loading}
          onClick={onSubmit}
          className={styles.button}
          disabled={disabled}
        >
          {t('login.sign_up')}
        </Button>
      </div>
    </>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

InputForm.defaultProps = {
  inputFormState: {},
};
