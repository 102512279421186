const articles = {
  published: "Publicado",
  by: "Por",
  other: "Otras",
  template: {
    return_to_guides: "Regreso a Guías"
  },
  index: {
    articles_title: "Guías",
    filter_by_category: "Filtrar por categoría",
    featured: "Destacado",
    all: "Otras guías"
  },
  categories: {
    coronavirus: "coronavirus",
    finance: "finanzas",
    health: "salud",
    education: "educación",
    legal: "leyes",
    benefits: "beneficios",
  },
  guides: {
    coronavirus: "Otras guías de coronavirus",
    finance: "Otras guías de finanzas",
    health: "Otras guías de salud",
    education: "Otras guías de educación",
    legal: "Otras guías de leyes",
    benefits: "Otras guías de beneficios",
  },
  article_image: 'Imagen del Artículo',
  no_results: "No se han econtrado resultados para",
  search: "Buscar",
  search_results: "Resultados para",
  social_share: {
    copy_link: "¡Enlace copiado!"
  },
  premium: {
    message: {
      title: "Este contenido exclusivo es para Miembros Premium",
      cta: "Conviértete en Miembro Premium",
      member: "¿Ya eres Miembro Premium?",
      link: "Registrarse aquí",
      home: "Cerrar"
    }
  },
};

export default articles;
