import React from 'react';
import Head from 'next/head';
import { useGetUrl } from '@separate/hooks/getUrl';
import { useLocale } from '@separate/hooks/translation';

export default function HeadData() {
  const { canonicalUrl } = useGetUrl();
  const { locale } = useLocale();

  const ogLocale = locale === 'en' ? 'en_US' : 'es_ES';

  return (
    <Head>
      <title>SABEResPODER</title>
      <meta name="description" content="SABEResPODER" key="description" />
      <meta name="google" content="notranslate" />
      <link rel="shortcut icon" href="/favicon.ico?v=2" />
      <link rel="canonical" href={canonicalUrl} />
      <meta content="SABEResPODER" property="og:site_name" />
      <meta content={canonicalUrl}  property="og:url" />
      <meta content={ogLocale} property="og:locale" />
    </Head>
  );
}
