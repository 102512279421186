// This is largely pilfered from https://www.w3schools.com/js/js_cookies.asp

export function setCookie(name, value, expirationSeconds, otherAttributes = {}) {
  const date = new Date();
  if (expirationSeconds) {
    date.setTime(date.getTime() + expirationSeconds * 1000);
  }
  const expires = expirationSeconds ? `expires=${date.toUTCString()}` : '';
  const secure = otherAttributes.domain ? 'secure' : '';
  let otherAttrsString = '';
  for (const [key, value] of Object.entries(otherAttributes)) {
    otherAttrsString += `;${key}=${value}`;
  }
  if (typeof window !== 'undefined') {
    document.cookie = `${name}=${JSON.stringify(value)};${expires};${secure};path=/${otherAttrsString}`;
  }
}

export function getCookie(name) {
  const match = `${name}=`;
  if (typeof window === 'undefined') return null;
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    let string = cookie;
    while (string.charAt(0) === " ") {
      string = string.substring(1);
    }
    if (string.indexOf(match) == 0) {
      return JSON.parse(string.substring(match.length, string.length));
    }
  }
  return null;
}
