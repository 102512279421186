import baseAuthAdminQuery from './baseAuthAdminQuery';
import baseUnauthAdminQuery from './baseUnauthAdminQuery';

export async function getCurrentUser() {
  const response = await baseAuthAdminQuery({
    path: 'users/current',
    method: 'GET',
  });

  return response?.data;
}

export async function getUserProfile() {
  const response = await baseAuthAdminQuery({
    path: '/profile',
    method: 'GET',
  });
  return response?.data;
}

export async function updateUserProfile({ user, locale }) {
  const response = await baseAuthAdminQuery({
    path: '/profile',
    method: 'POST',
    data: {
      user,
    },
    locale,
  });
  return response?.data;
}

export async function postUserReferral({ userId }) {
  const path = `users/${userId}/referrals`;
  const response = await baseAuthAdminQuery({
    path,
    method: 'POST',
  });

  return response?.data;
}

export async function getUserReferrals({ userId }) {
  const path = `users/${userId}/referrals`;
  const response = await baseAuthAdminQuery({
    path,
    method: 'GET',
  });

  return response?.data;
}

export async function getReferral(referralCode) {
  const path = `referrals/${referralCode}`;
  const response = await baseUnauthAdminQuery({
    path,
    method: 'GET',
  });

  return response?.data;
}

export async function getReferralDefaultRewards() {
  const path = 'referrals/default_rewards';
  const response = await baseUnauthAdminQuery({
    path,
    method: 'GET',
  });

  return response?.data;
}

export async function postUserProductParticipation(id, interestType, utm_tags) {
  const response = await baseAuthAdminQuery({
    path: '/users/products',
    method: 'POST',
    data: {
      id: id,
      interest_type: interestType,
      utm_tags: utm_tags,
    },
  });

  return response?.data;
}

export const updateUserPanelistStatus = async (userSelection) => {
  const path = 'panelist';
  const response = await baseAuthAdminQuery({
    path,
    method: userSelection ? 'POST' : 'DELETE',
  });

  return response?.data;
};

export const updateUserShortCodesStatus = async (data) => {
  const path = 'channels';
  const response = await baseAuthAdminQuery({
    path,
    method: 'POST',
    data,
  });

  return response?.data;
};

export const getUserShortCodesStatus = async () => {
  const path = 'channels';
  const response = await baseAuthAdminQuery({
    path,
    method: 'GET',
  });

  return response?.data;
};
