export function scrollToTop() {
  if (typeof window !== "undefined"){
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

export function scrollToView(ref) {
  if (ref?.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

export const getUserAgent = () => {
  if (typeof window === "undefined" || typeof navigator === "undefined") return null;

  return navigator.userAgent;
};
