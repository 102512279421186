const menu = {
  home: 'Home',
  earn: 'Rewards',
  chat: 'Chat',
  articles: 'Articles',
  premium: 'PODERsalud',
  farmaSEP: "farmaSEP",
  digital_banking: 'Digital banking',
  jobs_network: 'Jobs network',
  profile: 'Your account'
};

export default menu;
