const user = {
  first: 'Nombre',
  last: 'Apellido',
  first_name: "Nombre",
  last_name: "Apellido",
  email: "Correo electrónico",
  gender: "Género",
  phone_number: "Celular",
  birthday_with_format: "Fecha de nacimiento mm/dd/aaaa",
  gender_options: {
    male: "Hombre",
    female: "Mujer",
    unspecified: "Prefiero no decir",
  },
  culture_id: "Seleccione el país de origen de su familia",
  other_culture_name: "Especificar el país de origen de su familia",
  mexico_state_id: "¿De qué estado de México proviene su familia?",
  interests: "Intereses",
  language: "Idioma",
  language_options: {
    spanish: "Español",
    english: "Inglés",
  },
  profile: {
    account_info: "Detalles",
    add_card: "Agregar tarjeta",
    add_new_card: "Agregar tarjeta nueva",
    annual_interval: "año",
    annual_plan: "PODERsalud anual",
    cancel_edit_cta: "Cancelar",
    card_info: "Información de la tarjeta",
    change_payment: "Cambiar método de pago",
    default: "Preferido",
    delete: "Eliminar este método de pago",
    delete_payment: {
      body: "La información asociada con este método de pago será eliminada de forma permanente. Puede añadirla de nuevo después si desea.",
      cancel: "Volver",
      delete: "Eliminar",
      header: "Está a punto de eliminar un método de pago",
    },
    expires_on: "Vence el",
    make_default: "Establecer como método de pago preferido",
    monthly_interval: "mes",
    monthly_plan: "PODERsalud mensual",
    new_card_added: "¡Añadió una nueva tarjeta!",
    new_card_delay: "Su nueva tarjeta aparecerá en su perfil dentro de 5 minutos. ¡Gracias por su paciencia!",
    new_default_card: "Nueva tarjeta principal",
    new_default_delay: "Su nueva tarjeta aparecerá en su perfil dentro de 5 minutos. ¡Gracias por su paciencia!",
    next_billing: "Fecha de cobro",
    payment_cta_title: "Facturación",
    payment_method: "Método de pago",
    payment_methods: "Métodos de pago",
    plan: "Plan",
    price: "Precio",
    retry_card: "Reintentar con esta tarjeta",
    revise_payment: "Revise su método de pago",
    revise_subtitle: "Puede intentar de nuevo con su actual método de pago o cambiarlo.",
    submit_cta: "Aplicar cambios",
  },
  refer_a_friend: {
    earn: 'Reciba ${{ discount }}',
    refer_your_friends: '¡Invite a sus amigos a \nPODERsalud y <span>reciba ${{ discount }}!*</span>',
    refer_a_friend: 'Compartir',
    invite_your_friends: 'Recomiende PODERsalud y <span>reciba ${{ discount }}*</span> por cada amistad y familiar que se suscriba',
    start_earning: 'Comience a ganar',
    sms_message: "Hola! Te quiero recomendar PODERsalud. Si usas mi enlace, cada quien recibe ${{ discount }} de credito: {{ referralLink }} Suscribete!",
    how_does_it_work: '¿Cómo funciona?',
    your_reward: 'La recompensa de ${{ discount }} se añadirá como un crédito en su suscripción. ¡Invite tantas personas como desee para ganar más!',
    terms_and_conditions: '*Términos y condiciones del programa de referidos PODERsalud',
  },
  dependents: {
    title: 'Dependientes',
    dependent: 'Nombre completo {{ value }}',
    age: {
      title: '¿Su dependiente es menor de 18 años?',
    },
    type: {
      title: '¿Su dependiente es su cónyuge o hijo/a?',
    },
    no_dependents: 'Aún no hay dependientes que mostrar',
    add_dependent: 'Añadir dependiente',
    add_or_edit_dependent: {
      add_title: '¡Contáctenos!',
      edit_title: '¡Llámenos!',
      edit_text: 'Para editar o remover dependientes, por favor contacte al Equipo de Atención al Cliente al ',
      add_spouse_text: 'Para agregar a su cónyuge, por favor llame al Equipo de Atención al Cliente al ',
      add_child_text: 'Para agregar dependiente mayores a 18 años, por favor llame al Equipo de Atención al Cliente al ',
      contact: '(726) 202-1213',
    },
    dependent_information: 'Información del dependiente',
    birthday: 'Natalicio: {{ birthday }}',
    submit: 'Ingresar',
    success_modal: {
      title: '¡Ha añadido a su dependiente!',
      text: 'Para añadir dependientes o editar sus dependientes actuales, por favor vaya a su perfil.',
      cta: 'Cerrar',
    },
  },
};

export default user;
