import React from 'react';
import { useRegistration } from '@separate/hooks/login';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { getUtmTags } from '@separate/utilities/utmTagsStore';
import styles from './index.module.scss';
import InputForm from './InputForm';
import Terms from './Terms';
import useRegistrationInputFormState from './useRegistrationInputFormState';
import { isFeatureEnabled } from '@separate/utilities/features';
import { useResendCode } from '@separate/hooks/login';
import { logEvent } from '@separate/utilities/analytics';
import { RESEND_CODE_ERROR } from '@separate/constants/analytics';
import { useLoginFlow } from '../../loginFlowContext';

export default function RegistrationStep() {
  const locale = useLocale();
  const { t } = useTranslation();
  const prevent2faForNewUsers = isFeatureEnabled('prevent_2fa_for_new_users');
  const inputFormState = useRegistrationInputFormState();
  const { navigateTo, currentPhoneNumber, setCurrentPhoneNumber } = useLoginFlow();
  const [checked, setChecked] = React.useState(false);
  const { value: userInputs, setValue } = inputFormState;

  const { isLoading: isResendLoading, resendCode } = useResendCode({ onError: onResendError });
  function onResendError(err) {
    logEvent(RESEND_CODE_ERROR, { result: err });
  };

  const { isLoading: isRegistrationLoading, registration } = useRegistration({ onSuccess: onRegistrationSuccess, onError: onRegistrationError });

  function onRegistrationSuccess() {
    if(prevent2faForNewUsers) {
      resendCode({ phoneNumber: userInputs.phone_number });
    }
    setCurrentPhoneNumber(userInputs.phone_number);
    navigateTo("ConfirmationCodeStep");
  };

  function onRegistrationError({response}) {
    let errorMessage;

    switch(response.status){
    case 422:
      errorMessage = t(`validations.invalid_phone_number`);
      break;

    case 409:
      errorMessage = t(`validations.phone_already_taken`);
      break;
    }

    const fieldErrors = {
      phone_number: [
        errorMessage,
      ],
    };

    inputFormState.mergeErrors(fieldErrors);

  };

  const handleSubmit = () => {
    registration(
      {
        firstName: userInputs.first_name,
        lastName: userInputs.last_name,
        phoneNumber: userInputs.phone_number,
        language: locale,
        data: getUtmTags(),
      }
    );
  };

  React.useEffect(() => {
    if (setValue) {
      setValue({ phone_number: currentPhoneNumber });
    }
  }, [setValue, currentPhoneNumber]);

  const disabled = !inputFormState.isValid || !checked;
  const isLoading = isRegistrationLoading || isResendLoading;

  return (
    <div className='pt-2'>
      <h2 className={styles.title}>
        {t(`login.header.default_title_two`)}
      </h2>
      <InputForm inputFormState={inputFormState}
        loading={isLoading}
        onSubmit={handleSubmit}
        disabled={disabled}
      >
        <Terms
          checked={checked}
          onChange={setChecked}
        />
      </InputForm>
    </div>
  );
}
