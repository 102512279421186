export const PRE_POPULATE = "pre-populate";
export const RE_ASK = "re-ask";
export const SKIP = "skip";

// Profile Modal
export const ACCOUNT_CANCELLATION_VIEW = "accountCancellation";
export const EDIT_BASIC_INFO_VIEW = "editBasicInfo";
export const EDIT_DEPENDENTS_VIEW = "editDependentsView";
export const EDIT_PAYMENT_METHOD_VIEW = "editPaymentMethod";
export const EDIT_SMS_PREFERENCES_VIEW = "editSMSPreferences";
export const MAIN_VIEW = "main";
export const PAY_PAST_DUE_VIEW = "payPastDue";
export const PAYMENT_STATUS_CHECKER = "paymentStatusChecker";

// Update payment params query
export const UPDATE_PAYMENT = "update-payment";

// SaberEsPoder Email
export const SABERESPODER_EMAIL = 'info@saberespoder.com';
