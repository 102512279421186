const borderColor = '#007E84';
const colorBaseWhite = '#FBFCFF';

export const APPEARANCE = {
  theme: 'none',
  labels: 'floating',
  rules: {
    '.Input': {
      backgroundColor: colorBaseWhite,
      border: 'none',
      borderRadius: '0',
      borderBottom: `2px solid ${borderColor}`,
      fontSize: '16px',
    },
    '.Input:focus': {
      outline: 'none',
    },
    '.Input::placeholder': {
      fontSize: '12px',
      lineHeight: '17px',
      color: borderColor,
    },
    '.Label': {
      fontSize: '12px',
      lineHeight: '17px',
      color: borderColor,
    },
    '.Label--floating': {
      lineHeight: '13px',
    },
    '.Tab--selected': {
      border: `2px solid ${borderColor}`,
      transition: 'none',
    },
  },
  variables: {
    fontFamily: "Helvetica Neue",
  },
};

export const OPTIONS = {
  fields: {
    billingDetails: 'never',
  },
  layout:  {
    type: 'accordion',
    defaultCollapsed: true,
  },
  paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
  terms: {
    auBecsDebit: 'never',
    bancontact: 'never',
    card: 'never',
    ideal: 'never',
    sepaDebit: 'never',
    sofort: 'never',
    usBankAccount: 'never',
  },
};
