import React from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { loginModalTextMapping } from '@separate/constants/loginModal';
import { useTranslation } from '@separate/hooks/translation';
import { idType } from '@separate/types';
import { isFeatureEnabled } from '@separate/utilities/features';
import { scrollToTop } from '@separate/utilities/window';
import styles from './index.module.scss';
import ConfirmationCode from './steps/ConfirmationCode';
import LoginStep from './steps/LoginStep';
import RegistrationStep from './steps/RegistrationStep';

const LOGIN_STEP = 'login';
const REGISTRATION_STEP = 'register';
const CONFIRMATION_STEP = 'confirmationCode';

export default function Login({ onSuccess, providedPhoneNumber }) {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const [step, setStep] = React.useState(LOGIN_STEP);
  const [phoneNumber, setPhoneNumber] = React.useState(providedPhoneNumber);
  const [forceLogin, setForceLogin] = React.useState(!!providedPhoneNumber);

  const allowCustom2FAFlowForPremium = isFeatureEnabled('allow_custom_2FA_flow_for_premium_page');
  const textMapping = allowCustom2FAFlowForPremium ? loginModalTextMapping(pathname) : 'default';
  const [headerText, setHeaderText] = React.useState(t(`login.${textMapping}_welcome_back`));

  const onPhoneNumberSuccess = (value)=> {
    setPhoneNumber(value);
    setStep(CONFIRMATION_STEP);
  };

  const onPhoneNumberDoesNotExist = (value)=> {
    setPhoneNumber(value);
    setStep(REGISTRATION_STEP);
    setHeaderText(t(`login.${textMapping}_final_step`));
  };

  const goBack = () => {
    setForceLogin(false);
    setStep(LOGIN_STEP);
  };

  const onPhoneNumberAlreadyExist = (value)=> {
    setPhoneNumber(value);
    goBack();
  };

  const onConfirmationCodeSuccess = () => {
    scrollToTop();
    onSuccess?.();
  };

  React.useEffect(() => {
    setPhoneNumber(providedPhoneNumber);
  }, [providedPhoneNumber]);

  return (
    <div className={cx(styles.container, 'mt-4 px-4 pb-4')}>
      {step === LOGIN_STEP && (
        <LoginStep
          onPhoneNumberSuccess={onPhoneNumberSuccess}
          onPhoneNumberDoesNotExist={onPhoneNumberDoesNotExist}
          currentPhoneNumber={phoneNumber}
          providedPhoneNumber={providedPhoneNumber}
          forceLogin={forceLogin}
          textMapping={textMapping}
        />
      )}

      {step === REGISTRATION_STEP && (
        <RegistrationStep
          onPhoneNumberSuccess={onPhoneNumberSuccess}
          onPhoneNumberAlreadyExist={onPhoneNumberAlreadyExist}
          currentPhoneNumber={phoneNumber}
          textMapping={textMapping}
        />
      )}

      {step === CONFIRMATION_STEP && (
        <ConfirmationCode
          phoneNumber={phoneNumber}
          onConfirmationCodeSuccess={onConfirmationCodeSuccess}
          headerText={headerText}
        />
      )}
    </div>
  );
}

Login.propTypes = {
  onSuccess: PropTypes.func,
  providedPhoneNumber: idType,
};

Login.defaultProps = {
  providedPhoneNumber: "",
};
