const login = {
  sms: {
    text: "¿Le gustaría recibir mensajes de texto SMS sobre los siguientes servicios mencionados en SABEResPODER.com u otros materiales proporcionados por Welcome Technologies a un número de teléfono que nos proporcione?",
    new_text: "Al unirse a nuestra comunidad, usted acepta recibir mensajes de texto SMS sobre los siguientes temas en el número de celular proporcionado:",
    bullet1: "Soporte sobre servicios y/o temas y consultas generales que tenga",
    bullet2: "Participación en encuestas y paneles de investigación",
    bullet3: "Información sobre productos y servicios exclusivos",
  },
  accept_terms: "La frecuencia de los mensajes varía. Responda AYUDA para obtener ayuda o STOP para cancelar. Al seleccionar la caja usted acepta nuestros",
  terms_and_conditions: "Términos y Condiciones y Política de Privacidad",
  congrats_in_joining: "¡Le damos la bienvenida a la comunidad!",
  we_are_ready_to_help: "¡Disfrute de beneficios exclusivos!",
  enter_the_confirmation_code: "Ingrese el código de 5 dígitos",
  exit: "Salir",
  back_to_login: "Volver",
  submit_confirmation: "Enviar código",
  resend_label: "¿No recibió un código?",
  resend_code: "Reenviar",
  register: "Crear cuenta",
  login: "Iniciar sesión",
  code: "Código",
  header: {
    default_title: '¡Le damos la bienvenida!',
    default_title_two: '¡Ya casi termina!',
    premium_title: 'Premium Page Login (SP)',
    premium_title_two: 'Premium Page Register (SP)',
    subtitle: 'Ingrese su número de celular para comenzar a disfrutar de muchos beneficios.',
  },
  sign_up: 'Crear cuenta',
  privacy: 'Nos importa su privacidad. Nunca compartiremos su número celular. Su número de celular está a salvo con nosotros, nunca lo compartiremos.',
  footer: {
    text: '¿Tiene alguna pregunta? ¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! ',
    number: '(726) 202 1213',
  },
  default_final_step: '¡Último paso!',
  default_welcome_back: '¡Hola de nuevo!',
  premium_final_step: 'Premium Page Final step! (SP)',
  premium_welcome_back: 'Premium Page Welcome Back! (SP)',
  please_enter_5_digit_code: 'Ingrese el código de 5 dígitos.',
  enter_code: 'Confirmar celular',
};

export default login;
