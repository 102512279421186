import React from 'react';
import cx from 'classnames';
import { useLogin } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import styles from './index.module.scss';
import InputForm from './InputForm';
import useLoginInputFormState from './useLoginInputFormState';
import { useLoginFlow } from '../../loginFlowContext';

export default function LoginStep() {
  const { t } = useTranslation();
  const { navigateTo, currentPhoneNumber, setCurrentPhoneNumber, forceLogin } = useLoginFlow();
  const inputFormState = useLoginInputFormState();
  const { phone_number: phoneNumber } = inputFormState.value;

  const disabled = !inputFormState.isValid;

  const onSuccess = (response) => {
    setCurrentPhoneNumber(phoneNumber);
    navigateTo("ConfirmationCodeStep");
  };

  const onError = ({ response }) => {
    setCurrentPhoneNumber(phoneNumber);
    navigateTo("RegistrationStep");
  };

  const { isLoading, login } = useLogin({ onSuccess, onError });
  const { setValue } = inputFormState;

  const handleSubmit = React.useCallback(() => {
    login({ phoneNumber });
  }, [login, phoneNumber]);

  React.useEffect(() => {
    if (currentPhoneNumber) {
      setValue({ phone_number: currentPhoneNumber });
    }
  }, [currentPhoneNumber, setValue]);

  React.useEffect(() => {
    if(forceLogin && phoneNumber && String(phoneNumber).length === 10) {
      handleSubmit();
    }
  }, [forceLogin, phoneNumber, handleSubmit]);

  return (
    <div>
      <h2>{t(`login.header.default_title`)}</h2>
      <p>{t('login.header.subtitle')}</p>
      <InputForm inputFormState={inputFormState}
        loading={isLoading}
        onSubmit={handleSubmit}
        disabled={disabled}
      />
      <p className={cx(styles.footer, 'mt-4')}>
        {t('login.footer.text')}
        <a href="tel:(726) 202-1213">{t('login.footer.number')}</a>
      </p>
    </div>
  );
}
