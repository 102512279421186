import { presence } from '@separate/utilities/string';

export default function zipcodeValidator(value) {
  if (!presence(value)) return null;

  value = value.trim(' ');

  if (value.length !== 5) {
    return 'exactly_5_characters';
  }

  if (!value.match(/^\d+$/)) {
    return 'numeric';
  }

  return null;
}
