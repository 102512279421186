const INTERNAL_HOST = "saberespoder.com";

export function makeInternalUrlRelative(url) {
  if (typeof document === "undefined") return;

  const { hostname, pathname, search } = new URL(url, document.baseURI);

  if (endsWith(hostname, INTERNAL_HOST)) {
    return `${pathname}${search}`;
  } else {
    return url;
  }
}

export function redirectUrlFor(replaceFrom, replaceTo) {
  if (typeof window === "undefined") return;

  const { pathname, search } = window.location;

  let newPathName = pathname.replace(replaceFrom, replaceTo);
  return `${newPathName}${search}`;
}

export function makeInternalUrlRelativeAtHtml(htmlString) {
  if (window.DOMParser) {
    try {
      if (!htmlString) return "";
      let parser = new DOMParser();
      let htmlDoc = parser.parseFromString(htmlString, "text/html");
      for (let link of htmlDoc.getElementsByTagName("a")) {
        link.setAttribute('href', makeInternalUrlRelative(link.getAttribute("href")));
      }
      return htmlDoc.body.innerHTML;
    } catch (e) {
      return htmlString;
    }
  }
  return htmlString;
}

function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}
