import { client, BRAZE_API_KEY, BRAZE_SDK_ENDPOINT_URL } from '@/env';

// should be imported at the client side
async function loadBraze() {
  return await import('@braze/web-sdk');
}

export function initalizeBraze(userPid, setBrazeClient) {
  if (client && userPid && BRAZE_API_KEY && BRAZE_SDK_ENDPOINT_URL) {
    loadBraze().then((braze) => {
      braze.initialize(BRAZE_API_KEY, {
        baseUrl: BRAZE_SDK_ENDPOINT_URL
      });
      braze.automaticallyShowInAppMessages();
      braze.changeUser(userPid);
      braze.openSession();
      setBrazeClient(braze);
    });
  }
}
