import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { isProjectNameWelcomeForWork } from '@separate/utilities/modules';

export const SvgComponent = ({ name }) => {
  const DynamicSvg = dynamic(() =>
    isProjectNameWelcomeForWork()
      ? import(`../../../../welcomeplus-employee/src/icons/${name}.svg`)
      : import(`@separate/icons/${name}.svg`),
  );

  return <DynamicSvg />;
};

SvgComponent.propTypes = {
  name: PropTypes.string,
};
