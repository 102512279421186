const SUBSTITUTIONS = {
  a: (char) => /[a-zA-Z]/.test(char),
  9: (char) => /[0-9]/.test(char),
};

export function applyMask({ mask, value }) {
  let masked = '';
  let unmasked = '';

  if (value == null) {
    value = '';
  }
  value = `${value}`;

  const maskChars = mask.split('');
  const valueChars = value.split('');

  let maskIndex = 0;

  function nextSubstitutable(index) {
    while (!Object.keys(SUBSTITUTIONS).includes(maskChars[index]) && index < maskChars.length) {
      index++;
    }

    return index < maskChars.length ? maskChars[index] : null;
  }

  for (let char of valueChars) {
    const forwardMatch = nextSubstitutable(maskIndex);
    const forwardMatchFunction = SUBSTITUTIONS[forwardMatch];

    if (forwardMatchFunction) {
      if (forwardMatchFunction(char)) {
        while (maskChars[maskIndex] !== forwardMatch) {
          masked += maskChars[maskIndex];
          maskIndex++;
        }

        masked += char;
        unmasked += char;
        maskIndex++;
      } else if (char === maskChars[maskIndex]) {
        masked += char;
        maskIndex++;
      }
    }
  }

  return { masked, unmasked };
}
