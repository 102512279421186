import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import AddDependentsCard from '@separate/components/Dependents/AddDependentsCard';
import DependentsList from '@separate/components/Dependents/DependentsList';
import { DEPENDENTS_FETCH } from '@separate/constants/analytics';
import { useGetDependents } from '@separate/hooks/dependents';
import { useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import styles from './index.module.scss';

export default function EditDependents({ backToMain }) {
  const [userDependents, setUserDependents] = React.useState([]);
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const ref = React.createRef();

  const onSuccess = (result) => {
    setUserDependents(result);
  };

  const onError = (err) => {
    logEvent(DEPENDENTS_FETCH, { result: err });
  };

  const { isLoading, fetchUserDependents } = useGetDependents({ onSuccess, onError });

  const handleClick = () => {
    backToMain();
  };

  React.useEffect(() => {
    if (!user) return;

    fetchUserDependents();
  }, [user, fetchUserDependents]);

  return (
    <div className={styles.container} ref={ref}>
      <div className='d-flex w-100 justify-content-between'>
        <h4 className={styles.ctaTitle}>{t('user.dependents.title')}</h4>
        <Button
          onClick={handleClick}
          className={styles.editCta}
        >
          {t('user.profile.cancel_edit_cta')}
        </Button>
      </div>
      {isLoading && (
        <div className='d-block text-center'>
          <Spinner size='lg' animation='border' className={cx(styles.spinner, 'mt-4')} />
        </div>
      )}
      {!isLoading && userDependents.length > 0 && (
        <DependentsList
          userDependents={userDependents}
          container={ref}
          refetchDependents={fetchUserDependents}
        />
      )}
      {!isLoading && !userDependents.length && (
        <AddDependentsCard
          buttonClassName={styles.addDependentsCta}
          container={ref}
          refetchDependents={fetchUserDependents}
        />
      )}
    </div>
  );
}

EditDependents.propTypes = {
  backToMain: PropTypes.func.isRequired,
};
