import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import { useTranslation } from '@separate/hooks/translation';
import { CardFailed, CardSuccess } from '@separate/icons';
import styles from './alertModal.module.scss';

const PodersaludSubscriptionPlanAlertModal = (props) => {
  const { onHide, isLoading, success, failed, retryPaymentMethods, onDismiss, nextPayment} = props;
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if(success || isLoading || failed) setShow(true);

    return () => setShow(false);
  }, [failed, isLoading, onDismiss, success]);

  const showPaymentMethods = () => {
    retryPaymentMethods();
    setShow(false);
  };

  return (
    <Modal
      className={cx(styles.alertModal)}
      show={show}
      onHide={onHide}
      bodyClassName={styles.body}
      close={false}
      contentClassName={styles.content}
      dialogClassName={styles.dialog}
      backdropClassName={styles.backdrop}
    >
      {isLoading && (
        <div
          className={cx(
            styles.spinner,
            'd-flex justify-content-center align-items-center row'
          )}
        >
          <Spinner animation='border' size='lg' />
          <p>{t('premium.upgrade_subscription_plan.processing_payment')}</p>
        </div>
      )}

      {!isLoading && (
        <div className={styles.card}>
          {success && (
            <div>
              <CardSuccess />
              <p className={styles.title}>
                {t('premium.upgrade_subscription_plan.your_plan_has_been_updated')}
              </p>
              <p className={styles.textLight}>
                {t('premium.upgrade_subscription_plan.your_payment_has_been_received', { next_payment: nextPayment })}
              </p>
            </div>
          )}

          {failed && (
            <div>
              <CardFailed />
              <p className={styles.title}>
                {t('premium.upgrade_subscription_plan.your_payment_failed')}
              </p>
              <p className={styles.textLight}>
                {t('premium.upgrade_subscription_plan.make_sure_you_have_sufficient_funds')}
              </p>
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              className={cx({
                [styles.buttonDark]: success,
                [styles.buttonLight]: failed,
              })}
              onClick={() => onDismiss()}
            >
              {t('premium.upgrade_subscription_plan.close')}
            </Button>
            {failed && (
              <Button className={styles.buttonTryAgain} onClick={() => showPaymentMethods()}>
                {t('premium.upgrade_subscription_plan.try_again')}
              </Button>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

PodersaludSubscriptionPlanAlertModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  retryPaymentMethods: PropTypes.func,
  onDismiss: PropTypes.func,
  nextPayment: PropTypes.string,
};

PodersaludSubscriptionPlanAlertModal.defaultProps = {
  isLoading: false,
  success: false,
  failed: false,
};

export default PodersaludSubscriptionPlanAlertModal;
