const premium = {
  main: {
    header: {
      health: "Health",
      wellness: "and Wellness"
    },
    subheader: "Hassle-free and affordable medical service<sup>1</sup> for you and your immediate family nationwide.",
    cta: "Get my membership",
    span: "Only <strong>$25.00</strong> <small>/ month</small>",
    silver_price: "Starting from <strong>$17.00</strong> <small>/ month</small>",
  },
  services: {
    header: "Unbeatable benefits",
    items: [
      {
        title: "Dental",
        content: "Save easy at dental appointments. Receive <strong>15% - 50%</strong> discounts per visit from over 260,000 dentists"
      },
      {
        title: "See a Doctor",
        content: "Save <strong>10% - 40%</strong> when you visit a doctor, or talk to a doctor 24/7 with unlimited telehealth."
      },
      {
        title: "Prescription Discounts",
        content: "Save <strong>up to 80%</strong> on your prescriptions at 60,000 pharmacies nationwide."
      },
      {
        title: "Other Services",
        content: "Better vision and diagnosis. Save <strong>10 - 60%</strong> on eye health and <strong>up to 75%</strong> on MRI / CT scans"
      }
    ],
    cta: "Get my membership!"
  },
  plans: {
    header: "Sample savings",
    subheader: "Healthcare discounts that work. Discover real savings today with PODERsalud",
    items: [
      {
        banner: "$45.00 in savings",
        header: "Dental",
        subheader: "<strong>Average cost</strong> of teeth cleaning for an adult or child",
        amount: {
          old: "$102.00",
          new: "$57"
        },
        quantity: "/ per visit",
        member: "With <strong>PODER</strong>salud",
        cta: "Start saving"
      },
      {
        banner: "$250.00 in savings",
        header: "Telemedicine",
        subheader: "<strong>Average cost</strong> for an in person doctors appointment",
        amount: {
          old: "$250.00",
          new: "$0"
        },
        quantity: "/ per visit",
        member: "With <strong>PODER</strong>salud",
        cta: "Start saving"
      },
      {
        banner: "$10.87 in savings",
        header: "Pharmacy",
        subheader: "<strong>Average cost</strong> of Lisinopril / (Blood Pressure)",
        amount: {
          old: "$12.00",
          new: "$1.13"
        },
        quantity: "/ 20mg (30 tablets)",
        member: "With <strong>PODER</strong>salud",
        cta: "Start saving"
      },
      {
        banner: "$48.00 in savings",
        header: "Vision",
        subheader: "<strong>Average cost</strong> of a glasses exam",
        amount: {
          old: "$90.00",
          new: "$42"
        },
        quantity: "/ per visit",
        member: "With <strong>PODER</strong>salud",
        cta: "Start saving"
      }
    ],
    span: "With PODERsalud you can save an average<sup>2</sup> of $1,050 per year, and $3,136 for a family of 4."
  },
  chat: {
    header: "Do you have any questions?",
    content: "Our Customer Service Team is happy to assist you! <a href='tel:(877) 210-2513'>(877) 210 2513</a>",
    cta: "Chat with an expert"
  },
  rewards: {
    header: "Benefits built for you",
    subheader: "Your health, your future - Unlock your full potential",
    items: [
      {
        title: "Substantial Value and Simplicity",
        content: "Access in-person and on-demand virtual consultations, along with savings on dental services, prescription medications, and more for you and your immediate family."
      },
      {
        title: "Savings Cover the Cost",
        content: "PODERsalud benefits and discounts contribute to financial stability. Our simple pricing pays off with annual savings."
      },
      {
        title: "Health Empowerment for All",
        content: "PODERsalud complements existing coverage or offers health support if none exists. Circumstances don't matter — benefits are for all."
      },
      {
        title: "Get the answers you need",
        content: "Count on us! Your membership grants you access to our bilingual customer service which will provide you with reliable and personalized support you can trust."
      }
    ]
  },
  testimonials: {
    header: "Community Voices",
    items: [
      {
        content: "“I recommend PODERsalud to anyone, with or without health insurance. This program makes taking care of your health <strong>affordable.</strong>“",
        span: "- Jose. Miami, FL"
      },
      {
        content: "“We used the telemedicine service with PODERsalud and we were able to get a diagnosis right away. <strong>It was incredible!</strong>“",
        span: "- Angela. Chicago, IL"
      },
      {
        content: "“PODERsalud interested me because it offers plans at a <strong>very low cost</strong> and it includes your entire immediate family, and you can access a <strong>multitude of services.</strong> I haven't found these healthcare benefits anywhere else!“",
        span: "- Lucia. Los Angeles, CA"
      }
    ]
  },
  articles: {
    header: "Discover Health Tips",
    cta: "Read Article"
  },
  faq: {
    header: "Frequently Asked Questions",
    items: [
      {
        title: "How does PODERsalud help me?",
        content: "<span>This subscription supports you in three crucial ways:</span> <ol><li>It makes healthcare more accessible and affordable.</li><li>You can earn extra income participating in surveys, where you’ll discover valuable information on hundreds of topics that will help you make more informed life decisions, saving you time and money.</li><li>You can ask our team of experts any question.</li></ol>",
      },
      {
        title: "Can my family benefit from these healthcare discounts?",
        content: "YES! With PODERsalud not only do you have access to telemedicine and discounted healthcare, but so does your partner and your children (under 26 years of age).",
      },
      {
        title: "Can I cancel my subscription?",
        content: "Your subscription can be canceled at any time. Plus full money back satisfaction guarantee for your first 30 days.",
      }
    ],
    more: {
      title: "Do you have any questions?",
      content: "Our Customer Service Team is happy to assist you! <a href='tel:(877) 210-2513'>(877) 210-2513</a>",
      cta: "Chat with an expert"
    }
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Discount Plan Application. This program is NOT insurance coverage and does not meet the minimum creditable coverage requirements under the Affordable Care Act or Massachusetts M.G.L. c. 111M and 956 CMR 5.00. Your membership is effective upon receipt of membership materials. This program is not available to UT, VT and WA residents. It contains a 30 day cancellation period, provides discounts only at the offices of contracted health care providers, and each member is obligated to pay the discounted medical charges in full at the point of service. The range of discounts for medical or ancillary services provided under the program will vary depending on the type of provider and medical or ancillary service received. Member shall receive a reimbursement of all periodic membership fees if membership is cancelled within the first 30 days after the effective date. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475 Form #HPSEP2",
      "This is a savings example and does not guarantee a certain savings amount. Costs and savings may vary based on service, provider, and geographical area."
    ]
  },
  enrollment: {
    popular: "Popular",
    year: 'year',
    platinum: "Platinum",
    silver: "Silver",
    plans: {
      header: "Choose your plan",
      subheader: "Select the plan that's right for you."
    },
    intervals: {
      month: {
        tab: "Monthly",
        price_per: "month",
        text: "Billed monthly",
      },
      semi: {
        tab: "Semi-Annual",
        price_per: "semi-annual",
        text: "billed every 6 months",
      }
    },
    benefits: {
      title: "Benefits",
      teladoc: "Telehealth",
      inPerson: "Physicians",
      vision: "Vision",
      hearing: "Hearing",
      mri: "MRI + CT scans",
      travel: "Affinity travel",
      lab: "Lab testing",
      diabetic: "Diabetic care",
      pharmacy: "Prescriptions",
      dental: "Dental",
      show: "Show More",
      hide: "Hide",
    },
    perks: {
      title: "Perks",
      travel: "Travel",
      rewards: "Rewards",
      advisor: "Trusted Advisor",
    },
    discount: {
      tnc: {
        cta: "Promotional Discount Terms and Conditions",
      }
    },
    step: "Step",
    continue: "Continue",
    view_benefits: "View Benefits",
    phone_number_entry: {
      header: "Let's get started!",
      subheader: "Start benefiting now - enter your mobile number to enjoy premium benefits.",
      mobile_number: "Mobile number",
    },
    personal_info: {
      header: "About you",
      subheader: "Please enter your name.",
      more: "Please tell us some more about yourself!"
    },
    address: {
      header: "Your address",
      subheader: "This address will be used to mail you your membership materials."
    },
    payment: {
      header: "Confirm and pay",
      subheader: "Confirm your plan and enter payment information to purchase your subscription.",
      price: "Plan Price",
      month: "month",
      free_trial_days: "days Free Trial",
      one_time_credit: "One-time credit",
      total: "Total",
      change_plan: "Change plan type",
      cta: "Pay now",
      secure: {
        header: "Secure transactions, always.",
        subheader: "Your payments, securely handled."
      }
    },
    success: {
      welcome: "Welcome",
      header: {
        thanks: "Thanks for choosing the ",
        plan: "{{plan}} Plan!"
      },
      subheader: {
        registration: {
          final_step: "<strong>Final Step: Complete registration by verifying your phone number.</strong>",
          verify: "You're almost there. To fully access your benefits, please verify your phone number. It's the final step in completing your registration.",
          cta: "Confirm phone number"
        },
        benefits: {
          get_started: "Click Get started to explore your benefits.",
          cta: "Get started"
        }
      }
    },
    confirmation: {
      cta: "Verify code",
      new_user: {
        header: "Enter verification code",
        subheader: "Enter the 5-digit code sent to your phone ending in {{ number }} to complete setup.",
      },
      existing_user: {
        header: "Welcome back",
        subheader: "Please enter the 5-digit code sent to your mobile device.",
      },
      success: {
        header: "Success!",
        subheader: "Thank you for confirming your phone number! Please click Continue to proceed.",
        cta: "Continue"
      }
    }
  },
  free_trial: {
    banner: {
      header: 'Unlock your free* trial',
      subheader: 'days free* healthcare benefits discounts!',
    },
  },
  onboarding: {
    intro: {
      header: `Our goal is to deliver the <span>care</span> you need when you need it...`,
      subheader: "We just need to ask you a few more questions!",
      cta: "Get started",
    },
    benefit_interests: {
      header: `What's most <span>important</span> to you right now?`,
      subheader: 'Please <strong>select all</strong> immediate benefit needs:',
      benefits:{
        vision: "Vision",
        dental: "Dental",
        in_person_healthcare: "In person healthcare",
        telemedicine: "Telemedicine",
        pharmacy: "Pharmacy",
      },
      cta: "Next",
    },
    disclaimer: "Discount Plan Application. This program is NOT insurance coverage and does not meet the minimum creditable coverage requirements under the Affordable Care Act or Massachusetts M.G.L. c. 111M and 956 CMR 5.00. Your membership is effective upon receipt of membership materials. This program is not available to UT, VT and WA residents. It contains a 30 day cancellation period, provides discounts only at the offices of contracted health care providers, and each member is obligated to pay the discounted medical charges in full at the point of service. The range of discounts for medical or ancillary services provided under the program will vary depending on the type of provider and medical or ancillary service received. Member shall receive a reimbursement of all periodic membership fees if membership is cancelled within the first 30 days after the effective date. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475 Form #HPSEP2",
  },
};

export default premium;
