import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Card from '@separate/components/CTA/Card';
import { Radio, RadioFilled } from '@separate/icons';
import styles from './radioCard.module.scss';

export default function RadioCard({
  children,
  className,
  handleClick,
  selected,
  ...extraProps
}) {
  const RadioIcon = selected ? RadioFilled : Radio;

  return (
    <div className={cx(className, styles.radioCard)} onClick={handleClick}>
      <Card className={cx('content', styles.content)} {...extraProps}>
        {children}
      </Card>
      <RadioIcon className={cx(styles.indicator, 'radio-card-indicator')} />
    </div>
  );
}

RadioCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
};
