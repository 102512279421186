import { useMutation } from 'react-query';
import { addDependent, getDependents } from '@separate/api/sep/dependents';
import { useLocale } from '@separate/hooks/translation';

export const useAddDependent = ({ onSuccess, onError }) => {
  const locale = useLocale();
  const result = useMutation(({ data }) => addDependent({ data, locale }),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422,
    });

  const { isLoading, mutate } = result;

  return { isLoading, addUserDependent: mutate };
};

export const useGetDependents = ({ onSuccess, onError }) => {
  const result = useMutation(getDependents, {
    onSuccess,
    onError,
  });

  const { isLoading, mutate } = result;

  return { isLoading, fetchUserDependents: mutate };
};
