import React from 'react';
import PropTypes from 'prop-types';
import PaymentMethodChangerOffcanvas from '@separate/components/PaymentMethods/PaymentMethodChangerOffcanvas';
import RetryCardOffcanvas from '@separate/components/PaymentMethods/RetryCardOffcanvas';
import { usePastDuePaymentFetch } from '@separate/hooks/premium';

export default function MissedPaymentFlowOffcanvas({
  container,
  onChangePayment,
  onClickAddCard,
  onDismiss,
  onHide,
  onSuccess,
  primaryPaymentMethodId,
  show,
  pid,
}) {
  const [paymentSubscription, setPaymentSubscription] = React.useState();
  const [retry, setRetry] = React.useState(true);
  const [visible, setVisible] = React.useState(true);

  // Fetch the "payment" subscription, which has a payment intent client secret
  const onFetchPaymentSubSuccess = React.useCallback((data) => {
    setPaymentSubscription(data.subscription);
  }, []);
  const {
    fetchSubscription: fetchPaymentSubscription,
  } = usePastDuePaymentFetch({ onSuccess: onFetchPaymentSubSuccess });

  React.useEffect(() => {
    if (!fetchPaymentSubscription) return;

    fetchPaymentSubscription(pid ? { pid } : undefined);
  }, [fetchPaymentSubscription, pid]);

  // Local handler functions
  function handleClickAddCard() {
    onClickAddCard?.();
  }

  function handleChangePayment() {
    setRetry(false);
    onChangePayment?.();
  }

  function handleHide() {
    setVisible(false);
    onHide?.();
  }

  return (
    <>
      {retry ? (
        <RetryCardOffcanvas
          container={container}
          onChangePayment={handleChangePayment}
          onDismiss={onDismiss}
          onHide={handleHide}
          onSuccess={onSuccess}
          primaryPaymentMethodId={primaryPaymentMethodId}
          show={show && visible}
          subscription={paymentSubscription}
          pid={pid}
        />
      ) : (
        <PaymentMethodChangerOffcanvas
          container={container}
          onClickAddCard={handleClickAddCard}
          onDismiss={onDismiss}
          onHide={handleHide}
          onSuccess={onSuccess}
          paymentSubscription={paymentSubscription}
          primaryPaymentMethodId={primaryPaymentMethodId}
          show={show && visible}
          pid={pid}
        />
      )}
    </>
  );
}

MissedPaymentFlowOffcanvas.propTypes = {
  container: PropTypes.any,
  onChangePayment: PropTypes.func,
  onClickAddCard: PropTypes.func,
  onDismiss: PropTypes.func,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  show: PropTypes.bool,
  pid: PropTypes.string, // for zendesk-sep-admin
};
