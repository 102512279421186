const newBenefitsPharmacySearch = {
  search: 'Search',
  zipcode: 'ZipCode',
  drugName: 'Drug Name',
  title: 'Drug Prices Search',
  zipCodeValidationError: 'Please enter a 5-digit ZIP code',
  positiveIntegerError: 'Please enter integer value greater than 0',
  milesError: 'Please enter integer value greater than 0 and less than 55000',
  phone: 'Phone Number: ',
  miles: 'Radius In Miles',
  drugsQuantity: 'Number of Drugs',
  daySupply: 'Day Supply',
}

export default newBenefitsPharmacySearch;
