import { client } from '@separate/env';

const localStorageAvailable =
  client &&
  (function() {
    if (!("localStorage" in window)) return false;
    try {
      window.localStorage.setItem("foo", "bar");
      if (window.localStorage.getItem("foo") !== "bar") return false;
      window.localStorage.removeItem("foo");
    } catch (e) {
      return false;
    }
    return true;
  })();

export function set(key, value) {
  if (!localStorageAvailable) return;

  localStorage.setItem(key, value);
}

export function get(key) {
  if (!localStorageAvailable) return undefined;

  return localStorage.getItem(key);
}

export function clear(key) {
  if (!localStorageAvailable) return;

  localStorage.removeItem(key);
}
