import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Button from "@/components/Button";
import { useTranslation } from "@separate/hooks/translation";
import styles from "./addressModal.module.scss";

export default function FailureModal(props) {
  const { show, onDismiss } = props;

  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onDismiss}
      className={styles.modal}
      contentClassName={styles.modalContent}
      backdropClassName={styles.modalBackdrop}
      backdrop="static"
    >
      <h2 className={styles.title}>
        {t("address.verification.not_found_title")}
      </h2>
      <p className={styles.message}>
        {t("address.verification.not_found_description")}
      </p>
      <Button onClick={onDismiss} className={styles.next}>
        {t("address.verification.edit_address")}
      </Button>
    </Modal>
  );
}

FailureModal.propTypes = {
  show: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
};
