import home from './home';
import menu from './menu';
import footer from './footer';
import earn from './earn';
import premium from './premium';
import podercard from './podercard';
import about from './about';
import consulate from './consulate';
import validations from './validations';

const en = {
  translation: {
    home,
    menu,
    footer,
    earn,
    premium,
    podercard,
    about,
    consulate,
    validations
  }
};

export default en;
