const meta = {
  article_categories: {
    coronavirus: {
      title: '【News and guidelines regarding COVID-19 ✔️】',
      description: 'Learn how to combat coronavirus with our guides ✔️ The best tips, articles and day to day guidelines.'
    },
    finance: {
      title: '【Guides and articles on personal finance in the U.S.  ✔️】',
      description: 'Want to improve your finances? ✔️ At SABEResPODER we provide the best guides, tips and suggestions.'
    },
    health: {
      title: '【Tips and guides on health care and wellness ✔️】',
      description: 'Follow these tips and recommendations for improving the health of your family and friends. ✔️ The best health and wellness articles!'
    },
    education: {
      title: '【Learning guides for you and your children ✔️ 】',
      description: 'Invest in your children\'s education with these curated, in-depth guides.  ✔️ '
    },
    legal: {
      title: '【Legal information for Latinos in the U.S. ✔️】',
      description: 'Find the best articles and guides on legal issues affecting the Latino community in the United States. ✔️ Discover more!'
    }
  },
  articles: {
    title: '【News, guides and support for the Latino community in the U.S.】',
    description: 'Find the most insightful and supportive guides around Healthcare, Finance, Education, Legal and more! ✔️ Explore our articles and guides!'
  },
  earn: {
    title: '【Earn money with surveys and courses ✔️】',
    description: 'Start earning money! ✔️ With the SABEResPODER opinion groups you can earn money answering questions and completing educational courses.'
  },
  farmasep: {
    title: '【Discounts on medications in the U.S. ✔️】',
    description: 'FarmaSEP helps you save money while also taking care of your family members healthcare. ✔️ Get discounts on medication purchases today! '
  },
  podercard: {
    title: '【 Digital banking for the Latino Community ✔️ PODERcard】',
    description: 'Take control of your finances with PODERcard. ✔️ No maintenance fees ✔️ Free mobile app.'
  },
  premium: {
    title: '【Discounted medical plans and services  ✔️  PODERsalud】',
    description: 'Get discounts on health services! ✔️ Access unlimited medical consultations from your cell phone with PODERsalud.'
  }
};

export default meta;
