import React from 'react';
import PropTypes from 'prop-types';
import NewLoginModal from '../NewLoginModal';
import { isFeatureEnabled } from '@separate/utilities/features';
import NewBrandingLoginModal from '../NewBrandingLoginModal';

export default function RenderLoginModal({ show, onHide }) {
  const loginModalUpdate = isFeatureEnabled('login_modal_ui_update');

  return (
    <>
      {loginModalUpdate ? (
        <NewBrandingLoginModal show={show} onHide={onHide} />
      ) : (
        <NewLoginModal show={show} onHide={onHide} />
      )}
    </>
  );
}

RenderLoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
