import React from 'react';
import cx from 'classnames';
import MissedPayment from '../MissedPayment';
import ProfileModal from '../ProfileModal';
import Bugsnag from '@bugsnag/js';
import { useBraze } from '@/hooks/braze';
import { usePostHogProvider } from '@/hooks/posthog';
import { useGetMissedPayment } from '@/hooks/premium';
import { useDeepLinkUpdatePaymentModal } from '@/hooks/updatePayment';
import { SetShowMissedPaymentModalContext } from '@separate/context/SetShowMissedPaymentModalContext';
import { useSetUserLanguage } from '@separate/hooks/language';
import { useCurrentUser } from '@separate/hooks/user';
import { setAnalyticsPid } from '@separate/utilities/analytics';
import HeaderBar from './HeaderBar';
import Footer from './Footer';
import styles from './index.module.scss';

/* eslint react/prop-types: 0 */
export default function Layout({ Component, pageProps }) {
  // place for a query dependent hooks
  useBraze();
  useSetUserLanguage();
  const { showDeepLinkUpdatePaymentModal, hideDeepLinkUpdatePaymentModal } = useDeepLinkUpdatePaymentModal();
  const { disableLanguageSelect } = pageProps;

  const {
    userHasMissedPayment,
    showMissedPaymentModal,
    setShowMissedPaymentModal,
    showMissedPaymentBanner,
    setShowMissedPaymentBanner,
  } = useGetMissedPayment();

  const { user } = useCurrentUser();
  const pid = user?.pid;

  const [recordSession, setRecordSession] = React.useState(false);
  const posthog = usePostHogProvider();

  React.useEffect(() => {
    if (!posthog) return;

    if (recordSession) {
      console.log('Invoking posthog start');
      posthog.startSessionRecording();
    } else {
      console.log('Invoking posthog stop');
      posthog.stopSessionRecording();
    }
  }, [posthog, recordSession]);

  React.useEffect(() => {
    if (Component.enableSessionRecording) {
      setRecordSession(true);
    } else {
      setRecordSession(false);
    }
  }, [Component]);

  React.useEffect(() => {
    if (!pid) return;

    Bugsnag.setUser(pid);

    setAnalyticsPid(pid);
  }, [pid]);

  return (
    <SetShowMissedPaymentModalContext.Provider value={setShowMissedPaymentModal}>
      <div>
        {!Component.suppressHeaderBar && (
          <>
            <HeaderBar />
            <MissedPayment
              userHasMissedPayment={userHasMissedPayment}
              showMissedPaymentModal={showMissedPaymentModal}
              setShowMissedPaymentModal={setShowMissedPaymentModal}
              showMissedPaymentBanner={showMissedPaymentBanner}
              setShowMissedPaymentBanner={setShowMissedPaymentBanner}
            />
            <ProfileModal
              show={showDeepLinkUpdatePaymentModal}
              onHide={hideDeepLinkUpdatePaymentModal}
              showPayment
            />
          </>
        )}
        <div className={Component.suppressHeaderBar ? "componentNoHeader" : "component"}>
          <Component {...pageProps} />
        </div>
        {!Component.suppressFooter &&
         <Footer />
        }
      </div>
    </SetShowMissedPaymentModalContext.Provider>
  );
}
