import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { PODERSALUD_PLAN } from '@/constants/user';
import { ADDING_DENTAL_PRODUCT } from '@separate/constants/features';
import { useSubscriptionBundlesFetch } from '@separate/hooks/premium';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { isFeatureEnabled } from '@separate/utilities/features';

import InfoRow from '../BasicInfo/InfoRow';
import styles from './billing-status.module.scss';

export default function BillingStatus(props) {
  const { last4, pastDue, onPayPastDue, onEditPaymentMethod, interval, nextPaymentDate, subscription, isPremiumLegacy } = props;
  const [currentSubscriptionBundle, setCurrentSubscriptionBundle] = React.useState([]);

  const featureAddingDentalProduct = isFeatureEnabled(ADDING_DENTAL_PRODUCT);
  const locale = useLocale();

  const { t } = useTranslation();
  const allowProfileModalRefactor = isFeatureEnabled('allow_profile_modal_refactor');
  const buttonText = pastDue ? t('premium.billing.update_now') : t('premium.billing.edit_payment');

  const onFetchSubscriptionBundlesSuccess = (response) => {
    if(subscription) {
      setCurrentSubscriptionBundle(response.find((bundle) => bundle.id === subscription.subscription_bundle_id));
    }
  };

  const { fetchSubscriptionBundles } = useSubscriptionBundlesFetch({ onSuccess: onFetchSubscriptionBundlesSuccess });

  React.useEffect(() => {
    if(featureAddingDentalProduct) {
      fetchSubscriptionBundles();
    }
  }, [featureAddingDentalProduct, fetchSubscriptionBundles]);

  function handleClick() {
    if (pastDue) {
      onPayPastDue();
    } else {
      onEditPaymentMethod();
    }
  }

  const subscriptionBundleName =
    locale == 'en'
      ? subscription?.subscription_bundle_name_en
      : subscription?.subscription_bundle_name_es;

  const isAnnual = interval === 'year';
  const isCustomInterval =
    currentSubscriptionBundle?.price_interval_count &&
    currentSubscriptionBundle?.price_interval_count !== 1;

  const planType = () => {
    if (featureAddingDentalProduct) {
      if (isCustomInterval) {
        return t('premium.annual.billed_custom_light', {
          intervalCount: currentSubscriptionBundle?.price_interval_count,
        });
      } else if (isAnnual) {
        return t('premium.annual.billed_annually_light');
      } else {
        return t('premium.annual.billed_monthly_light');
      }
    }
    return interval === 'month' ? t('premium.payment.monthly') : t('premium.payment.yearly');
  };

  return (
    <>
      <div className='d-flex w-100 justify-content-between'>
        <h4 className={styles.ctaTitle}>
          {allowProfileModalRefactor ? t("user.profile.plan_information") : t("premium.steps.payment")}
        </h4>
        <Button onClick={handleClick} className={cx(styles.editCta, {[styles.hideEdit]: pastDue})}>{buttonText}</Button>
      </div>

      <div className={cx(styles.statusRow, 'py-2 px-0 mb-2',  { [styles.pastDue]: pastDue })}>
        {allowProfileModalRefactor && (
          <>
            <InfoRow label="user.profile.plan_name">
              <span>{ isPremiumLegacy ? PODERSALUD_PLAN : subscriptionBundleName }</span>
            </InfoRow>
            <InfoRow label="user.profile.plan_type">
              <span dangerouslySetInnerHTML={{ __html: planType() }} />
            </InfoRow>
            <InfoRow label="user.profile.next_payment">
              <span>{nextPaymentDate}</span>
            </InfoRow>
          </>
        )}
        <div className={cx('mb-2', { [styles.hide]: pastDue })}>
          <span className={cx(styles.infoRow, "mt-2")}>
            {allowProfileModalRefactor ? t('premium.billing.default_payment') : t('premium.billing.billing')}
          </span>
          <span className={cx(styles.last4, "mt-2", { [styles.largeFont]: !pastDue })}>...{last4}</span>
        </div>

        {pastDue && (
          <div className={styles.pastDueContainer}>
            <div className={styles.leftCol}>
              <div className='mb-2'>
                <span className={cx(styles.infoRow, "mt-2")}>
                  {allowProfileModalRefactor ? t('premium.billing.default_payment') : t('premium.billing.billing')}
                </span>
                <span className={cx(styles.last4, "mt-2")}>...{last4}</span>
              </div>
              <p className={cx(styles.pastDueMessage)}>{t('premium.past_due.status_message')}</p>
            </div>

            <div className={styles.rightCol}>
              <p onClick={onPayPastDue} className={cx(styles.payNowCta)}>{buttonText}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

BillingStatus.defaultProps = {
  interval: '',
  nextPaymentDate: '',
};

BillingStatus.propTypes = {
  last4: PropTypes.string.isRequired,
  pastDue: PropTypes.bool,
  onPayPastDue: PropTypes.func.isRequired,
  onEditPaymentMethod: PropTypes.func.isRequired,
  interval: PropTypes.string.isRequired,
  nextPaymentDate: PropTypes.string.isRequired,
  subscription: PropTypes.object,
  isPremiumLegacy: PropTypes.bool,
};
