const qa = {
  title: "Tu asesor de confianza",
  delivered: "Enviado",
  error: "Surgió un problema al cargar su historial. Por favor actualice la página e inténtelo de nuevo.",
  no_authn: "¿Tiene preguntas? ¡Tenemos respuestas! Nuestros expertos están aquí para ayudarle a través de nuestro servicio gratuito. Tiempo estimado de respuesta: 24-48 horas",
  no_history: "¡Haga una pregunta! Con gusto solucionaremos todas sus dudas. Tiempo estimado de respuesta: 24-48 horas",
  input_placeholder: "Enviar mensaje...",
  today: "Hoy",
  yesterday: "Ayer",
};

export default qa;
