import axios from 'axios';
import { ADMIN_UNAUTH_ENDPOINT } from '../../env';  // relative path needed for preval webpack step

export default async function baseUnauthAdminQuery(props) {
  const {
    path,
    method,
    data = null,
    params = {},
    locale = 'es',
  } = props;

  const headers = {
    "X-Api-Locale": locale,
  };

  const url = `${ADMIN_UNAUTH_ENDPOINT}/${path}`;
  const response = await axios({
    url,
    method,
    headers,
    data,
    params,
  });
  // TODO: Handle errors

  return response;
}
