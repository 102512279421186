import React from 'react';
import SeparateFooter from '@separate/v4/components/Footer';
import styles from './index.module.scss';
import Content from './Content';
import Watermark from './Watermarks';
import { useV4Translation } from '@v4/src/hooks/translation';

/* eslint react/prop-types: 0 */
export default function Footer() {
  const { t } = useV4Translation();

  let footerLinks = [
    {
      title: t("v4.footer.quick"),
      links: [
        {
          text: t("v4.footer.discover"),
          href: "/"
        },
        {
          text: t("v4.footer.health"),
          href: "/premium"
        },
        {
          text: t("v4.footer.rewards"),
          href: "/earn"
        },
        {
          text: t("v4.footer.knowledge"),
          href: "/articles"
        }
      ]
    },
    {
      title: t("v4.footer.company"),
      links: [
        {
          text: t("v4.footer.about"),
          href: "/about"
        },
        {
          text: t("v4.footer.customer"),
          href: "/qa"
        }
      ]
    }
  ];

  return (
    <>
      <SeparateFooter
        className={styles.footer}
        logo={require('@v4/images/logo-white.png').default.src}
        footerContent={<Content />}
        footerLinks={footerLinks}
        footerWatermark={<Watermark/>}
      />
    </>
  );
}
