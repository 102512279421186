import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getCurrentUser,
  getReferral,
  getReferralDefaultRewards,
  getUserProfile,
  getUserReferrals,
  getUserShortCodesStatus,
  postUserProductParticipation,
  postUserReferral,
  updateUserPanelistStatus,
  updateUserProfile,
  updateUserShortCodesStatus,
} from '@separate/api/sep/user';
import { useCurrentUserSession } from '@separate/hooks/session';
import { useLocale } from '@separate/hooks/translation';

function currentUserKey(idToken) {
  return ['current-user', idToken];
}

export function useCurrentUser() {
  const { isLoading: isSessionLoading, isLoggedIn, idToken } = useCurrentUserSession();

  const result = useQuery(currentUserKey(idToken), getCurrentUser);
  const { isLoading: isUserLoading, data } = result;

  const isLoading = isSessionLoading || isUserLoading;

  return { isLoading, user: data, isLoggedIn };
}

export function useInvalidateCurrentUser() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(currentUserKey(idToken));
}

export function useQueryUserProfile() {
  const result = useQuery('current-user-profile', getUserProfile);
  const { isLoading, data } = result;

  return { isLoading, user: data?.user };
}

export function useFetchUserProfile({ onSuccess }) {
  const result = useMutation(() => getUserProfile(), { onSuccess });
  const { isLoading, mutate } = result;
  return {
    isFetchingProfile: isLoading,
    fetchUserProfile : mutate,
  };
}

export function useUpdateUserProfile({ onSuccess, onError }) {
  const locale = useLocale();
  const result = useMutation(({ user }) => updateUserProfile({ user, locale }),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response.status !== 400,
    });
  const { isLoading, mutate } = result;
  return {
    isUpdatingProfile: isLoading,
    updateUserProfile : mutate,
  };
}

export function useResetUserProfile() {
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries('current-user-profile');
}

export function usePostUserProductParticipation({ onSuccess }) {
  const result = useMutation(({id, interestType, utm_tags}) => postUserProductParticipation(id, interestType, utm_tags),
    {
      onSuccess,
    }
  );

  const { isLoading, mutate } = result;

  return {
    isLoading,
    postUserProductParticipation: mutate,
  };
}

export const useUpdateUserPanelistStatus = () => {
  const { isLoading, mutate } = useMutation(updateUserPanelistStatus);

  return { isLoading, updateUserPanelist: mutate };
};

export const useUpdateUserShortCodes = ({ onSuccess, onError }) => {
  const { isLoading, mutate } = useMutation(
    updateUserShortCodesStatus,
    {
      onSuccess,
      onError,
    }
  );

  return { isLoading, updateUserShortCodes: mutate };
};

export const useGetUserShortCodesStatus = ({ onSuccess, onError }) => {
  const { isLoading, mutate } = useMutation(
    getUserShortCodesStatus,
    {
      onSuccess,
      onError,
    }
  );

  return { isLoading, getUserShortCodes: mutate };
};

export function usePostUserReferral({ onSuccess }) {
  const result = useMutation((userId) => postUserReferral(userId), { onSuccess });
  const { isLoading, mutate } = result;
  return {
    isPostingReferral: isLoading,
    postUserReferral : mutate,
  };
}

export function useFetchUserReferrals({ onSuccess }) {
  const result = useMutation((userId) => getUserReferrals(userId), { onSuccess });
  const { isLoading, mutate } = result;
  return {
    isFetchingReferrals: isLoading,
    fetchUserReferrals : mutate,
  };
}

export function useFetchReferral({ onSuccess, onError }) {
  const result = useMutation((referralCode) => getReferral(referralCode), {
    onSuccess,
    onError,
    useErrorBoundary: ({ response }) => response?.status !== 404,
  });
  const { isLoading, mutate } = result;
  return {
    isFetchingReferral: isLoading,
    fetchReferral : mutate,
  };
}

export function useFetchReferralDefaultRewards({ onSuccess, onError }) {
  const result = useMutation(() => getReferralDefaultRewards(), {
    onSuccess,
    onError,
    useErrorBoundary: ({ response }) => response?.status !== 404,
  });
  const { isLoading, mutate } = result;
  return {
    isFetchingReferralDefaultRewards: isLoading,
    fetchReferralDefaultRewards : mutate,
  };
}
