import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Container } from 'react-bootstrap';
import { useRouter } from 'next/router';

export default function HeaderBar(props) {
  const { className, expand, navItems, navButtons, brand, offCanvasClassName, offCanvasHeader } = props;
  const [expanded, setExpanded] = React.useState(false);
  const router = useRouter();

  function setNavExpanded() {
    setExpanded(!expanded);
  }

  function redirectToHome() {
    router.push("/");
  }

  React.useEffect(() => {
    const handleRouteChange = () => {
      setExpanded(false);
    }

    router.events.on('routeChangeStart', handleRouteChange);
  }, [setExpanded]);

  return (
    <Navbar className={cx(styles.headerBar, className)} expand={expand} sticky="top" onToggle={setNavExpanded} expanded={expanded}>
      <Container className={styles.container}>
      <Navbar.Brand onClick={redirectToHome} className={cx(styles.brand, "me-auto")}>
        {brand ? brand : <span>Home</span>}
      </Navbar.Brand>
      <Navbar.Offcanvas className={cx(styles.offCanvas, offCanvasClassName)} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className={styles.brand}>
            {offCanvasHeader ? offCanvasHeader : brand}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          { navItems }
        </Offcanvas.Body>
        <hr />
      </Navbar.Offcanvas>
      {navButtons}
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} className={cx(styles.toggle, "px-0")}/>
      </Container>
    </Navbar>
  );
}

HeaderBar.propTypes = {
  className: PropTypes.string,
  offCanvasClassName: PropTypes.string,
  disableLanguageSelect: PropTypes.bool,
  expand: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  navItems: PropTypes.element.isRequired,
  navButtons: PropTypes.element,
  brand: PropTypes.element,
  offCanvasHeader: PropTypes.element
};

HeaderBar.defaultProps = {
  expand: "xl"
}
