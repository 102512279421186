import React from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import { useTranslation } from '@separate/hooks/translation';
import styles from './podersaludUpgradeSubscriptionPlanModal.module.scss';

const PodersaludSubscriptionModal = (props) => {
  const {
    onHide,
    title,
    subtitle,
    description,
    buttonText,
    children,
    onClick,
    container,
    hidePaymentMethods,
    isRetryPayment,
    handleClickAddNewCard,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      className={cx(styles.podersaludUpgradeSubscriptionPlanModal, {[styles.hideModal]: hidePaymentMethods})}
      show
      onHide={onHide}
      bodyClassName={styles.body}
      close={false}
      backdropClassName={cx({[styles.backdrop]: hidePaymentMethods})}
      dialogClassName={styles.dialog}
      backdrop={!hidePaymentMethods}
      container={container}
    >
      <div className={styles.closeModal}>
        <p>{title}</p><a onClick={() => onHide(false)} />
      </div>

      <div className='d-flex justify-content-left align-items-center row'>
        <p className={styles.textLight}>{subtitle}</p>
      </div>

      <div className='d-flex justify-content-center align-items-center row'>
        {children}
      </div>

      {description && (
        <div className='d-flex justify-content-left align-items-center row mt-3'>
          <p className={styles.textLight}>{description}</p>
        </div>
      )}

      <div className='d-flex justify-content-center align-items-center row mx-5 mt-4'>
        {buttonText && <Button className='py-2' onClick={onClick}>{buttonText}</Button>}

        {isRetryPayment && (
          <Button
            onClick={() => handleClickAddNewCard()}
            className={cx(styles.buttonLight, 'py-2 mb-3')}
          >
            {t("premium.upgrade_subscription_plan.change_payment_method")}
          </Button>
        )}

      </div>
    </Modal>
  );
};

PodersaludSubscriptionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  container: PropTypes.any,
  hidePaymentMethods: PropTypes.bool,
  isRetryPayment: PropTypes.bool,
  handleClickAddNewCard: PropTypes.any,
};

export default PodersaludSubscriptionModal;
