import React from 'react';
import { useRouter } from "next/router";
import { USER_LANGUAGE } from "@separate/constants/language";
import { useCurrentUser } from "@separate/hooks/user";
import { getCookie } from "@separate/utilities/cookies";

export const useSetUserLanguage = () => {
  const { user } = useCurrentUser();
  const router = useRouter();

  React.useEffect(() => {
    if (!user || !router?.isReady) return;

    const userSetLanguage = getCookie(USER_LANGUAGE);
    const { asPath, locale, pathname, replace } = router;

    const newLang = userSetLanguage ? userSetLanguage : user.language;

    if (newLang && newLang !== locale && asPath === pathname) {
      replace(asPath, asPath, { locale: newLang });
    }
  }, [router, user]);
};
