import React from 'react';
import { useRouter } from 'next/router';

export function useHistoryWithTranslations() {
  const { locale, query, beforePopState, asPath, replace } = useRouter();

  React.useEffect(() => {
    // triggers for back and forward browser buttons
    beforePopState(({ url, as, options}) => {
      const { locale: newLocale } = options;
      // when path was changed & locale was changed between current and new page
      if (as !== asPath && locale !== newLocale) {
        let newPath = as;
        if (as.startsWith('/en/')) newPath = as.slice(3);
        if (as === '/en') newPath = '/';
        replace(newPath, newPath, { locale: locale });

        // refresh the page since previous locale was changed
        return false;
      }
      return true;
    });
  }, [asPath, locale, replace, beforePopState]);
}
