import * as React from 'react';
import { useTranslation } from '@separate/hooks/translation';
import useValidator from './useValidator';

export default function useInputFormState(validations = []) {
  const [value, setValue] = React.useState({});

  const { t } = useTranslation();

  const validate = useValidator(validations);

  const { isValid, fieldErrors, recordErrors, clearErrors, clearFieldErrors, mergeErrors, mergeServerErrorsWithDetails } = validate(value);

  function onFieldChange(field, newValue) {
    setValue({ ...value, [field]: newValue });
    clearErrors(field);
  }

  function onFieldBlur(field) {
    recordErrors(field);
  }

  function labelFor(field) {
    const key = validations[field]?.translationKey;

    return key ? t(key) : null;
  }

  return {
    value,
    setValue,
    isValid,
    fieldErrors,
    mergeErrors,
    onFieldChange,
    onFieldBlur,
    labelFor,
    clearErrors,
    clearFieldErrors,
    mergeServerErrorsWithDetails,
  };
}
