import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@separate/hooks/translation';
import styles from "./basicInfo.module.scss";

export default function InfoRow(props) {
  const { label, children } = props;
  const { t } = useTranslation();

  return (
    <div className='d-flex align-items-center mb-4'>
      <span className={styles.infoRow}>{t(`${label}`)}</span>
      <div className={styles.userInfo}>{children}</div>
    </div>
  );
}

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
