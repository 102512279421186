const articles = {
  published: "Published",
  by: "By",
  other: "Other",
  template: {
    return_to_guides: "Return to articles"
  },
  index: {
    articles_title: "Articles",
    filter_by_category: "Filter by category",
    featured: "Featured",
    all: "All articles"
  },
  categories: {
    coronavirus: "coronavirus",
    finance: "finance",
    health: "health",
    education: "education",
    legal: "legal",
    benefits: "benefits",
  },
  guides: {
    coronavirus: "Other coronavirus guides",
    finance: "Other finance guides",
    health: "Other health guides",
    education: "Other education guides",
    legal: "Other legal guides",
    benefits: "Other benefits guides",
  },
  article_image: 'Article Image',
  no_results: "No results found for",
  search: "Search",
  search_results: "Results for",
  social_share: {
    copy_link: "Link copied!"
  },
  premium: {
    message: {
      title: "This exclusive content is for Premium Members",
      cta: "Become a Premium Member",
      member: "Already a Premium Member?",
      link: "Sign in here",
      home: "Dismiss"
    }
  },
};

export default articles;
