import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useTranslation } from '@separate/hooks/translation';
import { useQueryUserProfile } from '@separate/hooks/user';
import { isFeatureEnabled } from '@separate/utilities/features';
import Address from './Address';
import styles from './basicInfo.module.scss';
import Email from './Email';
import Gender from './Gender';
import Name from './Name';
import Phone from './Phone';

export default function BasicInfo(props) {
  const { onEdit } = props;
  const { user } = useQueryUserProfile();
  const { t } = useTranslation();

  function handleClick() {
    onEdit();
  }

  const allowProfileModalRefactor = isFeatureEnabled('allow_profile_modal_refactor');
  const hasFullInfo = user?.is_panelist || user?.is_premium_member || user?.is_podercard_user;

  return (
    <div>
      <div className='d-flex w-100 justify-content-between'>
        <h4 className={styles.ctaTitle}> {t("user.profile.edit_cta_title")} </h4>
        <Button onClick={handleClick} className={styles.editCta}> {t("user.profile.edit_cta")} </Button>
      </div>

      {user && (
        <div className={styles.infoContainer}>
          {(!hasFullInfo || allowProfileModalRefactor) && (
            <>
              <Name
                firstName={user.first_name}
                lastName={user.last_name}
              />
              <Phone
                phoneNumber={user.phone_number}
              />
            </>
          )}

          {(hasFullInfo && !allowProfileModalRefactor) && (
            <>
              <Name
                firstName={user.first_name}
                lastName={user.last_name}
              />
              <Gender gender={user.gender} />
              <Email email={user.email} />
              <Phone
                phoneNumber={user.phone_number}
              />
              <Address address={user} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

BasicInfo.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
