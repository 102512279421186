const jobs = {
  apply: 'Apply',
  back: 'Back',
  complete_profile: {
    title: 'Looks like you have not completed your profile. Add your preferences now and get personalized job recommendations!',
    progress: 'completed',
    cta: 'Complete my profile',
  },
  edit_profile: {
    title: 'My profile',
    subtitle: 'Complete',
    cta: 'Edit',
  },
  header: {
    jobs: 'Jobs',
    jobs_network: 'Jobs Network',
    jobs_resources: 'Resource Center',
    next_job_title: 'Find your next job!',
    next_job_subtitle: {
      none: 'Sign up today to see personalized job opportunities.',
      registered: 'Get personalized job recommendations by taking a few minutes to finish your profile.',
      jobs_profile_complete: 'Personalized job opportunities and resources to help speed up your search.',
    },
    cta: 'Join now',
    search: 'Search by job title, occupation or company',
    search_articles: 'Search',
    location: 'City, state, or zip code',
  },
  jobs_text: 'Jobs',
  join_for_free: 'Join for free',
  results_in_location: '{{ results }} results near',
  resources: {
    header: 'Resources',
    main: {
      title: 'Career advice ',
      inner_title: 'that you can count on.',
    },
    job_search: {
      title_one: 'Finding ',
      title_two: 'a job',
      subtitle: 'Nail your search, resume prep, and interviews',
    },
    workplace_success: {
      title_one: 'Thriving ',
      title_two: 'at work',
      subtitle: 'Your guide to the workplace, benefits, and more',
    },
    career_transitions: {
      title_one: 'Transitioning ',
      title_two: 'careers',
      subtitle: 'Education and skills to enter a new occupation',
    },
    income_generation: {
      title_one: 'Generating ',
      title_two: 'income',
      subtitle: 'Tips to grow your income or start a business',
    },
  },
  resources_no_results: "No results found for",
  update_location: 'Update location',
  view_all: 'View All',
  view_more: 'View more',
  go: 'Go',
  view_more_jobs: 'View more jobs',
  zip_code_validation_error: 'Please enter a 5-digit ZIP code',
};

export default jobs;
