import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SubscriptionBundleRadioCard from '@separate/components/CTA/SubscriptionBundleRadioCard';
import { useSubscriptionBundlesFetch } from '@separate/hooks/premium';
import { useLocale } from '@separate/hooks/translation';
import { asPercent } from '@separate/utilities/string';
import styles from './paymentPlanSelector.module.scss';

export default function PaymentPlanSelector({
  selectedSubscriptionBundle,
  setSelectedSubscriptionBundle,
  currentSubscription,
}) {
  const locale = useLocale();

  const [subscriptionBundles, setSubscriptionBundles] = React.useState([]);

  const onFetchSubscriptionBundlesSuccess = (response) => {
    if(currentSubscription) {
      const nonCurrentSubscriptionBundles = response.filter((bundle) => bundle.id !== currentSubscription.subscription_bundle_id);
      setSelectedSubscriptionBundle(nonCurrentSubscriptionBundles[0]);
      setSubscriptionBundles(nonCurrentSubscriptionBundles);
    }
    else {
      setSelectedSubscriptionBundle(response[0]);
      setSubscriptionBundles(response);
    }
  };

  const { fetchSubscriptionBundles } = useSubscriptionBundlesFetch({ onSuccess: onFetchSubscriptionBundlesSuccess });

  useEffect(() => {
    fetchSubscriptionBundles();

  }, [fetchSubscriptionBundles]);

  const bundleGroupDiscountPercentage = (selectedBundle) => {
    if(subscriptionBundles.length < 2 || selectedBundle.price_interval_count === 1) return null;
    const unitBundle = subscriptionBundles.find(bundle => (
      bundle.price_interval === 'month' &&
      bundle.price_interval_count === 1 &&
      bundle.ui_group_name === selectedBundle.ui_group_name
    ));

    if(!unitBundle) return null;
    return asPercent(1 - ((selectedBundle.unit_amount * selectedBundle.price_interval_count) / (unitBundle.unit_amount * selectedBundle.price_interval_count) ));
  };

  return (
    <Col>
      {subscriptionBundles.map((bundle, index) => (
        <Row key={bundle.id} className="d-flex flex-column align-items-center justify-content-center p-1">
          <SubscriptionBundleRadioCard
            subscriptionPrice={bundle.unit_amount}
            disabled={!bundle.active}
            selected={selectedSubscriptionBundle?.id === bundle.id}
            onClick={() => setSelectedSubscriptionBundle(bundle)}
            subscriptionName={locale === 'es' ? bundle.name_es : bundle.name_en}
            subscriptionDescription={locale === 'es' ? bundle.description_es : bundle.description_en}
            subscriptionInterval={bundle.price_interval}
            subscriptionIntervalCount={bundle.price_interval_count}
            bundleGroupDiscountPercentage={bundleGroupDiscountPercentage(bundle)}
          />
        </Row>
      ))}
    </Col>
  );
}

PaymentPlanSelector.propTypes = {
  annualSelected: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  setAnnualSelected: PropTypes.func.isRequired,
  pid: PropTypes.string, // for zendesk-sep-admin
  selectedSubscriptionBundle: PropTypes.object,
  setSelectedSubscriptionBundle: PropTypes.func,
  currentSubscription: PropTypes.object,
};
