const premium = {
  coupon: {
    placeholder: "Add coupon",
    success: {
      title: "Success!",
      message: "Your coupon has been redeemed",
    },
    error: {
      title: "There was a problem",
      message: "Incorrect Coupon Code",
    },
    redeem: "Redeem",
    payment_one: "payment",
    payment_other: "{{ count }} payments",
    coupon_applied: "Congratulations! You will receive a {{ discount }} discount on your first {{ duration }}",
    your_subscription: 'You have {{ coupon }} OFF your subscription!',
    discount_will_be_automatically_applied_at_checkout: 'Credit will be automatically applied at checkout.',
  },
  payment: {
    title: "Payment",
    choose_the_plan: "Choose the plan that works best for you ",
    subtitle: "Enter your payment information and start enjoying your benefits.",
    plan_type: "Plan type",
    selected: "Selected:",
    plan_price: "Plan price",
    membership: "SEP+ Membership",
    annual_membership: "Yearly membership",
    monthly: "Monthly",
    monthly_membership: "Monthly membership",
    price: "Price",
    duration: "month",
    credit_card_number: "Card Number",
    expiry: "Exp mm/yy",
    cvv: "CVV",
    confirm_purchase: "Confirm purchase",
    failure_title: "There was a problem",
    product_name: "Premium Membership",
    or: "Or",
    pay_with_card: "Pay with card",
    update_and_pay: "Update + Pay",
    yearly: "Yearly",
    proceed: "Go to payment",
    one_time_coupon: 'One-time discount',
    one_time_credit: 'One-time credit',
    total: 'Total',
    confirmed: 'Payment confirmation',
    received: 'Thank you! Your payment has been received.',
    processing: 'Processing payment...',
    change_payment_method: 'Payment method',
    pay_now: 'Pay now',
    added: 'New card added successfully!',
    added_subtitle: 'Do you want to pay your subscription with this card?',
    failed: 'Oh no, your payment failed',
    ensure_funds_or_new_card: 'Make sure you have sufficient funds on your card or try adding a new card.',
    will_be_default: 'This card will be used as your default card for future payments',
    new_card_added: 'New card added!',
    new_card_delay: 'Please allow 5 minutes for your new card to be displayed in your profile.',
    free_trial: 'Free trial',
  },
  past_due: {
    status_message: "Your PODERsalud subscription payment failed. Please update your payment method and check your card balance.",
    success_title: "Congratulations",
    success_message: "Your payment has been successfully processed and card information has been updated.",
  },
  not_available: "Sorry, this product is not available in your state.",
  update_payment: {
    success_title: "Congratulations",
    // TODO: We need copy for this for realz
    success_message: "Your card information has been updated.",
  },
  annual: {
    billed_annually: "Billed <strong>annually</strong>",
    billed_monthly: "Billed <strong>monthly</strong>",
    billed_custom: "Billed <strong>every {{ intervalCount }} months</strong>",
    billed_annually_light: "Billed annually",
    billed_monthly_light: "Billed monthly",
    billed_custom_light: "Billed every {{ intervalCount }} months",
    discount_percentage: "{{ discount }} savings",
    months_free: "{{ number }} months free",
    no_discount: "No discount",
    pay_full_price: "Pay full price",
    per_month: "/ month",
    per_year: "/ year",
    per_custom_interval: "/ {{ intervalCount }} months",
  },
  ask_us_anything: {
    title: "Ask us anything",
    got_questions: "Got questions? Want to find out if PODERsalud is the right choice for you? We’re happy to assist!",
    talk_with_an_expert: "Talk with an expert",
  },
  upgrade_subscription_plan: {
    monthly: 'monthly',
    yearly: 'anual',
    next_billing_date: 'Next billing date:',
    save_more: 'Switch your plan today',
    update_your_plan: 'Update your plan',
    upgrade_now: 'Upgrade now',
    save_over_50_per_year: 'Save on your PODERsalud subscription with our annual plan.',
    you_will_be_charge_today_the_full_year: 'You will be charged today the full year. Your next billing date will be {{ next_payment }}.',
    proceed_to_payment: 'Proceed to payment',
    process_payment: 'Process payment',
    processing_payment: 'Processing payment...',
    your_payment_has_been_received: 'Thank you! Your payment has been received. Your next billing date is {{ next_payment }}.',
    your_plan_has_been_updated: 'Your plan has been updated!',
    close: 'Close',
    your_payment_failed: 'Oh no, your payment failed',
    make_sure_you_have_sufficient_funds: 'Make sure you have sufficient funds on your card or try adding a new card.',
    try_again: 'Try again',
    add_payment: 'Add payment',
    revise_your_payment_method: 'Revise your payment method',
    confirm_your_payment_method: 'Confirm your payment method',
    retry_with_this_card: 'Retry with this card',
    you_can_try_again: 'You can try again with your selected method of payment or change it.',
    go_back: 'Go Back',
    select_payment_option: 'Select payment option',
    change_payment_method: 'Change payment method',
    upgrade_plan: 'Change plan',
  },
  price_increase: {
    title: 'New pricing starting May 16th!',
    text: 'Act quickly to subscribe for $16.95 per month.',
  },
  free_trial: {
    banner: {
      title: 'Unlock your free* trial',
      description: 'Discounts on healthcare benefits <span>for {{ count }} day free*</span>',
      description_plural: 'Discounts on healthcare benefits <span>for {{ count }} days free*</span>',
    },
    splash: {
      bulletBox: [
        {
          item: '<span>{{ numberOfDays }}-day</span> free* trial',
        },
        {
          item: 'Enjoy all premium features during the trial period',
        },
        {
          item: 'Access to customer support for assistance',
        },
        {
          item: 'Cancel at anytime',
        },
      ],
      learn_more: 'Learn more',
      text: 'Discounts on healthcare care benefits for {{ numberOfDays }} days free',
      title: 'Unlock your  <span>Free* Trial</span>',
    },
    terms_and_conditions: '*Free trial terms and conditions',
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Discount Plan Application. This program is NOT insurance coverage and does not meet the minimum creditable coverage requirements under the Affordable Care Act or Massachusetts M.G.L. c. 111M and 956 CMR 5.00. Your membership is effective upon receipt of membership materials. This program is not available to UT, VT and WA residents. It contains a 30 day cancellation period, provides discounts only at the offices of contracted health care providers, and each member is obligated to pay the discounted medical charges in full at the point of service. The range of discounts for medical or ancillary services provided under the program will vary depending on the type of provider and medical or ancillary service received. Member shall receive a reimbursement of all periodic membership fees if membership is cancelled within the first 30 days after the effective date. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475 Form #HPSEP2",
    ],
  },
};

export default premium;
