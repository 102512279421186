// This is copied almost verbatim from proton.  I guess we don't localize countries and Mexican states.
// The only change is to replace `value` with `id` in keeping with the idType in this project.
// Also the id's are strings here because that is the type that comes out of the Select component.

export const MEXICO_CULTURE_ID = "1";
export const OTHER_CULTURE_ID = "23";

export const CULTURES = [
  { id: MEXICO_CULTURE_ID, text: "user.culture.mexico" },
  { id: "2", text: "user.culture.el_salvador" },
  { id: "3", text: "user.culture.guatemala" },
  { id: "4", text: "user.culture.united_states" },
  { id: "5", text: "user.culture.colombia" },
  { id: "6", text: "user.culture.puerto_rico" },
  { id: "7", text: "user.culture.costa_rica" },
  { id: "8", text: "user.culture.cuba" },
  { id: "9", text: "user.culture.nicaragua" },
  { id: "10", text: "user.culture.ecuador" },
  { id: "11", text: "user.culture.unknown" },
  { id: "12", text: "user.culture.honduras" },
  { id: "13", text: "user.culture.argentina" },
  { id: "14", text: "user.culture.bolivia" },
  { id: "15", text: "user.culture.chile" },
  { id: "16", text: "user.culture.dominican_republic" },
  { id: "17", text: "user.culture.panama" },
  { id: "18", text: "user.culture.paraguay" },
  { id: "19", text: "user.culture.peru" },
  { id: "20", text: "user.culture.spain" },
  { id: "21", text: "user.culture.uruguay" },
  { id: "22", text: "user.culture.venezuela" },
  { id: OTHER_CULTURE_ID, text: "user.culture.other" }
];

export const MEXICO_STATES = [
  { id: "1", text: "Aguascalientes" },
  { id: "2", text: "Baja California" },
  { id: "3", text: "Baja California Sur" },
  { id: "4", text: "Campeche" },
  { id: "5", text: "Chiapas" },
  { id: "6", text: "Chihuahua" },
  { id: "7", text: "Coahuila" },
  { id: "8", text: "Colima" },
  { id: "9", text: "Distrito Federal" },
  { id: "10", text: "Durango" },
  { id: "11", text: "Guanajuato" },
  { id: "12", text: "Guerrero" },
  { id: "13", text: "Hidalgo" },
  { id: "14", text: "Jalisco" },
  { id: "15", text: "Estado de Mexico" },
  { id: "16", text: "Michoacan" },
  { id: "17", text: "Morelos" },
  { id: "18", text: "Nayarit" },
  { id: "19", text: "Nuevo Leon" },
  { id: "20", text: "Oaxaca" },
  { id: "21", text: "Puebla" },
  { id: "22", text: "Queretaro" },
  { id: "23", text: "Quintana Roo" },
  { id: "24", text: "San Luis Potosi" },
  { id: "25", text: "Sinaloa" },
  { id: "26", text: "Sonora" },
  { id: "27", text: "Tabasco" },
  { id: "28", text: "Tamaulipas" },
  { id: "29", text: "Tlaxcala" },
  { id: "30", text: "Veracruz" },
  { id: "31", text: "Yucatan" },
  { id: "32", text: "Zacatecas" }
];

const SHORT_CODES_MAIN = 1;
const SHORT_CODES_RESEARCH = 2;
const SHORT_CODES_MARKETING = 3;

export const SHORT_CODES_IDS = [SHORT_CODES_MAIN, SHORT_CODES_RESEARCH, SHORT_CODES_MARKETING];

export const SHORT_CODES_TYPES = [
  {
    id: SHORT_CODES_RESEARCH,
    type: 'sep_rewards',
  },
  {
    id: SHORT_CODES_MAIN,
    type: 'sep_info_and_chat',
  },
];

// User subscription plan type
export const PODERSALUD_PLAN = 'PODERsalud';
