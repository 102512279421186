import React from 'react';
import PropTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { PAYMENT_METHOD_DEFAULT_FAILURE, PAYMENT_METHOD_DEFAULT_SUCCESS } from '@separate/constants/analytics';
import { useUpdateDefaultPaymentMethod } from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { ChevronDownRounded } from '@separate/icons';
import { logEvent } from '@separate/utilities/analytics';
import styles from './defaultDeletePaymentMethodOffcanvas.module.scss';

export default function DefaultDeletePaymentMethodOffcanvas({
  container,
  method,
  onDefaultSuccess,
  onDeleteCard,
  onHide,
  show,
  pid,
}) {
  const { t } = useTranslation();

  function handleHide() {
    onHide();
  }

  function handleDefaultSuccess() {
    logEvent(PAYMENT_METHOD_DEFAULT_SUCCESS, { payment_method_id: method.id });
    handleHide();
    onDefaultSuccess();
  }

  function handleDefaultError() {
    logEvent(PAYMENT_METHOD_DEFAULT_FAILURE, { payment_method_id: method.id });
    handleHide();
  }

  const { updateDefaultPaymentMethod } = useUpdateDefaultPaymentMethod({
    onSuccess: handleDefaultSuccess,
    onError: handleDefaultError,
  });

  function handleDefault() {
    updateDefaultPaymentMethod({paymentMethodId: method.id, pid});
  }

  function handleDelete() {
    onDeleteCard();
    handleHide();
  }

  return (method ? (
    <Offcanvas
      backdropClassName={styles.backdrop}
      className={styles.container}
      container={container}
      onHide={handleHide}
      placement="bottom"
      show={show}
    >
      <Offcanvas.Header className={styles.header}>
        <Offcanvas.Title className={styles.title}>
          {t('user.profile.payment_method')}
        </Offcanvas.Title>
        <ChevronDownRounded aria-label="Hide" className={styles.closeButton} onClick={handleHide} />
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.body}>
        <div className={styles.default} onClick={handleDefault}>
          {t('user.profile.make_default')}
        </div>
        {!method.card?.wallet && (
          <div className={styles.delete} onClick={handleDelete}>
            {t('user.profile.delete')}
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  ) : (
    null
  ));
}

DefaultDeletePaymentMethodOffcanvas.propTypes = {
  container: PropTypes.any,
  method: PropTypes.object,
  onDefaultSuccess: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  pid: PropTypes.string, // for zendesk-sep-admin
};
