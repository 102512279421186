import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { idType, optionType } from '@/types';
import styles from './radioGroup.module.scss';

export default function RadioGroup(props) {
  const { options, value, onChange, disabled, className, ...extraProps } = props;

  function isChecked(id) {
    return value === id;
  }

  function handleChange(id) {
    onChange(id);
  }

  return (
    <div>
      {options.map(({ id, text }) => (
        <Form.Check
          type="radio"
          key={id}
          id={id}
          label={text}
          disabled={disabled}
          checked={isChecked(id)}
          onChange={() => handleChange(id)}
          {...extraProps}
          className={cx(styles.formCheck, className)}
        />
      ))}
    </div>
  );
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(optionType),
  value: idType,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
