import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { CloseCircle } from '@/icons';
import { useTranslation } from '@separate/hooks/translation';
import Profile from './Profile';
import styles from './profile.module.scss';
import cx from 'classnames';

export default function ProfileModal(props) {
  const { show, onHide, showPayment } = props;
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide} fullscreen='lg-down' className={styles.modal} scrollable={true}>
      <Modal.Body className={styles.modalContent}>
        <section className={cx(styles.headerContent, 'px-3')}>
          <div className={styles.closeModal}>
            <CloseCircle onClick={onHide} />
          </div>
          <div className={styles.text}>
            <h2> {t('user.profile.header_title')} </h2>
          </div>
        </section>
        <div className={styles.horizontalLine} />

        <section className={cx(styles.bodyContent, 'p-3')}>
          <Profile onDismiss={onHide} showPayment={showPayment} />
        </section>
      </Modal.Body>
    </Modal>
  );
}

ProfileModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  showPayment: PropTypes.bool,
};
