import React from 'react';
import PropTypes from 'prop-types';
import BootstrapSpinner from 'react-bootstrap/Spinner';

export default function Spinner({ small, variant }) {
  return (
    <BootstrapSpinner animation="border" size={small ? 'sm' : undefined} variant={variant} />
  );
}

Spinner.propTypes = {
  small: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'black']),
};

Spinner.defaultProps = {
  small: false,
  variant: 'black',
};
