import { useQuery, useQueryClient } from 'react-query';
import { getIdToken } from '@separate/utilities/auth';

const CACHE_KEY = 'current-user-session';

export function useSetCurrentUserSession() {
  const queryClient = useQueryClient();
  return (idToken) => queryClient.setQueryData(CACHE_KEY, idToken);
}

export function useCurrentUserSession() {
  const result = useQuery(CACHE_KEY, getIdToken);
  const { isLoading, data } = result;

  return { isLoading, idToken: data, isLoggedIn: !!data };
}
