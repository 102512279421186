import React from 'react';
import Image from "next/image";

export const csLoader = ({ src, ...params }) => {
  let url = src;
  let added = false;

  if (params) {
    for (const option in params) {
      if (params[option]) {
        url += `${added ? '&' : '?'}${option}=${params[option]}`;
        added = true;
      }
    }
  }

  return url;
};

export const getImage = (slug, name, height, width) => {
  return (
    <Image
      src={slug}
      alt={name}
      loader={csLoader}
      height={height}
      width={width}
    />
  );
};
