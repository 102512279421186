const consulate = {
  enrollment: {
    first_name: "Nombre",
    last_name: "Apellido",
    phone_number: "Celular",
    birthday: "Fecha de nacimiento",
    birth_month: "Mes de nacimiento",
    birth_year: "Año de nacimiento",
    cta: "Siga",
    clear: "Borrar todas las entradas",
    main: {
      header: {
        welcome: "SABER es",
        opportunity: "Oportunidad.",
      },
      subheader: "Servicios a la medida para usted y su familia."
    },
    account: {
      header: "Únase a nuestra comunidad",
      subheader: "Obtenga acceso a beneficios y servicios hoy."
    },
    success: {
      header: "¡Le damos la bienvenida!",
      text: "Gracias por estar aquí. Recibirá un mensaje de texto con  información para acceder sus servicios y beneficios.<br/><br/>Si tiene dudas, ¡acérquese a nuestro representante! Con gusto le ayudará.",
      cta: "Listo"
    },
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ]
  }
};

export default consulate;
