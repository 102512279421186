const address = {
  address_line_1: "Calle",
  address_line_2: "Apt/Suite/Otro",
  city: "Ciudad",
  state: "Estado",
  zipcode: "Código postal",
  verification: {
    is_this_correct: "¿Es esto correcto?",
    not_found_title: "Dirección no válida",
    not_found_description: "No pudimos ubicar su dirección.",
    yes: "Sí",
    no_edit_address: "No, editar",
    edit_address: "Editar",
  },
};

export default address;
