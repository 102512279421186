import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { optionType, idType, inputFormStateType } from "@/types";
import styles from "./select.module.scss";

export default function Select(props) {
  const {
    options,
    label,
    value,
    onChange,
    onBlur,
    isInvalid,
    className,
    allowBlank,
    name,
    inputFormState,
    ...extraProps
  } = props;

  function handleChange({ target }) {
    const { value: newValue } = target;
    if (onChange) {
      onChange(newValue);
    }

    if (inputFormState) {
      inputFormState.onFieldChange(name, newValue);
    }
  }

  function handleBlur() {
    if (onBlur) {
      onBlur();
    }
    if (inputFormState) {
      inputFormState.onFieldBlur(name);
    }
  }

  const defaultValue = allowBlank ? "" : options[0].id;
  const currentValue = (inputFormState ? inputFormState.value[name] : value) || defaultValue;
  const isCurrentlyInvalid = inputFormState ? name in inputFormState.fieldErrors : isInvalid;
  const currentLabel = label || inputFormState?.labelFor(name);

  return (
    <div className={cx(styles.selectContainer, 'selectContainer')}>
      {currentValue === "" && (
        <>
          <p className={cx(styles.emptyLabel, 'emptyLabel')}> {currentLabel} </p>
        </>
      )}
      <Form.Select
        id={name}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cx(
          className,
          styles.select,
          { [styles.nullOption]: !currentValue },
          { [styles.isInvalid]: isCurrentlyInvalid }
        )}
        isInvalid={isCurrentlyInvalid}
        value={currentValue}
        {...extraProps}
      >

        {allowBlank && (
          <option value={null}>{" "}</option>
        )}

        {options.map(({ id, text }) => (
          <option value={id} key={id}>
            {text}
          </option>
        ))}
      </Form.Select>

      {!!currentValue && currentValue !== "" &&(
        <Form.Label
          htmlFor={name}
          className={cx(
            styles.textLabel,
            {[styles.isInvalid]: isCurrentlyInvalid}
          )}
        >
          {currentLabel}
        </Form.Label>
      )}
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(optionType),
  label: PropTypes.string,
  value: idType,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  allowBlank: PropTypes.bool,
  name: PropTypes.string,
  inputFormState: inputFormStateType,
};
