import React from 'react';
import PropTypes from 'prop-types';
import { useSubscriptionQuery } from '@separate/hooks/premium';
import { capitalizeFirstLetter, formatDateWithComma } from '@separate/utilities/datetime';
import { isSubscriptionActive, isSubscriptionPastDue, isSubscriptionProvisioning } from '@separate/utilities/premium';
import BillingStatus from './BillingStatus';

export default function PlanInfo(props) {
  const { onPayPastDue, onEditPaymentMethod, isPremiumLegacy } = props;

  const { isLoading, subscription } = useSubscriptionQuery({ options: { fastRefresh: true } });

  const active = isSubscriptionActive(subscription);
  const provisioning = isSubscriptionProvisioning(subscription);
  const pastDue = isSubscriptionPastDue(subscription);
  const last4 = subscription?.last4;

  const shouldDisplay = !isLoading && (active || provisioning) && last4;

  return (
    <>
      {shouldDisplay && (
        <BillingStatus
          pastDue={pastDue}
          last4={last4}
          onPayPastDue={onPayPastDue}
          onEditPaymentMethod={onEditPaymentMethod}
          interval={subscription?.interval}
          subscription={subscription}
          nextPaymentDate={`${capitalizeFirstLetter(formatDateWithComma(subscription?.next_payment_at))}`}
          isPremiumLegacy={isPremiumLegacy}
        />
      )}
    </>
  );
}

PlanInfo.propTypes = {
  onPayPastDue: PropTypes.func.isRequired,
  onEditPaymentMethod: PropTypes.func.isRequired,
  isPremiumLegacy: PropTypes.bool,
};
