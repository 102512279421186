import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ProfileModal from '@v4/src/components/ProfileModal';
import { useTranslation } from '@separate/hooks/translation';
import { CardFailed } from '@separate/icons';
import styles from './missedPaymentModal.module.scss';

export default function MissedPaymentModal(props) {
  const { show, onHide, setShowMissedPaymentBanner } = props;
  const { t } = useTranslation();
  const [showProfileModal, setShowProfileModal] = React.useState(false);

  const handleDismissModal = () => {
    setShowMissedPaymentBanner(true);
    onHide();
  };

  const handlePayButton = () => {
    setShowProfileModal(true);
    setShowMissedPaymentBanner(true);
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleDismissModal}
        centered
        backdropClassName={styles.modalBackdrop}
        contentClassName={styles.modalContent}
      >
        <Modal.Body>
          <div className='p-2 d-flex flex-column align-items-center'>
            <CardFailed />
            <h4 className={cx(styles.header, 'mt-3 text-center')}>{t('premium.missed_payment.header')}</h4>
            <p className={cx(styles.textContent, 'text-center')}>{t('premium.missed_payment.text_content')}</p>
            <div className='mt-3'>
              <Button className={styles.closeButton} onClick={handleDismissModal}>{t('premium.missed_payment.close_cta')}</Button>
              <Button className={styles.payButton} onClick={handlePayButton}>{t('premium.missed_payment.update_now_cta')}</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ProfileModal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        showPayment
      />
    </>
  );
}

MissedPaymentModal.propTypes ={
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setShowMissedPaymentBanner: PropTypes.func,
};
