import * as React from 'react';
import { compact } from '@separate/utilities/object';
import { formatFieldError } from '@separate/utilities/serverErrors';

export default function useValidator(validations) {
  const useValidationFunction = (values) => {
    const [fieldErrors, setFieldErrors] = React.useState({});

    let isValid = true;
    const allErrors = {};

    for (let field in validations) {
      const { translationKey, rules = [], onlyIf } = validations[field];
      if (onlyIf && !onlyIf(values)) {
        continue;
      }
      for (let rule of rules) {
        const error = rule(values[field]);
        if (error) {
          isValid = false;
          allErrors[field] ||= [];
          allErrors[field].push({ error, field: translationKey });
        }
      }
    }

    function recordErrors(field) {
      setFieldErrors(compact({ ...fieldErrors, [field]: allErrors[field] }));
    }

    function clearErrors(field) {
      if (!fieldErrors[field]) return;

      setFieldErrors(compact({ ...fieldErrors, [field]: undefined }));
    }

    function clearFieldErrors() {
      setFieldErrors({});
    }

    function mergeErrors(errors) {
      if (!errors) return;
      const newFieldErrors = { ...fieldErrors };

      for (let field of Object.keys(errors)) {
        const messages = errors[field].map((message) => formatFieldError(message));
        newFieldErrors[field] = messages;
      }

      setFieldErrors(newFieldErrors);
    }

    function mergeServerErrorsWithDetails(error, field = "invalid") {
      setFieldErrors(compact({ ...fieldErrors, [field]: [{error: error, field: field}] }));
    }

    return {
      isValid,
      fieldErrors,
      recordErrors,
      clearErrors,
      clearFieldErrors,
      mergeErrors,
      mergeServerErrorsWithDetails,
    };
  };

  return useValidationFunction;
}
