import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import BCard from 'react-bootstrap/Card';

export default function Card({
  children,
  className,
  footer,
  handleClick,
  header,
  ...extraProps
}) {
  return (
    <BCard
      className={className}
      onClick={handleClick}
      {...extraProps}
    >
      {header && (
        <BCard.Title>
          {header}
        </BCard.Title>
      )}
      {children && (
        <BCard.Body>
          {children}
        </BCard.Body>
      )}
      {footer && (
        <BCard.Footer>
          {footer}
        </BCard.Footer>
      )}
    </BCard>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.node,
  handleClick: PropTypes.func,
  header: PropTypes.node,
};
