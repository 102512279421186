import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from 'react-bootstrap/Modal';
import { idType } from '@separate/types';
import styles from './index.module.scss';
import LoginFlow from './LoginFlow';
import { PremiumArrowLeft } from "@v4/src/icons";
import { LoginFlowProvider } from './LoginFlow/loginFlowContext';

export default function NewBrandingLoginModal({ show, onHide, providedPhoneNumber }) {
  return (
    <>
      <LoginFlowProvider providedPhoneNumber={providedPhoneNumber} exitLoginFlow={onHide} startLoginFlow={show} onSuccess={onHide}>
        <Modal
          show={show}
          fullscreen
          onHide={onHide}
          animation={false}
          className={styles.modal}
          backdropClassName={styles.modalBackdrop}
          keyboard={false}
          centered
        >
          <Modal.Header className={styles.modalHeader}>
            <Button onClick={() => onHide()} className={styles.backButton}><PremiumArrowLeft /></Button>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <LoginFlow />
          </Modal.Body>
        </Modal>
      </LoginFlowProvider>
    </>
  );
}

NewBrandingLoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func,
  onAbandon: PropTypes.func,
  providedPhoneNumber: idType,
};

NewBrandingLoginModal.defaultProps = {
  providedPhoneNumber: "",
};
