const common = {
  back: "Back",
  close: "Close",
  dismiss: "Dismiss",
  down: "Down",
  hello: "Hello",
  next: "Next",
  up: "Up",
};

export default common;
