import { zipcode } from '@separate/validations';

const OPTIONAL_ADDRESS_VALIDATIONS = {
  address_line_1: {
    translationKey: 'address.address_line_1',
    rules: [],
  },
  address_line_2: {
    translationKey: 'address.address_line_2',
  },
  city: {
    translationKey: 'address.city',
    rules: [],
  },
  state: {
    translationKey: 'address.state',
    rules: [],
  },
  zipcode: {
    translationKey: 'address.zipcode',
    rules: [zipcode],
  },
};

export default OPTIONAL_ADDRESS_VALIDATIONS;
