import baseUnauthAdminQuery from './baseUnauthAdminQuery';

export async function login(phoneNumber) {
  // TODO: Handle the case that the phone number is not found.  The server returns a 404.
  // As it is now it will just raise an exception and not get treated special.
  const response = await baseUnauthAdminQuery({
    path: 'login',
    method: 'post',
    data: { phone_number: phoneNumber },
  });

  return response.data;
}

export async function resendCode(phoneNumber) {
  const response = await baseUnauthAdminQuery({
    path: 'registration/resend_confirmation',
    method: 'post',
    data: { phone_number: phoneNumber },
  });

  return response.data;
}

export async function freePhone(phoneNumber) {
  const response = await baseUnauthAdminQuery({
    path: `registration/free_phone?phone_number=${phoneNumber}`,
    method: 'get',
  });

  return response.data;
}

export async function registration({
  phoneNumber,
  firstName,
  lastName,
  emailAddress,
  language,
  data,
  consulateId,
  birthday,
}) {
  const response = await baseUnauthAdminQuery({
    path: 'registration',
    method: 'post',
    data: {
      user: {
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        email: emailAddress,
        birthday: birthday,
        consulate_id: consulateId,
        language,
        data,
      },
    },
    locale: language,
  });
  return response.data;
}

export async function confirm({ phoneNumber, code }) {
  const response = await baseUnauthAdminQuery({
    path: 'registration/confirmation',
    method: 'post',
    data: {
      phone_number: phoneNumber,
      code,
    },
  });

  return response.data;
}

export async function singleSignOn(token) {
  const response = await baseUnauthAdminQuery({
    path: 'single_sign_on',
    method: 'post',
    data: {
      sso: { token },
    },
  });

  return response.data;
}
