import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/locales/en';
import es from '@/locales/es';

i18n
  .use(initReactI18next)
  .init({
    resources: { en, es },
    fallbackLng: 'es',
    debug: false,
    compatibilityJSON: 'v3',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span', 'sup'],
    },
  });

export default i18n;
