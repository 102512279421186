import React from 'react';
import { SvgComponent } from '@separate/components/SvgComponent';
import styles from './paymentMethodInfoCard.module.scss';

const defaultCardIcon = () => {
  return (
    <div className={styles.defaultIcon}>
      <SvgComponent name='PODERcardGradient' />
    </div>
  );
};

const brandMapping = {
  amex: {
    name: 'American Express',
    icon: <SvgComponent name='Amex' />,
  },
  amex_express_checkout: {
    name: 'Amex Express Checkout',
    icon: <SvgComponent name='Amex' />,
  },
  apple_pay: {
    name: 'Apple Pay',
    icon: <SvgComponent name='ApplePay' />,
  },
  diners: {
    name: 'Diners Club',
    icon: defaultCardIcon(),
  },
  discover: {
    name: 'Discover',
    icon: defaultCardIcon(),
  },
  google_pay: {
    name: 'Google Pay',
    icon: <SvgComponent name='GooglePay' />,
  },
  jcb: {
    name: 'JCB',
    icon: defaultCardIcon(),
  },
  mastercard: {
    name: 'Mastercard',
    icon: <SvgComponent name='Mastercard' />,
  },
  masterpass: {
    name: 'Masterpass',
    icon: <SvgComponent name='Mastercard' />,
  },
  samsung_pay: {
    name: 'Samsung Pay',
    icon: defaultCardIcon(),
  },
  unionpay: {
    name: 'UnionPay',
    icon: defaultCardIcon(),
  },
  visa: {
    name: 'Visa',
    icon: <SvgComponent name='Visa' />
    ,
  },
  visa_checkout: {
    name: 'Visa Checkout',
    icon: <SvgComponent name='Visa' />,
  },
  unknown: {
    name: 'Card',
    icon: defaultCardIcon(),
  },
};

export { brandMapping as default };
