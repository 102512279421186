import React from 'react';
import PropTypes from 'prop-types';
import InfoRow from './InfoRow';

export default function Address(props) {
  const { address } = props;

  return (
    <InfoRow
      label="user.address"
    >
      <>
        {address.address_line_1} {address.address_line_2}<br />
        {address.city}, {address.state} {address.zipcode}
      </>
    </InfoRow>
  );
}

Address.propTypes = {
  address: PropTypes.object,
};
