import React from 'react';
import PropTypes from 'prop-types';

const LoginFlowContext = React.createContext(null);

function LoginFlowProvider({ children, providedPhoneNumber, exitLoginFlow, startLoginFlow }) {
  const [location, setLocation] = React.useState('LoginStep');
  const [currentPhoneNumber, setCurrentPhoneNumber] = React.useState(providedPhoneNumber);
  const [entering, setEntering] = React.useState(false);

  const [forceLogin, setForceLogin] = React.useState(!!providedPhoneNumber);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  async function navigateTo(step) {
    setEntering(false);
    await delay(500);
    setLocation(step);
  }

  React.useEffect(() => {
    setCurrentPhoneNumber(providedPhoneNumber);
  }, [providedPhoneNumber]);

  React.useEffect(() => {
    setEntering(true);
  }, [location]);

  React.useEffect(() => {
    if(startLoginFlow) {
      setLocation("LoginStep");
    }
  }, [startLoginFlow]);

  return (
    <LoginFlowContext.Provider
      value={{
        location,
        navigateTo,
        currentPhoneNumber,
        setCurrentPhoneNumber,
        entering,
        setEntering,
        forceLogin,
        setForceLogin,
        startLoginFlow,
        exitLoginFlow
      }}
    >
      {children}
    </LoginFlowContext.Provider>
  );
}

LoginFlowProvider.propTypes = {
  children: PropTypes.node.isRequired,
  providedPhoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  exitLoginFlow: PropTypes.func
};

export { LoginFlowProvider };

export function useLoginFlow() {
  return React.useContext(LoginFlowContext);
}
