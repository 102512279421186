import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterLinks from './FooterLinks';

export default function Footer(props) {
  const { className, logo, footerContent, footerLinks, footerWatermark } = props;

  return (
    <div className={cx(styles.footer, 'py-4 d-flex align-items-center', className)}>
      <Container className={styles.container}>
        <Row>
          <Col xs={12} lg={6} className={cx(styles.contentColumn, 'me-auto')}>
            {footerContent}
          </Col>
          <Col md={12}>
            <div className={styles.line} />
          </Col>
        </Row>
        <Row>
          {footerLinks?.map((item, index) => (
            <Col xs={6} lg={2} key={index}>
              <FooterLinks title={item.title} links={item.links} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12} className={cx(styles.logoColumn, 'mb-4 text-center')}>
            <img src={logo} alt="logo" className={cx(styles.logo, 'd-block mx-auto')}/>
          </Col>
          <Col xs={12}>
            {footerWatermark}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  thumbnail: PropTypes.string,
  footerContent: PropTypes.node,
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      links: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
  footerWatermark: PropTypes.element,
};

