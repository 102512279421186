import { getUserAgent } from "./window";

export const deviceType = () => {
  const userAgent = getUserAgent();

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return "tablet";
  }
  if (/IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS/.test(userAgent)) {
    return "other_mobile_device";
  }
  if (/Android/.test(userAgent)) {
    return "mobile_android";
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "mobile_ios";
  }
  return "desktop";
};
