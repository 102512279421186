const premium = {
  main: {
    header: {
      health: "Salud",
      wellness: "y bienestar"
    },
    subheader: "Servicios médicos accesibles<sup>1</sup> y sin complicaciones, para usted y su familia inmediata.",
    cta: "Obtener mi membresía",
    span: "Solo <strong>$25.00</strong> <small>/ al mes</small>",
    silver_price: "Desde <strong>$17.00</strong> <small>/ mes</small>",
  },
  services: {
    header: "Beneficios a su medida",
    items: [
      {
        title: "Dentistas",
        content: "Ahorre fácilmente en sus consultas dentales. Reciba entre <strong>15% - 50%<sup>2</sup></strong> de descuento en más de 260,000 dentistas."
      },
      {
        title: "Consultas médicas",
        content: "Ahorre <strong>10% - 40%<sup>2</sup></strong> en consultas médicas presenciales, o acuda al médico 24/7 por teléfono o videollamada cuantes veces requiera."
      },
      {
        title: "Medicamentos descontados",
        content: "<strong>Ahorre hasta 80%<sup>2</sup></strong> en medicamentos recetados en 60,000 farmacias del país."
      },
      {
        title: "Servicios adicionales",
        content: "Mejor vista y diagnóstico. Ahorre de <strong>10% - 60%<sup>2</sup></strong> en el cuidado de la vista y <strong>hasta 75%<sup>2</sup></strong> en resonancias magnéticas."
      }
    ],
    cta: "Obtener mi membresía"
  },
  plans: {
    header: "Ejemplos de ahorros",
    subheader: "Descuentos médicos que funcionan. Obtenga verdaderos ahorros hoy mismo con PODERsalud.",
    items: [
      {
        banner: "$45.00 en ahorros",
        header: "Dentistas",
        subheader: "<strong>Costo promedio</strong> de limpieza dental para chicos y grandes",
        amount: {
          old: "$102.00",
          new: "$57"
        },
        quantity: " / por visita",
        member: "Con <strong>PODERsalud</strong>",
        cta: "Comenzar a ahorrar"
      },
      {
        banner: "$250.00 en ahorros",
        header: "Telemedicine",
        subheader: "<strong>Costo promedio</strong> de una cita médica en presencial",
        amount: {
          old: "$250.00",
          new: "$0"
        },
        quantity: " / por visita",
        member: "Con <strong>PODERsalud</strong>",
        cta: "Comenzar a ahorrar"
      },
      {
        banner: "$10.87 en ahorros",
        header: "Farmacia",
        subheader: "<strong>Costo promedio</strong> de Lisinopril (Presión Arterial)",
        amount: {
          old: "$12.00",
          new: "$1.13"
        },
        quantity: " / 20mg (30 tabletas)",
        member: "Con <strong>PODERsalud</strong>",
        cta: "Comenzar a ahorrar"
      },
      {
        banner: "$48.00 en ahorros",
        header: "Visión",
        subheader: "<strong>Costo promedio</strong> de un examen de anteojos",
        amount: {
          old: "$90.00",
          new: "$42"
        },
        quantity: " / por visita",
        member: "Con <strong>PODERsalud</strong>",
        cta: "Comenzar a ahorrar"
      }
    ],
    span: "Con PODERsalud puede ahorrar en promedio $1,050 al año<sup>3</sup>, y hasta $3,136 para una familia de 4 integrantes al año."
  },
  chat: {
    header: "¿Tiene alguna pregunta?",
    content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Iniciar conversación"
  },
  rewards: {
    header: "Su salud, su futuro",
    subheader: "Alcance su máximo potencial",
    items: [
      {
        title: "Valor sustancial y sencillez",
        content: "Consultas médicas presenciales, ahorros en servicios dentales, medicamentos descontados, consultas virtuales cuantas veces las requiera ¡y más! Para usted y su familia inmediata."
      },
      {
        title: "Ahorre $2,000+ al año<sup>2</sup>",
        content: "Los descuentos y beneficios de PODERsalud contribuyen a mantener la estabilidad financiera. Nuestros precios sencillos se reflejan en ahorros significativos año tras año."
      },
      {
        title: "Bienestar para todas las personas",
        content: "PODERsalud complementa la cobertura de un seguro médico o le ofrece apoyo para la salud si no cuenta con seguro. Las circunstancias no importan — los beneficios son para todas las personas."
      },
      {
        title: "Obtenga las respuestas que busca",
        content: "¡Cuente con nosotros! Su membresía le da acceso a nuestro Equipo de Atención al Cliente, que le ofrece soporte personalizado en el que puede confiar."
      }
    ]
  },
  testimonials: {
    header: "Voces de la comunidad",
    items: [
      {
        content: "“Les recomiendo PODERsalud a todas las personas que quiero. Con este programa, el cuidado de la salud es <strong>más barato.</strong>“",
        span: "- Jose. Miami, FL"
      },
      {
        content: "“Contacté al equipo de PODERsalud para solicitar una consulta médica por video y recibimos un diagnóstico de inmediato. <strong>Fue increíble.</strong>“",
        span: "- Angela. Chicago, IL"
      },
      {
        content: "“Me interesó porque los planes son a <strong>muy bajo costo</strong> y te incluye toda la familia, y te brinda servicios <strong>en todas las áreas</strong>. Para la salud de nosotros los hispanos es muy recomendable tener planes de salud como este.“",
        span: "- Lucia. Los Angeles, CA"
      }
    ]
  },
  articles: {
    header: "Tips para una vida saludable",
    cta: "Lea más"
  },
  faq: {
    header: "Preguntas frecuentes",
    items: [
      {
        title: "¿Para que me sirve la membresía de PODERsalud?",
        content: "<span>Esta suscripción le apoya de tres maneras importantes:</span> <ol><li>Le facilita el acceso a servicios médicos a un bajo costo.</li> <li>Le permite generar ingresos adicionales haciendo encuestas mientras descubre información valiosa sobre temas que le ayudarán a tomar decisiones informadas.</li> <li>Pone a su disposición al Equipo de Atención al Cliente de SABEResPODER para asistirle cuando lo requiera.</li></ol>",
      },
      {
        title: "¿Mi familia puede recibir los descuentos de PODERsalud?",
        content: "¡Sí! Los beneficios se extienden a usted, su pareja y dependientes menores de 26 años.",
      },
      {
        title: "¿Qué pasa si quiero cancelar la suscripción?",
        content: "Cancelar es fácil. Para nosotros es importante ofrecer servicios que sean fáciles de usar. Si necesita hacer algún cambio en su suscripción, lo puede hacer en cualquier momento.",
      }
    ],
    more: {
      title: "¿Tiene alguna pregunta?",
      content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(877) 210-2513'>(877) 210 2513</a>",
      cta: "Iniciar conversación"
    }
  },
  disclaimer: {
    title: "Divulgaciones",
    items: [
      "Inscripción para plan de descuento. Este programa NO ES una póliza de seguros, la intención NO ES reemplazar a su compañía de seguros, y tampoco cumple con los requisitos mínimos de cobertura que piden el Affordable Care Act o el M.G.L. c. 111M y 956 CRM 5.00 de Massachusetts. Su membresía es efectiva desde la recepción de los materiales de membresía. Este programa no está disponible para residentes de UT, VT o WA. Tras inscribirse, Tendrá 30 días para evaluar el servicio y si no queda satisfecho en ese período, puede cancelar el programa sin costo alguno y le devolveremos su dinero. Los descuentos los puede utilizar sólo en las oficinas de los proveedores de servicios médicos que forman parte de la red, y cada titular de la membresía tiene la obligación de pagar los servicios descontados en el momento en el que se reciben. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475. Formulario # HPSEP2",
      "Este es un ejemplo de los ahorros que se pueden generar y no representa una garantía de ahorros específicos en todos los casos."
    ]
  },
  enrollment: {
    popular: "Popular",
    year: 'año',
    platinum: "Platino",
    silver: "Plata",
    plans: {
      header: "Elija su plan",
      subheader: "Seleccione el plan adecuado para usted."
    },
    intervals: {
      month: {
        tab: "Mensual",
        price_per: "Mes",
        text: "Cobro mensual",
        badge: "Más popular",
      },
      semi: {
        tab: "Semi anual",
        price_per: "semi anual",
        text: "facturado cada 6 meses",
        badge: "Ahorros",
      }
    },
    benefits: {
      title: "Servicios",
      teladoc: "Telemedicina",
      inPerson: "Médicos",
      vision: "Visión",
      hearing: "Ayuda auditiva",
      mri: "Resonancias",
      travel: "Turismo descontado",
      lab: "Estudios clínicos",
      diabetic: "Diabetes",
      pharmacy: "Medicamentos",
      dental: "Dentistas",
      show: "Mostrar más",
      hide: "Ocultar",
    },
    perks: {
      title: "Beneficios",
      travel: "Turismo",
      rewards: "Recompensas",
      advisor: "Asesoría confiable",
    },
    discount: {
      tnc: {
        cta: "Términos y Condiciones de Descuentos Promocionales",
      }
    },
    step: "Paso",
    continue: "Siga",
    view_benefits: "Ver beneficios",
    phone_number_entry: {
      header: "¡Empecemos!",
      subheader: "Comience ahora: Ingrese su celular para disfrutar de beneficios a su medida.",
      mobile_number: "Celular",
    },
    personal_info: {
      header: "Su cuenta",
      subheader: "Por favor, ingrese su nombre.",
      more: "¡Cuéntenos más sobre usted!"
    },
    address: {
      header: "Domicilio",
      subheader: "Se usará esta dirección para enviarle su kit de bienvenida."
    },
    payment: {
      header: "Confirmar compra",
      subheader: "Confirme su plan y elija su forma de pago para completar su suscripción.",
      price: "Costo del plan",
      month: "mes",
      free_trial_days: "días de prueba gratuita",
      one_time_credit: "Crédito único",
      total: "Total",
      change_plan: "Cambio de plan",
      cta: "Confirmar compra",
      secure: {
        header: "Transacciones seguras, siempre.",
        subheader: "Su pago es procesado con los más altos estándares de seguridad."
      }
    },
    success: {
      welcome: "Le damos la bienvenida",
      header: {
        thanks: "¡Gracias por elegir el ",
        plan: "Plan {{plan}}!"
      },
      subheader: {
        registration: {
          final_step: "<strong>Paso final: Verifique su número celular para completar su registro.</strong>",
          verify: "Está a punto de terminar. Para obtener sus beneficios, confirme su número celular. Es el paso final para completar su suscripción.",
          cta: "Confirmar celular"
        },
        benefits: {
          get_started: "Haga clic en 'Comenzar' para explorar sus beneficios.",
          cta: "Comenzar"
        }
      }
    },
    confirmation: {
      cta: "Verificar código",
      new_user: {
        header: "Ingresar código",
        subheader: "Ingrese el código de 5 dígitos enviado a su celular con terminación {{ number }} para finalizar.",
      },
      existing_user: {
        header: "Ingresar código",
        subheader: "Ingrese el código de 5 dígitos enviado a su celular con terminación {{ number }} para finalizar.",
      },
      success: {
        header: "¡Éxito!",
        subheader: "¡Gracias por confirmar su celular! Haga clic en Siga para avanzar.",
        cta: "Siga"
      }
    }
  },
  free_trial: {
    banner: {
      header: 'Pruebe PODERsalud',
      subheader: 'días de prueba gratuita*',
    },
  },
  onboarding: {
    intro: {
      header: `Nuestro objetivo es brindarte el <span>cuidado</span> que necesitas cuando lo necesitas...`,
      subheader: "¡Solo necesitamos hacerte algunas preguntas más!",
      cta: "Comenzar",
    },
    benefit_interests: {
      header: `¿Qué es lo más <span>importante</span> para ti en este momento?`,
      subheader: 'Por favor, <strong>selecciona todas</strong> las necesidades de beneficio inmediato:',
      benefits:{
        vision: "Visión",
        dental: "Dentistas",
        in_person_healthcare: "Médicos",
        telemedicine: "Telemedicina",
        pharmacy: "Medicamentos",
      },
      cta: "Siga",
    },
    disclaimer: "Inscripción para plan de descuento. Este programa NO ES una póliza de seguros, la intención NO ES reemplazar a su compañía de seguros, y tampoco cumple con los requisitos mínimos de cobertura que piden el Affordable Care Act o el M.G.L. c. 111M y 956 CRM 5.00 de Massachusetts. Su membresía es efectiva desde la recepción de los materiales de membresía. Este programa no está disponible para residentes de UT, VT o WA. Tras inscribirse, Tendrá 30 días para evaluar el servicio y si no queda satisfecho en ese período, puede cancelar el programa sin costo alguno y le devolveremos su dinero. Los descuentos los puede utilizar sólo en las oficinas de los proveedores de servicios médicos que forman parte de la red, y cada titular de la membresía tiene la obligación de pagar los servicios descontados en el momento en el que se reciben. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475. Formulario # HPSEP2",
  },
};

export default premium;
