import axios from 'axios';
import { assumeRoleFor } from '@separate/api/sep/auth';
import * as env from '@separate/env';
import * as auth from "./auth";
const AwsV4Signer = env.client && require('aws4fetch').AwsV4Signer;

// This file was largely pilfered as-is from proton.
let timeOffset = null;
const requestSigner = {
  decodeToken() {
    if (!env.client) return null;

    let idToken = auth.getIdToken();
    if (idToken) {
      let header = idToken.split(".")[1];
      return JSON.parse(window.atob(header));
    } else {
      return null;
    }
  },

  exp() {
    return this.decodeToken() && this.decodeToken().exp;
  },

  iat() {
    return this.decodeToken() && this.decodeToken().iat;
  },

  sub() {
    return this.decodeToken() && this.decodeToken().sub;
  },

  isExpired() {
    if (Date.now() >= this.exp() * 1000) {
      return true;
    } else {
      return false;
    }
  },

  isIdle() {
    // TODO:  I'm not sure this ever worked.  The refresh iam token was firing always because
    // it was comparing seconds to millis.
    return false;
    if (
      auth.getIdToken() != null &&
      Date.now() >= (this.iat() + env.OPENID_IDLE_TIME) * 1000
    ) {
      return true;
    } else {
      return false;
    }
  },

  buildParamsGet(action) {
    const parsedURL = new URL(`${env.ADMIN_AUTH_ENDPOINT}${action}`);
    parsedURL.searchParams.append("access_token", auth.getAccessToken());
    return {
      method: "GET",
      url: parsedURL.toString(),
      path: `${parsedURL.pathname}${parsedURL.search}`,
    };
  },

  buildParamsPut(action, data) {
    data["access_token"] = auth.getAccessToken();
    return {
      method: "PUT",
      data: data, // axios
      body: JSON.stringify(data), // aws4
      url: `${env.ADMIN_AUTH_ENDPOINT}${action}`,
      path: `${env.AUTH_API_PATH}${action}`,
      headers: {
        "content-type": "application/json",
      },
    };
  },

  buildParamsPost(action, data) {
    data["access_token"] = auth.getAccessToken();
    return {
      method: "POST",
      data: data, // axios
      body: JSON.stringify(data), // aws4
      url: `${env.ADMIN_AUTH_ENDPOINT}${action}`,
      path: `${env.AUTH_API_PATH}${action}`,
      headers: {
        "content-type": "application/json",
      },
    };
  },

  buildParamsPatch(action, data) {
    data["access_token"] = auth.getAccessToken();
    return {
      method: "PATCH",
      data: data, // axios
      body: JSON.stringify(data), // aws4
      url: `${env.ADMIN_AUTH_ENDPOINT}${action}`,
      path: `${env.AUTH_API_PATH}${action}`,
      headers: {
        "content-type": "application/json",
      },
    };
  },

  buildParamsDelete(action, data) {
    data["access_token"] = auth.getAccessToken();
    return {
      method: "DELETE",
      data: data, // axios
      body: JSON.stringify(data), // aws4
      url: `${env.ADMIN_AUTH_ENDPOINT}${action}`,
      path: `${env.AUTH_API_PATH}${action}`,
      headers: {
        "content-type": "application/json",
      },
    };
  },

  buildParams(action, method, data) {
    let params = null;
    if (method === "PUT" || method === 'put') {
      params = this.buildParamsPut(action, data || {});
    } else if (method === "POST" || method === 'post') {
      params = this.buildParamsPost(action, data || {});
    } else if (method === "PATCH" || method === 'patch') {
      params = this.buildParamsPatch(action, data || {});
    } else if (method === "DELETE" || method === 'delete') {
      params = this.buildParamsDelete(action, data || {});
    } else {
      params = this.buildParamsGet(action);
    }
    return params;
  },

  buildRequest(params) {
    return Object.assign(
      {
        service: env.AWS_GATEWAY_SERVICE,
        region: env.AWS_GATEWAY_REGION,
        host: env.AWS_GATEWAY_CUSTOM_DOMAIN,
      },
      params
    );
  },

  buildCredentials() {
    let temporaryCredentials = auth.getTemporaryCredentials();
    let credentials = {
      secretAccessKey: temporaryCredentials.secretKey,
      accessKeyId: temporaryCredentials.accessKey,
      sessionToken: temporaryCredentials.sessionToken,
    };
    return credentials;
  },

  buildHeaders(signedRequest) {
    delete signedRequest.headers["Host"];
    delete signedRequest.headers["Content-Length"];
    return signedRequest;
  },

  async buildSignedRequest(request, credentials, locale) {
    let signedRequest = await this.signRequest(request, credentials, locale);
    signedRequest = this.buildHeaders(signedRequest);
    return signedRequest;
  },

  async signRequest(request, credentials, locale) {
    if (!env.client) throw new Error("Prerendering should never reach here");
    if (timeOffset === null) await this.syncTime();

    // Required to set the current DateTime manually for siging with API Gateway
    request.headers = request.headers || {};
    request.headers["X-Api-Locale"] = locale;
    const datetime = new Date(Date.now() + timeOffset)
      .toISOString()
      .replace(/[:-]|\.\d{3}/g, "");
    request.headers["X-Amz-Date"] = datetime;

    const signer = new AwsV4Signer({
      ...request,
      ...credentials,
      datetime,
    });
    const { headers } = await signer.sign();
    request.headers = {};
    headers.forEach((value, name) => {
      request.headers[name] = value;
    });

    return request;
  },

  async syncTime() {
    if (process.env.NODE_ENV === "development") {
      timeOffset = 0;
      return;
    }
    const clientTimeBefore = Date.now();
    const serverTime = (await axios.head("/?nc=" + Date.now())).headers.date;
    const clientTimeAfter = Date.now();
    timeOffset =
      new Date(serverTime).getTime() -
      clientTimeBefore +
      (clientTimeAfter - clientTimeBefore) / 2;
  },

  async refreshToken() {
    let response;
    let data;
    try {
      response = await axios.post(`${env.ADMIN_UNAUTH_ENDPOINT}/refresh`, {
        refresh_token: auth.getRefreshToken(),
      });
      data = await assumeRoleFor(response.data.id_token);
      auth.setAccessToken(response.data.access_token);
      auth.setIdToken(response.data.id_token);
      auth.setRefreshToken(response.data.refresh_token);
      auth.setTemporaryCredentials(data);
    } catch (error) {
      this.errorMessage = error.message;
      auth.reset();
      throw error;
    }
  },

  buildToken() {
    if (this.isExpired()) {
      this.refreshToken();
    }
  },

  async sign(action, method = "GET", data, locale = 'es') {
    if (!action.startsWith(`/`)) {
      action = `/${action}`;
    }

    if (this.isIdle()) {
      return null;
    } else {
      this.buildToken();
      let params = this.buildParams(action, method, data);
      let request = this.buildRequest(params);
      let credentials = this.buildCredentials();
      let signedRequest = await this.buildSignedRequest(request, credentials, locale);
      if (method === "POST" || method === "PUT") {
        delete signedRequest.params;
      }
      return signedRequest;
    }
  },
};

export default requestSigner;
