import React from 'react';
import styles from './index.module.scss';
import { useV4Translation } from '@v4/src/hooks/translation';

/* eslint react/prop-types: 0 */
export default function Content() {
  const { t } = useV4Translation();
  return (
    <>
      <h3 className={styles.title}>{t("v4.footer.header")}</h3>
    </>
  );
}
