const menu = {
  home: 'Explorar',
  earn: 'Ganancias',
  chat: 'Atención al cliente',
  articles: 'Centro de conocimiento',
  premium: 'Salud',
  farmaSEP: "farmaSEP",
  digital_banking: 'Banca',
  jobs_network: 'Red de empleos',
  profile: 'Su cuenta',
  about: 'Acerca de'
};

export default menu;
