import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useLogout } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import { logoutAnalyticsUser } from '@separate/utilities/analytics';
import { isFeatureEnabled } from '@separate/utilities/features';
import { isPremiumLegacy } from '@separate/utilities/premium';
import BasicInfo from './BasicInfo';
import Dependents from './Dependents';
import PlanInfo from './PlanInfo';
import styles from './steps.module.scss';

export default function Main(props) {
  const { onEdit, onDismiss, onPayPastDue, onEditPaymentMethod, onEditSMSPreferences, onEditDependents } = props;
  const { isLoading, logout } = useLogout({ onSuccess: hideOnLogout });
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const allowAddDependents = isFeatureEnabled('allow_dependents');

  function hideOnLogout() {
    logoutAnalyticsUser();
    onDismiss();
  }

  return (
    <div className={styles.container}>
      <div>
        <BasicInfo onEdit={onEdit} />
        <PlanInfo
          onPayPastDue={onPayPastDue}
          onEditPaymentMethod={onEditPaymentMethod}
          isPremiumLegacy={isPremiumLegacy(user)}
        />
        {(user?.is_activated_podersalud && allowAddDependents) && (
          <Dependents onClick={onEditDependents} />
        )}
        <div className='d-flex my-3 w-100 justify-content-between'>
          <h4 className={styles.ctaTitle}>{t("user.profile.sms_preferences.title")}</h4>
          <Button
            onClick={onEditSMSPreferences}
            className={styles.editCta}
          >
            {t("user.profile.edit_cta")}
          </Button>
        </div>
      </div>

      <div className={styles.modalCTAs}>
        <div>
          <Button className={styles.logoutButton} onClick={logout} loading={isLoading}>
            {t('user.profile.logout_cta')}
          </Button>
        </div>

        <div>
          <Link href="/legal/terms-and-conditions">
            <a target="_blank" rel="noopener noreferrer">
              {t('user.profile.terms_and_condition')}
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

Main.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onPayPastDue: PropTypes.func.isRequired,
  onEditPaymentMethod: PropTypes.func.isRequired,
  onEditSMSPreferences: PropTypes.func.isRequired,
  onEditDependents: PropTypes.func.isRequired,
};
