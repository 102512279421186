import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@separate/hooks/translation';
import { ChevronRight, PODERcardGradient } from '@separate/icons';
import styles from './addNewCard.module.scss';

export default function AddNewCard({ onClick }) {
  const { t } = useTranslation();

  function handleClick() {
    onClick?.();
  }

  return (
    <div className={styles.newCard} onClick={handleClick}>
      <PODERcardGradient className={styles.cardIcon} />
      {t('user.profile.add_new_card')}
      <ChevronRight className={styles.chevron} />
    </div>
  );
}

AddNewCard.propTypes = {
  onClick: PropTypes.func,
};
