import { presence } from '@separate/utilities/string';

export default function nameValidator(value) {
  if (!presence(value)) return null;

  value = value.trim(' ');

  if (value.length < 2) {
    return 'at_least_2_characters';
  }

  if (value.length > 35) {
    return 'at_most_35_characters';
  }

  if (!value.match(/^[ ',.0-9a-zÀÁÃÇÈÉÍÎÑÓÚÜàáãçèéíîñóúü-]+$/i)) {
    return 'invalid_characters';
  }

  return null;
}
