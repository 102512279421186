import React from 'react';
import cx from 'classnames';
import styles from './index.module.scss';
import { useLoginFlow } from './loginFlowContext';
import * as steps from './steps';
import { scrollToView } from '@separate/utilities/window';

export default function LoginFlow() {
  const { location, entering } = useLoginFlow();
  const Step = steps[location];
  const top = React.useRef(null);

  React.useEffect(() => {
    if(entering) {
      scrollToView(top);
    }
  }, [entering, top]);

  return (
    <>
      <div ref={top} className={styles.top} />
      <div className={cx(styles.contentContainer, { [styles.entering]: entering })}>
        <Step />
      </div>
    </>
  );
}
