const user = {
  first_name: "First name",
  last_name: "Last name",
  name: "Name",
  email: "Email address",
  email_optional : "Email address (optional)",
  gender: "Gender",
  address: "Address",
  phone: "Phone number",
  phone_number: "Mobile number",
  birthday_with_format: "Date of Birth mm/dd/yyyy",
  birthday: "Date of birth",
  gender_options: {
    male: "Male",
    female: "Female",
    unspecified: "Unspecified",
  },
  culture: {
    mexico: "Mexico",
    el_salvador: "El Salvador",
    guatemala: "Guatemala",
    united_states: "United States",
    colombia: "Colombia",
    puerto_rico: "Puerto Rico",
    costa_rica: "Costa Rica",
    cuba: "Cuba",
    nicaragua: "Nicaragua",
    ecuador: "Ecuador",
    unknown: "Unknown",
    honduras: "Honduras",
    argentina: "Argentina",
    bolivia: "Bolivia",
    chile: "Chile",
    dominican_republic: "Dominican Republic",
    panama: "Panama",
    paraguay: "Paraguay",
    peru: "Peru",
    spain: "Spain",
    uruguay: "Uruguay",
    venezuela: "Venezuela",
    other: "Not listed here"
  },
  culture_id: "Select your family’s country of origin",
  other_culture_name: "Please specify your family’s country of origin",
  mexico_state_id: "Which Mexico state does your family come from?",
  interests: "Interests",
  language: "Language",
  language_options: {
    spanish: "Spanish",
    english: "English",
  },
  profile: {
    header_title: "My Account",
    logout_cta: "Logout",
    account_cancellation: {
      title: 'SEP Account Cancellation',
      text_one: 'To cancel your SEP account and/or delete your personal information, please send an email to ',
      text_two: ' with your request. Make sure to include the following information in your email:',
      details: [
        {
          text: 'Full Legal Name',
        },
        {
          text: 'Address',
        },
        {
          text: 'Mobile phone number',
        },
        {
          text: 'Email',
        },
        {
          text: 'Confirmation that you\'d like to cancel and/or delete your SEP account',
        },
      ],
      note: {
        text_one: 'Note: ',
        text_two: 'The option to request deletion of your personal information is only available to California residents.',
      },
      go_back: 'Go back',
    },
    edit_cta_title: "My Information",
    edit_cta: "Edit",
    cancel_edit_cta: "Cancel",
    submit_cta: "Submit Changes",
    next_payment: "Next payment date",
    payment_cta_title: "Payment Info",
    plan_name: "Plan name",
    plan_information: "Plan information",
    plan_type: "Plan type",
    payPastDue_cta: "Pay Now",
    member_since: 'Member since',
    basic_member: {
      upgrade_premium_message: "Upgrade to premium to unlock all benifits for",
      unlock_benifit_cta: "Unlock benifits now!",
      duration: "/ month"
    },
    podercard_alert: {
      title: "Oops!",
      subtitle:
        "Since you’ve enrolled in PODERcard these edits can only be made there",
      open: "Open PODERcard App",
      cancel: "Cancel",
    },
    sms_preferences: {
      account_cancellation: {
        text_one: 'Click ',
        link: 'here ',
        text_two: 'to delete my account and personal information',
      },
      title: 'SMS Preferences',
      text: 'You can adjust your SMS notification settings here. Leave boxes checked for each SMS channel you would like to remain subscribed to. Uncheck boxes to unsubscribe at any given time.',
      sep_rewards: {
        title: 'SEP Rewards ',
        code: '(55280)',
      },
      sep_info_and_chat: {
        title: 'SEP Info + Chat ',
        code: '(77237)',
      },
      sep_marketing: {
        title: 'SEP Marketing and Offers ',
        code: '(52749)',
      },
      submit_cta: 'Submit',
    },
    terms_and_condition: "Terms and Conditions and Privacy Policy",
  },
  update_location: {
    text: 'Update location',
    location: 'City, state, or zip code',
    save: 'Save',
    close: 'Close',
    enter_zip_code: 'Enter zip code',
    zip_code_validation_error: 'Please enter a 5-digit ZIP code',
    drug_validation_error: 'Please select a drug first',
  },
};

export default user;
