import { required, name, email } from '@/validations';
import OPTIONAL_ADDRESS_VALIDATIONS from '@separate/components/address/optionalValidations';
import useInputFormState from '@separate/hooks/useInputFormState';

const VALIDATIONS = {
  first_name: {
    translationKey: 'user.first_name',
    rules: [required, name],
  },
  last_name: {
    translationKey: 'user.last_name',
    rules: [required, name],
  },
  gender: {
    translationKey: 'user.gender',
    rules: [],
  },
  email: {
    translationKey: 'user.email',
    rules: [email],
  },
};

export default function useEditBasicInfoInputFormState() {
  let validations = {};

  function add(field) {
    validations[field] = VALIDATIONS[field];
  }

  add('first_name');
  add('last_name');
  add('gender');
  add('email');

  validations = { ...validations, ...OPTIONAL_ADDRESS_VALIDATIONS };

  return useInputFormState(validations);
}
