import { presence } from '@separate/utilities/string';

export default function phoneValidator(value) {
  if (!presence(value)) return null;

  value = value.trim(' ');

  if (value.length < 10) {
    return 'invalid_phone';
  }

  if (value.length > 10) {
    return 'invalid_phone';
  }

  return null;
}

export const formatPhoneNumber = (num) => {
  return `(${num.slice(0,3)}) ${num.slice(4)}`;
};
export const formatNumberToOutBoundLink = (num) => {
  return `<a href="tel:${num}">${formatPhoneNumber(num)}</a>`;
};
