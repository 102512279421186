import { HOME_PAGE, PREMIUM_PAGE } from "./pageRoutes";

export const LOGIN_MODAL_MAPPING = {
  [HOME_PAGE]: 'default',
  [PREMIUM_PAGE]: 'premium',
};

export const loginModalTextMapping = (currentPage) => {
  return LOGIN_MODAL_MAPPING[currentPage] || 'default';
};
