import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from '@separate/components/Button';
import ProcessingModal from '@separate/components/ProcessingModal';
import SuccessModal from '@separate/components/SuccessModal';
import { SetShowMissedPaymentModalContext } from '@separate/context/SetShowMissedPaymentModalContext';
import { useConfirmCardPayment } from '@separate/hooks/payment';
import {
  useListPaymentMethods,
  useResetListPaymentMethods,
  useResetSubscriptionQuery,
} from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { CardFailed, CardSuccess } from '@separate/icons';
import { logEvent, PAYMENT_FAILED } from '@separate/utilities/analytics';
import PaymentMethodInfoCard from '../PaymentMethodInfoCard';
import styles from './payWithNewlyAddedCardOffcanvas.module.scss';

export default function PayWithNewlyAddedCardOffcanvas({
  addedAsDefault,
  container,
  onDismiss,
  onFailure,
  onHide,
  onSuccess,
  primaryPaymentMethodId,
  show,
  subscription,
  pid,
}) {
  const { t } = useTranslation();
  const { isLoading: paymentsLoading, data } = useListPaymentMethods(pid);

  const resetListPaymentMethods = useResetListPaymentMethods();
  const resetSubscriptionQuery = useResetSubscriptionQuery();

  const paymentMethods = data?.payment_methods;
  const latestPaymentMethod = paymentMethods?.[0];

  const isPrimary = addedAsDefault || (latestPaymentMethod?.id === primaryPaymentMethodId);

  const [showFailed, setShowFailed] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const setShowMissedPaymentModal = React.useContext(SetShowMissedPaymentModalContext);

  function handleHide() {
    onHide?.();
  }

  // Confirm payment
  function onPaymentSuccess() {
    setShowSuccess(true);
  }

  function onPaymentError() {
    logEvent(PAYMENT_FAILED);
    setShowFailed(true);
  }

  const {
    isLoading,
    confirmCardPayment,
  } = useConfirmCardPayment({ onSuccess: onPaymentSuccess, onError: onPaymentError });

  function handlePay() {
    handleHide();
    confirmCardPayment({
      clientSecret: subscription.client_secret,
      data: { payment_method: latestPaymentMethod.id },
    });
  }

  function handleChangePayment() {
    resetSubscriptionQuery(pid);
    resetListPaymentMethods(pid);
    handleHide();
  }

  function handleFailedModalOnHide() {
    setShowFailed(false);
    onFailure?.();
    onDismiss?.();
    setShowMissedPaymentModal(true);
  }

  function handleSuccessModalOnHide() {
    setShowSuccess(false);
    resetSubscriptionQuery(pid);
    resetListPaymentMethods(pid);
    onSuccess?.();
  }

  return (
    <>
      <Offcanvas
        backdropClassName={styles.backdrop}
        className={styles.container}
        container={container}
        onHide={handleHide}
        placement="bottom"
        show={show}
      >
        <Offcanvas.Header className={styles.header}>
          <Offcanvas.Title className={styles.title}>
            {t('premium.payment.added')}
            <div className={styles.subtitle}>
              {t('premium.payment.added_subtitle')}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={cx(styles.body, { [styles.padded]: !isPrimary })}>
          <Col className="d-flex flex-column align-items-center justify-content-center">
            {!paymentsLoading && latestPaymentMethod && (
              <PaymentMethodInfoCard
                className={cx({ [styles.padded]: !isPrimary })}
                method={latestPaymentMethod}
                primary={isPrimary}
              />
            )}
            <Button className={styles.payNowButton} disabled={!subscription} onClick={handlePay}>
              {t('premium.payment.pay_now')}
            </Button>
            <Button className={styles.changeButton} disabled={!subscription} onClick={handleChangePayment}>
              {t('premium.upgrade_subscription_plan.change_payment_method')}
            </Button>
            <div className={styles.informational}>
              {t('premium.payment.will_be_default')}
            </div>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
      <ProcessingModal
        container={container}
        show={isLoading}
        title={t('premium.payment.processing')}
      />
      <SuccessModal
        container={container}
        show={showSuccess}
        icon={<CardSuccess />}
        title={t('premium.payment.confirmed')}
        description={t('premium.payment.received')}
        buttonText={t('close')}
        onHide={handleSuccessModalOnHide}
      />
      <SuccessModal
        container={container}
        show={showFailed}
        icon={<CardFailed />}
        title={t('premium.payment.failed')}
        description={t('premium.payment.ensure_funds_or_new_card')}
        buttonText={t('close')}
        onHide={handleFailedModalOnHide}
      />
    </>
  );
}

PayWithNewlyAddedCardOffcanvas.propTypes = {
  addedAsDefault: PropTypes.bool,
  container: PropTypes.any,
  onDismiss: PropTypes.func,
  onFailure: PropTypes.func,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  show: PropTypes.bool,
  subscription: PropTypes.object,
  pid: PropTypes.string, // for zendesk-sep-admin
};
