import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import TextInput from '@separate/components/TextInput';
import { inputFormStateType } from '@separate/types';
import styles from '../steps.module.scss';

export default function InputForm({ inputFormState, useSepStyling }) {
  return (
    <div className={cx(styles.inputContainer, 'mt-4 mb-1')}>
      <TextInput
        type="tel"
        mask="(999) 999-9999"
        placeholder='(000) 000-0000'
        unmasked
        name="phone_number"
        inputFormState={inputFormState}
        useSepStyling={useSepStyling}
        className={styles.placeholder}
      />
    </div>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  useSepStyling: PropTypes.bool,
};

InputForm.defaultProps = {
  inputFormState: {},
};
