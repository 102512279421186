import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  PREMIUM_ANNUAL_MEMBERSHIP_PRICE,
} from '@separate/constants/premium';
import { useTranslation } from '@separate/hooks/translation';
import { asCurrency } from '@separate/utilities/string';
import styles from './paymentPricing.module.scss';

export default function PaymentPricing({ subscriptionPrice, className, isAnnual, subscriptionInterval, subscriptionIntervalCount }) {
  const { t } = useTranslation();

  const displayPrice = asCurrency(subscriptionPrice / 100);
  const isCustomInterval = subscriptionIntervalCount && subscriptionIntervalCount !== 1;

  const perText = () => {
    if(isCustomInterval) {
      return t('premium.annual.per_custom_interval', { intervalCount: subscriptionIntervalCount });
    }
    else if(isAnnual) {
      return t('premium.annual.per_year');
    }
    else {
      return t('premium.annual.per_month');
    }
  };

  return (
    <div className={cx('paymentPricing', styles.paymentPricing, className)}>
      <span className={cx('price', styles.price)}>{displayPrice}</span>
      <span className={cx('perMonthOrYear', styles.perMonthOrYear)}>
        {perText()}
      </span>
    </div>
  );
}

PaymentPricing.propTypes = {
  annualPrice: PropTypes.number,
  className: PropTypes.string,
  isAnnual: PropTypes.bool,
  hideFullAnnualPrice: PropTypes.bool,
  monthlyPrice: PropTypes.number,
  subscriptionPrice: PropTypes.number,
  subscriptionInterval: PropTypes.string,
  subscriptionIntervalCount: PropTypes.number,
};

PaymentPricing.defaultProps = {
  annualPrice: PREMIUM_ANNUAL_MEMBERSHIP_PRICE,
  isAnnual: false,
  hideFullAnnualPrice: false,
};
