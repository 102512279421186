import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import * as env from '@/env';
import {
  MenuArticles,
  MenuChat,
  MenuEarn,
  MenuHome,
  MenuPodercard,
  MenuPremium,
  MenuFarmaSep,
  MenuJobsNetwork,
  MenuAbout,
} from '@v4/src/icons';
import { HAMBURGER_MENU_CLICKED } from '@separate/constants/analytics';
import { useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';
import { logEvent } from "@separate/utilities/analytics";
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './index.module.scss';
import cx from 'classnames';

export default function NavItems(props) {
  const { show } = props;
  const { user, isLoggedIn } = useCurrentUser();
  const  { pathname } = useRouter();
  const { t } = useTranslation();
  const isPremiumMember = user?.is_premium_member;
  const hidePodercard = isFeatureEnabled('hide_podercard_references');
  const [currentItems, setCurrentItems] = React.useState([]);

  const menuEventProperties = {
    "menu.earn": "rewards",
    "menu.chat": "chat",
    "menu.articles": "articles",
    "menu.premium": "podersalud",
    "menu.digital_banking": "digital_banking",
    "menu.farmaSEP": "farmasep",
    "menu.jobs_network": "jobs_network",
    "menu.profile": "your_account",
  };

  const items = [
    {
      text: "v4.menu.home",
      href: "/",
      component: MenuHome,
    },
    {
      text: "v4.menu.premium",
      href: isPremiumMember ? "/benefits" : "/premium",
      component: MenuPremium,
    },
    {
      text: "v4.menu.earn",
      href: "/earn",
      component: MenuEarn,
      style: { paddingLeft: 2 },
    },
    {
      text: "v4.menu.digital_banking",
      href: "/podercard",
      component: MenuPodercard,
    },
    {
      text: "v4.menu.articles",
      href: "/articles",
      component: MenuArticles,
    },
    {
      text: "v4.menu.chat",
      href: "/qa",
      component: MenuChat,
    },
    {
      text: "v4.menu.about",
      href: "/about",
      component: MenuAbout,
    }
  ];

  function handleRouting(menuOnClick, text) {
    logHamburgerMenuClicked(text);
    if (menuOnClick) {
      menuOnClick();
    }
  }

  const logHamburgerMenuClicked = (name) => {
    if(!menuEventProperties[name]) return;

    logEvent(HAMBURGER_MENU_CLICKED, { destination: menuEventProperties[name] });
  };

  React.useEffect(() => {
    if(hidePodercard) {
      setCurrentItems(items.filter(item => item.href !== "/podercard"));
    } else {
      setCurrentItems(items);
    }
  }, [hidePodercard]);

  return (
    <>
      <Nav className={styles.menuLinks}>
        {currentItems.map(item => (
          <Link key={item.text} href={item.href}>
            <a onClick={() => handleRouting(item.onClick, item.text)} className={cx({ [styles.active]: pathname === item.href })}>
              <div className={styles.menuLinksIcon} >
                <item.component />
              </div>
              {t(item.text)}
            </a>
          </Link>
        ))}
      </Nav>
    </>
  );
}

NavItems.propTypes = {
  show: PropTypes.bool,
};
