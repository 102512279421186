import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

export default function Checkbox(props) {
  const { label, onChange, checked, disabled, className, ...extraProps } = props;

  function handleChange({ target }) {
    const value = target.checked;
    onChange(value);
  }

  return (
    <Form.Check
      type="checkbox"
      className={className}
      label={label}
      disabled={disabled}
      checked={checked}
      onChange={handleChange}
      {...extraProps}
    />
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
