import React from 'react';
import PropTypes from 'prop-types';
import AddNewCard from '@separate/components/PaymentMethods/AddNewCard';
import PaymentMethodInfoCard from '@separate/components/PaymentMethods/PaymentMethodInfoCard';
import Spinner from '@separate/components/Spinner';
import { useListPaymentMethods } from '@separate/hooks/premium';
import styles from './paymentMethodsList.module.scss';

export default function PaymentMethodsList({
  hideAddNewCard,
  onClickAddCard,
  onPaymentMethodClick,
  primaryPaymentMethodId,
  selectedPaymentMethodId,
  title,
  pid,
}) {
  const { isLoading, data } = useListPaymentMethods(pid);

  const paymentMethods = data?.payment_methods;

  const defaultPaymentMethod = paymentMethods?.find(method => method.id === primaryPaymentMethodId);

  function handleClickAddCard() {
    onClickAddCard?.();
  }

  function handlePaymentMethodClick(id) {
    const paymentMethod = paymentMethods.find(method => method.id === id);
    onPaymentMethodClick?.(paymentMethod);
  }

  return (
    <>
      {title && <h4 className={styles.ctaTitle}>{title}</h4>}
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div className={styles.paymentMethods}>
          {defaultPaymentMethod && (
            <PaymentMethodInfoCard
              method={defaultPaymentMethod}
              onClick={() => { return; }}
              primary={true}
            />
          )}
          {paymentMethods && paymentMethods.map((method) => {
            if (method.id === primaryPaymentMethodId) {
              return null;
            }

            return (
              <PaymentMethodInfoCard
                key={method.id}
                method={method}
                onClick={handlePaymentMethodClick}
                primary={method.id === primaryPaymentMethodId}
                selected={method.id === selectedPaymentMethodId}
              />
            );
          })}
          {!hideAddNewCard && <AddNewCard onClick={handleClickAddCard} />}
        </div>
      )}
    </>
  );
}

PaymentMethodsList.propTypes = {
  hideAddNewCard: PropTypes.bool,
  onClickAddCard: PropTypes.func,
  onPaymentMethodClick: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  selectedPaymentMethodId: PropTypes.string,
  title: PropTypes.string,
  pid: PropTypes.string, // for zendesk-sep-admin
};
