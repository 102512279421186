const studies = {
  max_out_of_quota_limit_reached: {
    header: 'This survey has reached the limit and was closed',
    text: 'Some surveys close when they reach their number of responses needed.<br><br>Try again with a different survey! Visit the Rewards Center to find out early about new earning opportunities.'
  },
  completion_header: {
    default: "Something went wrong",
    qualified:
      "Congratulations, ",
    added_to_account: "added to your account",
    added_to_podercard: "added to your PODERcard",
    non_qualified: "You did not qualify for this survey",
    out_of_quota:
      "This survey has reached the limit and was closed",
  },
};

export default studies;
