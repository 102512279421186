const consulate = {
  enrollment: {
    first_name: "First name",
    last_name: "Last name",
    phone_number: "Phone number",
    birthday: "Birthday",
    birth_month: "Birthday month",
    birth_year: "Birthday year",
    cta: "Continue",
    clear: "Clear all fields",
    main: {
      header: {
        welcome: "Welcome to",
        opportunity: "Opportunity",
      },
      subheader: "Best in class services built for you and your family."
    },
    account: {
      header: "Join our community",
      subheader: "Get access to benefits and services today."
    },
    success: {
      header: "Thank you for joining our community!",
      text: "Soon you will receive a text message with more information on how to access different benefits and services.<br/><br/>Our empowerment officers can help answer any of your questions!",
      cta: "Done"
    },
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
  }
};

export default consulate;
