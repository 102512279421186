import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@/components/Spinner';
import { useSubscriptionQuery } from '@separate/hooks/premium';
import { isSubscriptionActive, isSubscriptionPastDue } from '@separate/utilities/premium';

export default function PaymentStatusChecker({ onPayPastDue, onNoPaymentInfo, onEditPaymentMethod }) {
  const { isLoading, subscription } = useSubscriptionQuery({ options: { fastRefresh: true } });

  React.useEffect(() => {
    if (isLoading) return;

    const active = isSubscriptionActive(subscription);
    const pastDue = isSubscriptionPastDue(subscription);

    if (pastDue) {
      onPayPastDue();
    } else if (active) {
      onEditPaymentMethod();
    } else {
      onNoPaymentInfo();
    }

  }, [isLoading, subscription, onPayPastDue, onEditPaymentMethod, onNoPaymentInfo]);


  return (
    <div>
      {isLoading && <Spinner />}
    </div>
  );
}

PaymentStatusChecker.propTypes = {
  onPayPastDue: PropTypes.func,
  onNoPaymentInfo: PropTypes.func,
  onEditPaymentMethod: PropTypes.func,
};
