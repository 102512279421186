import React from 'react';
import PropTypes from 'prop-types';
import Button from '@v4/src/components/Button';
import { inputFormStateType } from '@separate/types';
import styles from './index.module.scss';
import TextInput from '@separate/v4/components/TextInput';
import FieldErrorsList from '@separate/v4/components/FieldErrorsList';
import { useTranslation } from '@separate/hooks/translation';

export default function InputForm({ inputFormState, onSubmit, loading }) {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        type="tel"
        mask="(999) 999-9999"
        unmasked
        inputId="phone_number"
        inputFormState={inputFormState}
        className={styles.input}
      />

      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />

      <div className="my-2 text-center">
        <Button
          loading={loading}
          onClick={onSubmit}
          disabled={!inputFormState.isValid}
          className={styles.button}
        >
          {t('login.login')}
        </Button>
      </div>
    </>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  useSepStyling: PropTypes.bool,
};

InputForm.defaultProps = {
  inputFormState: {},
};
