import React from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import posthog from 'posthog-js';
import PropTypes from 'prop-types';
import PageLoader from '@separate/components/PageLoader';

const PostHogContext = React.createContext(null);
export function PostHogProvider({ children, posthogHost, posthogKey, segmentHost, segmentKey }) {
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false); // this should be safe, as React should not trigger a re-render if it's already false
    }, 2500);
  });

  React.useEffect(() => {
    if (!posthogKey || !segmentKey) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    // Segment
    const analytics = AnalyticsBrowser.load({ cdnURL: segmentHost, writeKey: segmentKey });

    // Init PostHog with Segment info when Segment is ready
    analytics.ready(() => {
      posthog.init(posthogKey, {
        api_host: posthogHost,
        disable_session_recording: true,  // We explicitly enable it where we want recording
        segment: analytics.instance,
        capture_pageview: false,
        loaded: () => window.analytics.page(),
      });
      setTimeout(() => {
        setLoading(false);
      }, 1500);
      console.log('Initialized PostHog');
    });

    // Globalify
    window.analytics = analytics;
  });

  return (
    <PostHogContext.Provider
      value={posthogKey && segmentKey ? posthog : undefined}
    >
      {isLoading ? (
        <PageLoader />
      ) : (
        <>{children}</>
      )}
    </PostHogContext.Provider>
  );
}

PostHogProvider.propTypes = {
  children: PropTypes.node.isRequired,
  posthogHost: PropTypes.string,
  posthogKey: PropTypes.string,
  segmentHost: PropTypes.string,
  segmentKey: PropTypes.string,
};

export function usePostHogProvider() {
  return React.useContext(PostHogContext);
}
