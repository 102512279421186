const about = {
  main: {
    header: "Acerca de",
    subheader: "Servicios a la medida para usted y su familia",
    cta: "Conozca más",
  },
  about: {
    header: "Acerca",
    items: [
      "Cuando se trata de mudarse a EE. UU., muchas personas que migran solas o junto con sus familiares se desplazan sin los conocimientos, las herramientas y los recursos necesarios para adaptarse fácilmente a su nuevo país. SABEResPODER fue fundada para ayudar a resolver las necesidades cotidianas de las personas migrantes, con servicios educativos y productos prácticos hechos para todas las generaciones. Ofrecemos soluciones en el idioma y cultura de nuestra comunidad, disponibles a través del celular y las apps.",
      "Habiendo crecido en hogares de inmigrantes de primera generación, y habiendo aprendido de la experiencia de sus familiares, nuestros fundadores Raul Lomelí y Amir Hemmat vieron la oportunidad y reconocieron la obligación de ayudar a la comunidad migrante. Es por eso que crearon SABEResPODER, organización con la misión de satisfacer las necesidades de los inmigrantes en los EE. UU., quienes enfrentan barreras lingüísticas y culturales mientras intentan tomar decisiones informadas.",
      "Hoy, casi 4 millones de personas latinas confían en nuestra plataforma como una fuente confiable de información, educación y servicios de clase mundial."
    ]
  },
  mission: {
    quote: "Nuestra misión es crear un mundo en el que cualquier persona pueda alcanzar el éxito en dondequiera que esté",
    span: "- Amir Hemmat, CEO"
  },
  help: {
    header: "¿Qué apoyo le brinda SABEResPODER?",
    content: "Migrar a un nuevo país requiere de mucha fortaleza y valor. Una vez que llega, adaptarse a un nuevo idioma y cultura puede representar un gran reto, y despertar muchas preguntas e incertidumbre. Nuestro objetivo es aligerar este peso y ayudarle con la preparación necesaria para enfrentar cualquier reto."
  },
  services: {
    header: "Conozca los servicios",
    items: [
      {
        title: "Financiero",
        span: "Administre sus finanzas sin estrés",
        content: "Obtenga mayor visibilidad sobre sus finanzas con la cuenta bancaria digital y tarjeta de débito PODERcard. Libérese de los saldos mínimos, cuotas mensuales, cargos ocultos, ¡y más!",
        mobileContent: "Obtenga mayor visibilidad sobre sus finanzas con la cuenta bancaria digital y tarjeta de débito PODERcard. Libérese de los saldos mínimos, cuotas mensuales, cargos ocultos, ¡y más!"
      },
      {
        title: "Grupo de Opinión",
        span: "Obtenga efectivo a cambio de su opinión",
        content: "Genere dinero extra al participar en encuestas que ayudan a mejorar los productos que otras marcas desarrollan para la comunidad.",
        mobileContent: "Genere dinero extra al participar en encuestas que ayudan a mejorar los productos que otras marcas desarrollan para la comunidad.",
      },
      {
        title: "Servicios médicos",
        span: "Consulte en español cuando y donde quiera",
        content: "Con una cuota fija, usted y toda su familia están cubiertos. Los servicios médicos incluyen servicios dentales, pruebas de laboratorio, medicamentos recetados, y más. Puede hacer una consulta en cualquier momento y en cualquier lugar, desde un dispositivo móvil o la computadora.",
        mobileContent: "Con una cuota fija, usted y toda su familia están cubiertos. Los servicios médicos incluyen servicios dentales, pruebas de laboratorio, medicamentos recetados, y más. Puede hacer una consulta en cualquier momento y en cualquier lugar, desde un dispositivo móvil o la computadora.",
      },
      {
        title: "Recursos Educativos",
        span: '',
        content: "Las guías pretenden orientarle sobre aspectos importantes de vivir en los EE. UU. Desde la atención médica que sus hijos pueden requerir hasta cómo empezar su primer negocio. Además, estamos a un mensaje de distancia mediante el chat, para responder cualquier duda que pueda tener. ¡Estamos aquí para ayudarle",
        mobileContent: "Las guías pretenden orientarle sobre aspectos importantes de vivir en los EE. UU. Desde la atención médica que sus hijos pueden requerir hasta cómo empezar su primer negocio. Además, estamos a un mensaje de distancia mediante el chat, para responder cualquier duda que pueda tener. ¡Estamos aquí para ayudarle",
      }
    ],
    cta: "Conozca más"
  },
  chat: {
    header: "¿Tiene alguna pregunta?",
    content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Iniciar conversación"
  },
  faq: {
    header: "Preguntas frecuentes",
    items: [
      {
        title: "¿Qué es SABEResPODER?",
        content: "SABEResPODER ofrece asesoría confiable para una comunidad de más de 4 millones de personas, empoderándolas con el conocimiento y las herramientas necesarias para tomar decisiones informadas, mejorar su calidad de vida y alcanzar una mayor estabilidad financiera y social.",
      },
      {
        title: "¿Los servicios de SABEResPODER son gratuitos?",
        content: "Unirse a nuestra comunidad es gratis, así como también consultar información confiable y ganar dinero con el programa de Rewards. Además, ofrecemos otros servicios de primera para promover el bienestar físico y financiero.",
      },
      {
        title: "¿Cómo puede SABEResPODER ayudarme?",
        content: "Estamos aquí para promover un mejor bienestar mediante recursos de salud, servicios bancarios accesibles, opciones para generar ingresos en línea y para brindar información confiable para lograr una vida más plena en los Estados Unidos.",
      },
      {
        title: "¿Se puede acceder a SABEResPODER afuera de los Estados Unidos?",
        content: "Nuestros contenidos están disponibles fuera de EE.UU., pero servicios como PODERsalud están disponibles solamente en territorio estadounidense. Contacte al Equipo de Atención al Cliente para obtener un desglose completo de nuestros servicios y determinar si son adecuados para usted.",
      }
    ],
    more: {
      title: "¿Tiene alguna pregunta?",
      content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Iniciar conversación"
    }
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Refer to the Rewards and Redemption section of our terms and conditions for full details of the program offering.",
      "PODERcard account opening is subject to identity verification by Thread Bank. Welcome Tech dba SABEResPODER is not a bank. Banking services provided by Thread Bank; Member FDIC. The PODERcard Visa® Debit Card is issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted. Your funds are FDIC insured up to $250,000 through Thread Bank; Member FDIC. You can apply using your Passport, ITIN or SSN",
      "TBD"
    ]
  }
};

export default about;
