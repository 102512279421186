import * as React from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import { useTranslation } from '@separate/hooks/translation';
import useAddressVerification from '@separate/hooks/useAddressVerification';
import {
  useFetchUserProfile,
  useUpdateUserProfile,
  useResetUserProfile,
  useInvalidateCurrentUser,
} from '@separate/hooks/user';
import { isStateEligible } from '@separate/utilities/premium';
import styles from '../editViews.module.scss';
import InputForm from './InputForm';
import useEditBasicInfoInputFormState from './useEditBasicInfoInputFormState';

export default function EditBasicInfo({ isPoderCardUser, isPremiumMember, onCancel, onUpdateBasicInfoSuccess }) {
  const { t } = useTranslation();
  const { isFetchingProfile, fetchUserProfile } = useFetchUserProfile({ onSuccess: onFetchSuccess });
  const { isUpdatingProfile, updateUserProfile } = useUpdateUserProfile({ onSuccess: onUpdateSuccess, onError: onUpdateFail });
  const resetUserProfile = useResetUserProfile();
  const invalidateCurrentUser = useInvalidateCurrentUser();

  const inputFormState = useEditBasicInfoInputFormState();

  React.useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  function onFetchSuccess({ user }) {
    inputFormState.setValue(user);
  }

  function onUpdateSuccess() {
    if (onUpdateBasicInfoSuccess) {
      invalidateCurrentUser();
      onUpdateBasicInfoSuccess();
    }

    resetUserProfile();
  }

  function onUpdateFail({ response }) {
    const fieldErrors = response.data?.field_errors;
    inputFormState.mergeErrors(fieldErrors);
  }

  function performUpdate(result = { ...inputFormState.value, usps_verified_address: true }) {
    updateUserProfile({ user: result });
  }

  function onAddressVerified() {
    performUpdate();
  }

  function onReplaceAddress(correctedAddress) {
    const newAnswer = { ...inputFormState.value, ...correctedAddress, usps_verified_address: true };
    inputFormState.setValue(newAnswer);
    updateUserProfile({ user: newAnswer });
  }

  const {
    isLoading: isVerificationLoading,
    verifyAddress,
    addressVerificationState,
  } = useAddressVerification({ onAddressVerified, onReplaceAddress });

  function handleSubmit() {
    if (isPremiumMember && !isStateEligible(inputFormState.value.state)) {
      inputFormState.mergeErrors({ state: [t('premium.not_available')] });
    } else {
      if (!inputFormState.value.address_line_1 && !inputFormState.value.city && !inputFormState.value.state && !inputFormState.value.zipcode) {
        performUpdate(omit(inputFormState.value, ['address_line_1', 'address_line_2', 'city', 'state', 'zipcode']));
      } else {
        verifyAddress({ address: inputFormState.value });
      }
    }
  }

  return (
    <div>
      <div className='d-flex w-100 justify-content-between'>
        <h4 className={styles.ctaTitle}> {t("user.profile.edit_cta_title")} </h4>
        <Button onClick={onCancel} className={styles.editCta}> {t("user.profile.cancel_edit_cta")} </Button>
      </div>

      <InputForm
        isPoderCardUser={isPoderCardUser}
        inputFormState={inputFormState}
        addressVerificationState={addressVerificationState}
        loading={isFetchingProfile || isUpdatingProfile || isVerificationLoading}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

EditBasicInfo.propTypes = {
  isPoderCardUser: PropTypes.bool,
  isPremiumMember: PropTypes.bool,
  onUpdateBasicInfoSuccess: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};
