import { useQuery, useMutation, useQueryClient } from 'react-query';
import { confirmSetupViaElements } from '@separate/api/sep/stripe';
import { useCurrentUserSession } from '@separate/hooks/session';
import { importApi } from '@separate/utilities/modules';

const {
  createFreeSubscription,
  getEditPaymentMethod,
  getPastDuePayment,
  getPendingPremiumSubscription,
  getSubscriptionBundles,
  getSubscription,
  listPaymentMethods,
  postPaymentSuccess,
  removePaymentMethod,
  updateCouponCode,
  updateDefaultPaymentMethod,
  updateSubscriptionBundle,
  undoCancellation,
} = await importApi('premium');

export function useActivatePremiumMembership({ onSuccess }) {
  const { isLoading, mutate } = useMutation(
    postPaymentSuccess,
    {
      onSuccess,
    }
  );
  return { isLoading, activatePremiumMembership: mutate };
}

export function useConfirmSetup({ onSuccess, onError }) {
  function handleResult(result) {
    if (!result?.error) {
      onSuccess(result);
    } else {
      onError(result);
    }
  }

  const { isLoading, mutate } = useMutation(
    ({ elements, stripe, billingDetails }) => confirmSetupViaElements(elements, stripe, billingDetails),
    {
      onSettled: handleResult,
    }
  );

  return { isLoading, confirmSetup: mutate };
}

export function useCouponCodeUpdate({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ couponCode, pid }) => updateCouponCode({ couponCode, pid }),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updateCouponCode: mutate };
}

export function useCreateFreeSubscription({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ setupIntentId, pid }) => createFreeSubscription(setupIntentId, pid),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, createFreeSubscription: mutate };
}

export function useEditPaymentMethodFetch({ onSuccess }) {
  const fetcher = useMutation((args) => getEditPaymentMethod(args), { onSuccess });
  const { isLoading, mutate, error } = fetcher;
  return {
    isLoading,
    error: error?.message,
    fetchSubscription: mutate,
  };
}

export function useSubscriptionBundleUpdate({ onSuccess, onError, isUpdate = false }) {
  function handleSuccess(data) {
    if (data?.success) {
      onSuccess(data.subscription);
    } else {
      onError(data?.subscription);
    }
  }
  const { isLoading, mutate } = useMutation(
    ({ bundleId, pid }) => updateSubscriptionBundle({ bundleId, pid, isUpdate }),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, updateSubscriptionBundle: mutate };
}

export function useListPaymentMethods(pid) {
  const { idToken } = useCurrentUserSession();

  const { isLoading, data } = useQuery(paymentMethodsQueryKey({ idToken, pid }), () => listPaymentMethods(pid));

  return { isLoading, data };
}

function paymentMethodsQueryKey({ idToken, pid }) {
  return ['payment_methods', idToken, pid];
}

export function usePastDuePaymentFetch({ onSuccess }) {
  const fetcher = useMutation((args) => getPastDuePayment(args), { onSuccess });
  const { isLoading, mutate, error } = fetcher;
  return {
    isLoading,
    error: error?.message,
    fetchSubscription: mutate,
  };
}

export function usePendingPremiumSubscriptionFetch({ onSuccess, onError }) {
  function handleSuccess(data) {
    if (data?.subscription) {
      onSuccess(data);
    } else {
      onError(null);
    }
  }
  const { isLoading, mutate, error } = useMutation(
    (args) => getPendingPremiumSubscription(args),
    {
      onSuccess: handleSuccess,
    }
  );
  return { isLoading, fetchSubscription: mutate, error: error?.message };
}

export function useSubscriptionBundlesFetch({ onSuccess, onError }) {
  const { isLoading, mutate, error } = useMutation(
    (args) => getSubscriptionBundles(args),
    {
      onSuccess,
      onError,
    }
  );
  return { isLoading, fetchSubscriptionBundles: mutate, error: error?.message };
}

export function useRemovePaymentMethod({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ paymentMethodId, pid }) => removePaymentMethod({ paymentMethodId, pid }),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422,
    }
  );

  return { isLoading, removePaymentMethod: mutate };
}

export function useResetListPaymentMethods() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return (pid) => queryClient.resetQueries(paymentMethodsQueryKey({ idToken, pid }));
}

export function useResetSubscriptionQuery() {
  const { idToken } = useCurrentUserSession();
  const queryClient = useQueryClient();
  return (pid) => queryClient.resetQueries(subscriptionQueryKey({ idToken, pid }));
}

function subscriptionQueryKey({ idToken, pid }) {
  return ['subscription', idToken, pid];
}

export function useSubscriptionQuery({ options = {}, pid }) {
  const { idToken } = useCurrentUserSession();

  const { fastRefresh } = options;
  const refetchOnMount = fastRefresh ? 'always' : true;

  const { isLoading, data } = useQuery(
    subscriptionQueryKey({ idToken, pid }),
    () => getSubscription(pid),
    { refetchOnMount },
  );

  return { isLoading, subscription: data };
}

export function useUpdateDefaultPaymentMethod({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    ({ paymentMethodId, pid }) => updateDefaultPaymentMethod({ paymentMethodId, pid }),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422,
    }
  );

  return { isLoading, updateDefaultPaymentMethod: mutate };
}

export function useUndoCancellation({ onSuccess, onError }) {
  const { isLoading, mutate } = useMutation(
    (args) => undoCancellation(args),
    {
      onSuccess,
      onError,
      useErrorBoundary: ({ response }) => response?.status !== 422,
    },
  );

  return { isLoading, undoCancellation: mutate };
}
