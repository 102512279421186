const premium = {
  coupon: {
    placeholder: "Añadir cupón",
    success: {
      title: "¡Éxito!",
      message: "Su cupón ha sido redimido",
    },
    error: {
      title: "¡Uh-oh!",
      message: "Código de Cupón Incorrecto",
    },
    redeem: "Redimir",
    payment_one: "pago",
    payment_other: "{{ count }} pagos",
    coupon_applied: "¡Felicidades! Recibirá un descuento del {{ discount }} en sus primeros {{ duration }}",
    your_subscription: '¡Tiene {{ coupon }} de regalo para suscribirse!',
    discount_will_be_automatically_applied_at_checkout: 'El crédito se aplicará automáticamente al pagar.',
  },
  payment: {
    title: "Pago",
    choose_the_plan: "Seleccione el plan de suscripción que le funcione mejor",
    subtitle: "Ingrese sus información de pago y comience a disfrutar de sus beneficios.",
    plan_type: "Tipo de plan",
    selected: "Su elección:",
    plan_price: "Costo del plan",
    membership: "Membresía anual SEP+ ",
    annual_membership: "Membresía anual",
    monthly_membership: "Membresía mensual",
    monthly: "Mensual",
    price: "Precio",
    duration: "mes",
    credit_card_number: "Número de tarjeta",
    expiry: "Expiración mm/aa",
    cvv: "CVV",
    confirm_purchase: "Confirmar compra",
    failure_title: "¡Uh-oh!",
    product_name: "Membresía Premium",
    or: "O",
    pay_with_card: "pague con tarjeta",
    update_and_pay: "Actualizar + Pagar",
    yearly: "Anual",
    proceed: "Proceder al pago",
    one_time_coupon: 'Cupón de uso único',
    one_time_credit: 'Crédito de un solo uso',
    total: 'Total',
    confirmed: 'Confirmación de pago',
    received: '¡Muchas gracias! Su pago ya fue recibido.',
    processing: 'Procesando pago...',
    change_payment_method: 'Cambiar método de pago',
    pay_now: 'Hacer pago',
    added: '¡Su tarjeta ha sido añadida!',
    added_subtitle: '¿Quiere proceder a pagar su suscripción con este método de pago?',
    failed: '¡Oh! Su pago no pasó',
    ensure_funds_or_new_card: 'Asegúrese de tener fondos en su cuenta o intente añadir una nueva tarjeta.',
    will_be_default: 'Esta tarjeta será utilizada para futuros pagos',
    new_card_added: '¡Tarjeta nueva añadida!',
    new_card_delay: 'Por favor, espere 5 minutos para que su nueva tarjeta se muestre en su perfil.',
    free_trial: 'Prueba gratuita',
  },
  past_due: {
    status_message: "El pago de su suscripción a PODERsalud no pasó. Por favor consulte el saldo de su tarjeta y actualice su método de pago.",
    success_title: "Felicidades",
    success_message: "Su pago se ha procesado correctamente y la información de su tarjeta se ha actualizado.",
  },
  not_available: "Lo sentimos, este producto no está disponible en su estado.",
  update_payment: {
    success_title: "Felicidades",
    // TODO: This is probably wrong.  We don't have copy for this in proton
    success_message: "La información de su tarjeta se ha actualizado.",
  },
  annual: {
    billed_annually: "Un solo cobro <strong>anual</strong>",
    billed_monthly: "Cobro <strong>mensual</strong>",
    billed_custom: "Cobro <strong>semestral ({{ intervalCount }} meses</strong>)",
    billed_annually_light: "Un solo cobro anual",
    billed_monthly_light: "Cobro mensual",
    billed_custom_light: "Cobro semestral ({{ intervalCount }} meses)",
    discount_percentage: "{{ discount }} de ahorro",
    months_free: "{{ number }} meses gratis",
    no_discount: "Sin discuento",
    pay_full_price: "Pagar precio regular",
    per_month: "/ mes",
    per_year: "/ año",
    per_custom_interval: "/ {{ intervalCount }} meses",
  },
  ask_us_anything: {
    title: "Pregúntenos cualquier duda",
    got_questions: "¿Tiene preguntas? ¿Le gustaría saber si PODERsalud es ideal para usted? ¡Con gusto le asistimos!",
    talk_with_an_expert: "Hable con un experto",
  },
  upgrade_subscription_plan: {
    monthly: 'mensual',
    yearly: 'anual',
    next_billing_date: 'Próxima fecha de facturación:',
    save_more: 'Cambie de plan hoy',
    update_your_plan: 'Actualización de plan',
    upgrade_now: 'Actualizar ahora',
    save_over_50_per_year: 'Ahorre en su suscripción a PODERsalud con nuestro plan anual.',
    you_will_be_charge_today_the_full_year: 'Efectuaremos hoy el cargo por todo el año. Su próxima fecha de facturación será en {{ next_payment }}.',
    proceed_to_payment: 'Proceder al pago',
    process_payment: 'Actualizar ahora',
    processing_payment: 'Procesando pago...',
    your_payment_has_been_received: 'Su pago ya fue recibido. ¡Muchas gracias! Su próxima fecha de facturación es en {{ next_payment }}.',
    your_plan_has_been_updated: '¡Su plan ha sido actualizado!',
    close: 'Cerrar',
    your_payment_failed: '¡Oh! Su pago no pasó',
    make_sure_you_have_sufficient_funds: 'Asegúrese de tener fondos en su cuenta o intente añadir una nueva tarjeta.',
    try_again: 'Inténtelo de nuevo',
    add_payment: 'Add payment (SP)',
    revise_your_payment_method: 'Revise su método de pago',
    confirm_your_payment_method: 'Confirme su método de pago',
    retry_with_this_card: 'Reintentar con esta tarjeta',
    you_can_try_again: 'Puede intentar de nuevo con su actual método de pago o cambiarlo.',
    go_back: 'Volver',
    select_payment_option: 'Confirme su método de pago',
    change_payment_method: 'Cambiar método de pago',
    upgrade_plan: 'Actualizar plan',
  },
  price_increase: {
    title: '¡Nuevo precio a partir del 16 de mayo!',
    text: 'Suscríbase pronto para pagar solo $16.95 al mes.',
  },
  free_trial: {
    banner: {
      title: 'Pruebe PODERsalud',
      description: 'Promoción exclusiva - membresía de salud para <span>usted y su familia</span>',
      description_plural: 'Promoción exclusiva - membresía de salud para <span>usted y su familia</span>',
    },
    splash: {
      bulletBox: [
        {
          item: 'Prueba gratuita de <span>{{ numberOfDays }} días*</span>',
        },
        {
          item: 'Disfrute los beneficios de primera calidad para usted y sus dependientes',
        },
        {
          item: 'Acceda a Atención al Cliente para asistencia',
        },
        {
          item: 'Cancele en cualquier momento',
        },
      ],
      learn_more: 'Aprenda más',
      text: 'Promoción exclusiva - membresía de salud para usted y su familia',
      title: '<span>Pruebe</span> PODERsalud',
    },
    terms_and_conditions: 'Términos y condiciones para la prueba gratuita de PODERsalud',
  },
  disclaimer: {
    title: "Divulgaciones",
    items: [
      "Inscripción para plan de descuento. Este programa NO ES una póliza de seguros, la intención NO ES reemplazar a su compañía de seguros, y tampoco cumple con los requisitos mínimos de cobertura que piden el Affordable Care Act o el M.G.L. c. 111M y 956 CRM 5.00 de Massachusetts. Su membresía es efectiva desde la recepción de los materiales de membresía. Este programa no está disponible para residentes de UT, VT o WA. Tras inscribirse, Tendrá 30 días para evaluar el servicio y si no queda satisfecho en ese período, puede cancelar el programa sin costo alguno y le devolveremos su dinero. Los descuentos los puede utilizar sólo en las oficinas de los proveedores de servicios médicos que forman parte de la red, y cada titular de la membresía tiene la obligación de pagar los servicios descontados en el momento en el que se reciben. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475. Formulario # HPSEP2",
    ],
  },
};

export default premium;
