const newBenefitsPharmacySearch = {
  search: 'Buscar',
  zipcode: 'Código Postal',
  drugName: 'Nombre de la droga',
  title: 'Búsqueda De Precios De Medicamentos',
  zipCodeValidationError: 'Por favor ingrese un código postal de 5 dígitos',
  positiveIntegerError: 'Por favor ingrese valor entero mayor que 0',
  milesError: 'Ingrese un valor entero mayor que 0 y menor que 55000',
  phone: 'Número De Teléfono: ',
  miles: 'Radio en millas',
  drugsQuantity: 'Número de Medicamentos',
  daySupply: 'Suministro Diario',
}

export default newBenefitsPharmacySearch;
