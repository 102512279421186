const footer = {
  header: "Welcome to Opportunity.",
  quick: "Quick Links",
  discover: "Discover",
  health: "Health and Wellness",
  rewards: "Earning Opportunities",
  banking: "Banking Services",
  knowledge: "Knowledge Hub",
  company: "Company",
  about: "About Us",
  customer: "Customer Support",
  terms: "Terms of Use and Privacy Agreements",
};

export default footer;
