import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { LanguageEN, LanguageES } from '@separate/v4/icons';
import LangSelectModal from './LangSelectModal';
import styles from './languageSelector.module.scss';
import { setCookie } from '@separate/utilities/cookies';
import { useRouter } from 'next/router';
import { useLocale } from '@separate/hooks/translation';
import { USER_LANGUAGE } from '@separate/constants/language';
import cx from 'classnames';


export default function LanguageSelector(props) {
  const { className, disabled, ...extraProps } = props;
  const [show, setShow] = useState(false);
  const router = useRouter();
  const locale = useLocale();

  const handleClick = () => {
    setShow(!show);
  };

  function onSelect(newLocale) {
    setCookie(USER_LANGUAGE, newLocale);
    router?.replace(router?.asPath, router?.asPath, { locale: newLocale });
  }

  const handleSelect = (val) => {
    onSelect(val);
    setShow(false);
  };

  return (
    <>
      <Button onClick={handleClick} className={cx(styles.button, className)} disabled={disabled}>
        {locale === 'en' ? (
          <LanguageEN className={styles.icon} />
        ) : (
          <LanguageES className={styles.icon} />
        )}
      </Button>
      <LangSelectModal show={show} locale={locale} onHide={handleSelect} />
    </>
  );
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
