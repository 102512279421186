import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { presence } from '@separate/utilities/string';

export default function validateBirthday(birthday, isAdult) {
  dayjs.extend(isSameOrAfter);
  const currentAge = dayjs().diff(birthday, 'year');
  const isTodayOrFutureDate = dayjs(birthday).isSameOrAfter(dayjs(), 'day');

  if (!presence(birthday)) return null;

  if (currentAge === NaN) return null;

  if (isAdult && currentAge < 18) {
    return 'dependent.must_be_18_or_over';
  } else if (!isAdult && currentAge >= 18) {
    return 'dependent.must_be_under_18';
  } else if (isTodayOrFutureDate) {
    return 'dependent.dob_must_be_before_today';
  } else {
    return null;
  }
}
