const footer = {
  header: "Red de empoderamiento latino",
  quick: "Servicios",
  discover: "Explorar",
  health: "Salud y bienestar",
  rewards: "Generar ingresos",
  banking: "Servicios bancarios",
  knowledge: "Centro de conocimiento",
  company: "Empresa",
  about: "Acerca de",
  customer: "Atención al cliente",
  terms: "Acuerdos y Políticas de Privacidad",
};

export default footer;
