import React from 'react';
import PropTypes from 'prop-types';
import InfoRow from './InfoRow';

export default function Email(props) {
  const { email } = props;

  return (
    <InfoRow
      label="user.email"
    >
      {email}
    </InfoRow>
  );
}

Email.propTypes = {
  email: PropTypes.string,
};
