import React from 'react';
import { useCurrentUser } from '@separate/hooks/user';
import { initalizeBraze } from '@/utilities/braze';

export function useBraze() {
  const { isLoggedIn, user } = useCurrentUser();
  const [brazeClient, setBrazeClient] = React.useState();

  function clearBrazeSession() {
    brazeClient?.removeAllSubscriptions();
    setBrazeClient();
  }

  React.useEffect(() => {
    if (isLoggedIn && user?.pid) {
      if (brazeClient) return true;

      initalizeBraze(user.pid, setBrazeClient);
    } else {
      clearBrazeSession();
    }
    return () => {
      clearBrazeSession();
    }
  }, [isLoggedIn, user?.pid]);
}
