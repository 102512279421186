import React from 'react';
import styles from './index.module.scss';
import { useV4Translation } from '@v4/src/hooks/translation';
import Link from 'next/link';

export default function Watermarks() {
  const { t } = useV4Translation();
  return (
    <div className={styles.watermark}>
      <span className="d-block mb-1">
        &copy; {new Date().getFullYear()} SABEResPODER
      </span>
      <Link href="/legal/terms-and-conditions">
        <a target="_blank" rel="noopener noreferrer">
          {t("v4.footer.terms")}
        </a>
      </Link>
      <span className="d-block mt-1">{process.env.NEXT_PUBLIC_VERSION}</span>
    </div>
  );
}

