import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Checkbox from '@separate/components/Checkbox';
import { useTranslation } from '@separate/hooks/translation';
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './terms.module.scss';

export default function Terms({ checked, onChange }) {
  const { t } = useTranslation();
  const allowNew2FAModal = isFeatureEnabled('allow_new_2fa_modal');

  return (
    <div className='mt-4'>
      <p className={styles.description}>
        {t(`login.sms.${allowNew2FAModal ? 'new_text' : 'text'}`)}
      </p>
      <ul className={cx(styles.bullet, 'mt-2')}>
        <li>{t('login.sms.bullet1')}</li>
        <li>{t('login.sms.bullet2')}</li>
        <li>{t('login.sms.bullet3')}</li>
      </ul>
      <div className="mt-4 d-flex flex-row mb-4">
        <Checkbox
          checked={checked}
          onChange={onChange}
          className={styles.check}
          id="accept_terms"
        />
        <label
          htmlFor="accept_terms"
          className={cx(styles.label, "ps-3")}
        >
          {t('login.accept_terms')}{" "}
          <Link href="/legal/terms-and-conditions">
            <a
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('login.terms_and_conditions')}
            </a>
          </Link>
        </label>
      </div>
    </div>
  );
}

Terms.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};
