import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { SABERESPODER_EMAIL } from '@/constants/profile';
import { useTranslation } from '@separate/hooks/translation';
import styles from './accountCancellation.module.scss';

export default function AccountCancellation({ onEditSMSPreferences }) {
  const { t } = useTranslation();
  const listItems = t('user.profile.account_cancellation.details', { returnObjects: true });

  return (
    <div className={cx(styles.accountCancellation, 'd-flex flex-column justify-content-between')}>
      <div>
        <h4 className={styles.ctaTitle}>{t('user.profile.account_cancellation.title')}</h4>
        <p className={cx(styles.text, 'mt-3')}>
          {t('user.profile.account_cancellation.text_one')}
          <a href={`mailto:${SABERESPODER_EMAIL}`}>{SABERESPODER_EMAIL}</a>
          {t('user.profile.account_cancellation.text_two')}
        </p>
        <ul className='mt-3 mb-4'>
          {listItems.map(({ text }) => (
            <li key={text}>{text}</li>
          ))}
        </ul>
        <div className={cx(styles.note, 'mt-4')}>
          <p>
            {t('user.profile.account_cancellation.note.text_one')}
            <span>{t('user.profile.account_cancellation.note.text_two')}</span>
          </p>
        </div>
      </div>

      <div>
        <div className='d-block text-center px-4 my-4'>
          <Button
            className={cx(styles.cta, 'w-100')}
            onClick={onEditSMSPreferences}
          >
            {t('user.profile.account_cancellation.go_back')}
          </Button>
        </div>
      </div>
    </div>
  );
}

AccountCancellation.propTypes = {
  onEditSMSPreferences: PropTypes.func.isRequired,
};
