import React from 'react';
import PropTypes from 'prop-types';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
  PAYMENT_METHOD_ADDED,
  PAYMENT_METHOD_ADD_FAILURE,
  PAYMENT_METHOD_DEFAULT_FAILURE,
  PAYMENT_METHOD_DEFAULT_SUCCESS,
} from '@separate/constants/analytics';
import { useEditPaymentMethodFetch, useUpdateDefaultPaymentMethod } from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { ChevronDownRounded, PODERcardGradient } from '@separate/icons';
import { logEvent } from '@separate/utilities/analytics';
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './addNewCardOffcanvas.module.scss';
import CreditCardInputForm from './CreditCardInputForm';
import PaymentElementInputForm from './PaymentElementInputForm';

export default function AddNewCardOffcanvas({
  container,
  onHide,
  onSuccess,
  show,
  isUpgradeSubscriptionPlan,
  onCancel,
  pid,
  onCardDecline,
}) {
  const { t } = useTranslation();
  const allowAppleAndGooglePayMethods = isFeatureEnabled('allow_apple_and_google_payment_methods');

  const [isDefault, setIsDefault] = React.useState(true);
  const [addedPaymentMethodId, setAddedPaymentMethodId] = React.useState();

  function handleHide() {
    onHide();
  }

  const [subscription, setSubscription] = React.useState();
  const clientSecret = subscription?.client_secret;

  const onFetchSubscriptionSuccess = React.useCallback((data) => {
    setSubscription(data?.subscription);
  }, []);
  const { fetchSubscription } = useEditPaymentMethodFetch({ onSuccess: onFetchSubscriptionSuccess });

  React.useEffect(() => {
    if(!show) return;
    if(!fetchSubscription) return;
    if(subscription?.client_secret) return;

    fetchSubscription({ pid });
  }, [fetchSubscription, pid, show, subscription?.client_secret]);

  function handleSuccessCompleted() {
    handleHide();
    onSuccess(isDefault);
  }

  function handleDefaultSuccess() {
    logEvent(PAYMENT_METHOD_DEFAULT_SUCCESS, { payment_method_id: addedPaymentMethodId });
    handleSuccessCompleted();
  }

  function handleDefaultFailure() {
    logEvent(PAYMENT_METHOD_DEFAULT_FAILURE, { payment_method_id: addedPaymentMethodId });
    handleHide();
  }

  const { updateDefaultPaymentMethod } = useUpdateDefaultPaymentMethod({
    onSuccess: handleDefaultSuccess,
    onError: handleDefaultFailure,
  });

  function handleAddSuccess(result) {
    const paymentMethodId = result?.setupIntent?.payment_method;
    setAddedPaymentMethodId(paymentMethodId);

    logEvent(PAYMENT_METHOD_ADDED, { as_default: isDefault, payment_method_id: paymentMethodId });

    if(isDefault && paymentMethodId) {
      updateDefaultPaymentMethod({paymentMethodId, pid});
    } else {
      handleSuccessCompleted();
    }
  }

  function handleFailure() {
    logEvent(PAYMENT_METHOD_ADD_FAILURE, { as_default: isDefault });
    onCardDecline && onCardDecline();
    handleHide();
  }

  return (clientSecret ? (
    <Offcanvas
      backdropClassName={styles.backdrop}
      className={styles.container}
      container={container}
      onHide={handleHide}
      placement="bottom"
      show={show}
    >
      <Offcanvas.Header className={styles.header}>
        <Offcanvas.Title className={styles.title}>
          {t('user.profile.add_new_card')}
        </Offcanvas.Title>
        <ChevronDownRounded aria-label="Hide" className={styles.closeButton} onClick={handleHide} />
      </Offcanvas.Header>
      <Offcanvas.Body className={styles.body}>
        <div className={styles.infoBanner}>
          <PODERcardGradient className="me-1" />
          {t('user.profile.card_info')}
        </div>

        {allowAppleAndGooglePayMethods ? (
          <PaymentElementInputForm
            clientSecret={clientSecret}
            isDefault={isDefault}
            isUpgradeSubscriptionPlan={isUpgradeSubscriptionPlan}
            onCancel={onCancel}
            onFailure={handleFailure}
            onSuccess={handleAddSuccess}
            setIsDefault={setIsDefault}
            pid={pid}
          />
        ) : (
          <CreditCardInputForm
            clientSecret={clientSecret}
            isDefault={isDefault}
            isUpgradeSubscriptionPlan={isUpgradeSubscriptionPlan}
            onCancel={onCancel}
            onFailure={handleFailure}
            onSuccess={handleAddSuccess}
            setIsDefault={setIsDefault}
            pid={pid}
          />
        )}

      </Offcanvas.Body>
    </Offcanvas>
  ) : (
    null
  ));
}

AddNewCardOffcanvas.propTypes = {
  container: PropTypes.any,
  isUpgradeSubscriptionPlan: PropTypes.bool,
  onCancel: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  show: PropTypes.bool,
  pid: PropTypes.string, // for zendesk-sep-admin
  onCardDecline: PropTypes.func,
};
