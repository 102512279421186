import React from 'react';
import PropTypes from 'prop-types';
import LanguageSelector from '@separate/v4/components/LanguageSelector';
import LoginButton from 'v4/src/components/LoginButton';
import styles from './index.module.scss';
import cx from 'classnames';

export default function NavButtons(props) {
  const { className, disableLanguageSelect } = props;

  return (
    <div className={className}>
      <LanguageSelector
        className={cx(styles.navButton, "px-0")}
        disabled={disableLanguageSelect}
      />
      <LoginButton className={styles.navButton}/>
    </div>
  );
}

NavButtons.propTypes = {
  className: PropTypes.string,
  disableLanguageSelect: PropTypes.bool,
};
