import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_KEY } from '@/env';

if (BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()]
  });
}

const Passthrough = ({ children }) => <>{children}</>;

export const ErrorBoundary = BUGSNAG_KEY ?
  Bugsnag.getPlugin('react').createErrorBoundary(React) :
  Passthrough;

export const notify = BUGSNAG_KEY ?
  Bugsnag.notify :
  (error) => { console.log("Simulate reporting to bugsnag:", error); };
