const menu = {
  home: 'Inicio',
  earn: 'Recompensas',
  chat: 'Chat',
  articles: 'Guías',
  premium: 'PODERsalud',
  farmaSEP: "farmaSEP",
  digital_banking: 'Banco digital',
  jobs_network: 'Red de empleos',
  profile: 'Su cuenta'
};

export default menu;
