import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import FieldErrorsList from '@separate/components/FieldErrorsList';
import { RESEND_CODE_ERROR } from '@separate/constants/analytics';
import { useConfirm, useResendCode } from '@separate/hooks/login';
import { useTranslation } from '@separate/hooks/translation';
import styles from '../steps.module.scss';
import InputForm from './InputForm';
import useConfirmationInputFormState from './useConfirmationInputFormState';

export default function ConfirmationCode({ phoneNumber, onConfirmationCodeSuccess, headerText }) {
  const { t } = useTranslation();

  const inputFormState = useConfirmationInputFormState();
  const { confirmation } = inputFormState.value;

  const disabled = !inputFormState.isValid;

  const onSuccess = (response) => {
    onConfirmationCodeSuccess();
  };

  const onError = () => {
    const fieldErrors = {
      confirmation: [
        t(`validations.incorrect`, {field: `^${inputFormState?.labelFor("confirmation")}`}),
      ],
    };
    inputFormState.mergeErrors(fieldErrors);
  };

  const onResendSuccess = ({ response }) => {
    // The resend is fire and forget.  This is a no-op.
  };

  const onResendError = ({ response }) => {
    logEvent(RESEND_CODE_ERROR, { result: response.status });
  };

  const { isLoading: isConfirmLoading, confirm } = useConfirm({ onSuccess, onError });
  const { isResendLoading, resendCode } = useResendCode({ onResendSuccess, onResendError });
  const isLoading = isConfirmLoading || isResendLoading;

  const handleSubmit = () => {
    confirm({ phoneNumber, code: confirmation });
  };

  const handleResend = () => {
    resendCode({ phoneNumber });
  };

  return (
    <div className='pt-2'>
      <h2 className={styles.title}>{headerText}</h2>
      <p className={styles.subtitle}>{t('login.please_enter_5_digit_code')}</p>
      <InputForm inputFormState={inputFormState} useSepStyling />
      <FieldErrorsList fieldErrors={inputFormState.fieldErrors} />
      <div className='mt-4 d-block text-center'>
        <Button
          className={cx(styles.cta, {
            [styles.disabled]: disabled,
          })}
          onClick={handleSubmit}
          loading={isLoading}
          disabled={disabled}
        >
          {t('login.enter_code')}
        </Button>
      </div>
      <div className={cx(styles.footer, 'mt-4 d-flex align-items-center justify-content-center')}>
        <span>{t('login.resend_label')}</span>
        <Button
          className={styles.footerCta}
          onClick={handleResend}
        >
          {t('login.resend_code')}
        </Button>
      </div>
      <p className={cx(styles.footer, 'mt-3')}>
        {t('login.footer.text')}
        <a href="tel:(726) 202-1213">{t('login.footer.number')}</a>
      </p>
    </div>
  );
}

ConfirmationCode.propTypes = {
  headerText: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  onConfirmationCodeSuccess: PropTypes.func.isRequired,
};
