const jobs = {
  apply: 'Solicitar empleo',
  back: 'Atrás',
  complete_profile: {
    title: 'Parece que no ha terminado su perfil. ¡Complete ya sus preferencias y reciba recomendaciones de empleo personalizadas!',
    progress: 'completo',
    cta: 'Completar mi perfil',
  },
  edit_profile: {
    title: 'Mi perfil',
    subtitle: 'Completo',
    cta: 'Editar',
  },
  header: {
    jobs: 'Empleos',
    jobs_network: 'Red de empleos',
    jobs_resources: 'Recursos',
    next_job_title: '¡Encuentre su próximo empleo!',
    next_job_subtitle: {
      none: 'Regístrese hoy para ver recomendaciones de trabajos personalizadas.',
      registered: 'Termine su perfil en minutos y aumente sus posibilidades de conseguir un mejor trabajo.',
      jobs_profile_complete: 'Oportunidades laborales personalizadas y consejos para conseguir trabajo.',
    },
    cta: 'Crear mi perfil',
    search: 'Buscar por cargo, ocupación o empresa',
    search_articles: 'Buscar',
    location: 'Ciudad, estado o código postal',
  },
  jobs_text: 'Empleos',
  join_for_free: 'Unirme gratis',
  language_availability_modal: {
    title: '¡Lo sentimos! Por el momento algunos empleos solo se muestran en inglés. Estamos trabajando en ello.',
    subtitle: {
      none: 'Al crear su perfil puede recibir más ofertas de trabajo personalizadas y en su idioma.',
      registered: 'Al terminar su perfil puede recibir más ofertas de trabajo personalizadas y en su idioma.',
      jobs_profile_complete: 'Al añadir sus experiencias y preferencias en su perfil puede recibir ofertas de trabajo aún más personalizadas y en su idioma.',
    },
    cta: {
      none: 'Crear mi perfil',
      registered: 'Completar mi perfil',
      jobs_profile_complete: 'Editar mi perfil',
    },
    close_text: 'Ver empleos en inglés',
  },
  results_in_location: '{{ results }} resultados cerca de',
  resources: {
    header: 'Recursos',
    main: {
      title: 'Asesoría laboral ',
      inner_title: 'en la que puede confiar.',
    },
    job_search: {
      title_one: 'Encontrar ',
      title_two: 'empleo',
      subtitle: 'Dónde buscar empleo, cómo redactar un curriculum vitae, negociar sueldo y más',
    },
    workplace_success: {
      title_one: 'Éxito ',
      title_two: 'laboral',
      subtitle: 'Sus derechos, protecciones y beneficios en el trabajo',
    },
    career_transitions: {
      title_one: 'Transición ',
      title_two: 'de profesión',
      subtitle: 'Cursos y estudios para dedicarse a una nueva ocupación',
    },
    income_generation: {
      title_one: 'Generar ',
      title_two: 'ingresos',
      subtitle: 'Ideas de negocios, cómo ganar dinero por Internet y más',
    },
  },
  resources_no_results: "No se han econtrado resultados para",
  update_location: 'Cambiar ubicación',
  view_all: 'Mostrar todos',
  view_more: 'Ver más',
  go: 'Ir',
  view_more_jobs: 'Ver más empleos',
  zip_code_validation_error: 'Por favor ingrese un código postal de 5 dígitos',
};

export default jobs;
