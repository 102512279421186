import React from 'react';
import NavButtons from './NavButtons';
import NavItems from './NavItems';
import SeparateHeaderBar from '@separate/v4/components/HeaderBar';
import styles from './index.module.scss';
import { useCurrentUser } from '@separate/hooks/user';
import { Profile } from '@v4/src/icons';

/* eslint react/prop-types: 0 */
export default function HeaderBar() {
  const { isLoggedIn, user } = useCurrentUser();

  const brand = () => {
    return <div className={styles.logo} />
  }
  const offCanvasHeader = () => {
    if(!isLoggedIn && !user) {
      return null
    }
    return <div className={styles.profile}>
      <Profile className={styles.icon}/>
      <div>
        <p>{user?.full_name}</p>
        <span>{user?.email}</span>
      </div>
    </div>;
  };
  return (
    <>
      <SeparateHeaderBar
        brand={brand()}
        navItems={<NavItems />}
        navButtons={<NavButtons />}
        className={styles.headerBar}
        offCanvasClassName={styles.offCanvas}
        offCanvasHeader={offCanvasHeader()}
      />
    </>
  );
}
