const studies = {
  max_out_of_quota_limit_reached: {
    header: 'Esta encuesta llegó a su límite y ya se cerró',
    text: 'Algunas encuestas se cierran cuando llegan al límite de respuestas necesarias.<br><br>Pero no se preocupe ¡cuando una encuesta se cierra, otra se abre! Visite el Centro de Recompensas y entérese temprano de nuevas oportunidades para ganar.'
  },
  completion_header: {
    default: "Algo salió mal",
    qualified: "¡Felicidades, ",
    added_to_account: "agregado al su cuenta",
    added_to_podercard: "agregado al su PODERcard",
    non_qualified: "No calificó para esta encuesta",
    out_of_quota: "Esta encuesta llegó a su límite y ya se cerró",
  },
};

export default studies;
