import useInputFormState from '@separate/hooks/useInputFormState';
import { required, name, email, dateMonthFirst, phone, validateBirthday } from '@separate/validations';

const VALIDATIONS = (isAdult) => {
  const validateRequired = (value) => isAdult && required(value);

  return {
    first_name: {
      translationKey: 'user.first_name',
      rules: [required, name],
    },
    last_name: {
      translationKey: 'user.last_name',
      rules: [required, name],
    },
    birthday: {
      translationKey: 'user.birthday_with_format',
      rules: [required, dateMonthFirst, (birthday) => validateBirthday(birthday, isAdult)],
    },
    phone_number: {
      translationKey: 'user.phone_number',
      rules: [validateRequired, phone],
    },
    email: {
      translationKey: 'user.email',
      rules: [validateRequired, email],
    },
  };
};

export function useDependentsInputFormState(isAdult) {
  return useInputFormState(VALIDATIONS(isAdult));
}
