import React from 'react';
import PropTypes from 'prop-types';
import { inputFormStateType } from '@/types';
import TextInput from '@separate/components/TextInput';
import { useTranslation } from '@separate/hooks/translation';

export default function InputForm({ inputFormState, loading, adult }) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <TextInput
          type="text"
          name="first_name"
          disabled={loading}
          inputFormState={inputFormState}
          placeholder={`${t('user.first_name')}*`}
        />
        <TextInput
          type="text"
          name="last_name"
          disabled={loading}
          inputFormState={inputFormState}
          placeholder={`${t('user.last_name')}*`}
        />
        <TextInput
          type="tel"
          name="birthday"
          mask="99/99/9999"
          placeholder={`${t('user.birthday_with_format')}*`}
          disabled={loading}
          inputFormState={inputFormState}
        />
        {adult && (
          <>
            <TextInput
              type='tel'
              name="phone_number"
              placeholder={`${t('user.phone')}*`}
              mask='(999) 999-9999'
              unmasked
              disabled={loading}
              inputFormState={inputFormState}
            />
            <TextInput
              type="email"
              name="email"
              placeholder={`${t('user.email')}*`}
              disabled={loading}
              inputFormState={inputFormState}
            />
          </>
        )}
      </div>
    </div>
  );
}

InputForm.propTypes = {
  inputFormState: inputFormStateType,
  loading: PropTypes.bool,
  adult: PropTypes.bool,
};

InputForm.defaultProps = {
  inputFormState: {},
};
