import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/main.scss';
import V4Layout from '@v4/src/components/Layout';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';
import HeadData from '@/components/HeadData';
import { API_MOCKING, POSTHOG_HOST, POSTHOG_PROJECT_KEY, SEGMENT_URL, SEGMENT_WRITE_KEY } from '@/env';
import { ErrorBoundary } from '@/error';
import { useHistoryWithTranslations } from '@/hooks/history';
import { PostHogProvider } from '@/hooks/posthog';
import i18n from '@/i18n';
import { useGlobalClickListener, useLogPageView, useRouterChangeAnalytics } from '@separate/hooks/analytics';
import { SingleSignOnProvider } from '@separate/hooks/singleSignOn';
import { rememberUtmTags } from "@separate/utilities/utmTagsStore";
import 'dayjs/locale/es';

if (API_MOCKING === 'enabled') {
  import('@/mocks');
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15 * 1000,
      refetchOnWindowFocus: 'always',
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
});

/* eslint react/prop-types: 0 */
function MyApp({ Component, pageProps }) {
  const { locale, query, pathname } = useRouter();
  i18n.changeLanguage(locale);
  dayjs.locale(locale);
  rememberUtmTags(query);
  useHistoryWithTranslations();
  useRouterChangeAnalytics();
  useLogPageView();
  useGlobalClickListener();

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HeadData />
        <SingleSignOnProvider>
          <PostHogProvider posthogHost={POSTHOG_HOST} posthogKey={POSTHOG_PROJECT_KEY} segmentHost={SEGMENT_URL} segmentKey={SEGMENT_WRITE_KEY}>
            <V4Layout Component={Component} pageProps={pageProps} />
          </PostHogProvider>
        </SingleSignOnProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
