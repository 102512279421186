import React from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Checkbox from '@separate/components/Checkbox';
import { STRIPE_PUBLISHABLE_KEY } from '@separate/env';
import { useConfirmSetup } from '@separate/hooks/premium';
import { useLocale, useTranslation } from '@separate/hooks/translation';
import { useQueryUserProfile } from '@separate/hooks/user';
import { GoBack } from '@separate/icons';
import styles from './addNewCardOffcanvas.module.scss';
import { APPEARANCE, OPTIONS } from './paymentElementConstants';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentElementForm = ({
  isDefault,
  isUpgradeSubscriptionPlan,
  onCancel,
  onFailure,
  onSuccess,
  setIsDefault,
}) => {
  const { t } = useTranslation();
  const { user } = useQueryUserProfile();

  const billingDetails = {
    name: `${user?.first_name} ${user?.last_name}`,
    email: user?.email,
    phone: `${user?.phone_number}`,
    address: {
      line1: user?.address_line_1,
      line2: user?.address_line_2,
      city: user?.city,
      state: user?.state,
      country: 'US',
      postal_code: user?.zipcode,
    },
  };

  const elements = useElements();
  const stripe = useStripe();

  function handleConfirmFailure() {
    onFailure();
  }

  function handleConfirmSuccess(result) {
    onSuccess(result);
  }

  const { isLoading, confirmSetup } = useConfirmSetup({
    onSuccess: handleConfirmSuccess,
    onError: handleConfirmFailure,
  });

  function handleSubmit(event) {
    event.preventDefault();
    confirmSetup({ elements, stripe, billingDetails });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.cardInfo}>
        <PaymentElement options={OPTIONS} />
      </div>

      <Checkbox
        className={styles.checkbox}
        checked={isDefault}
        label={t("user.profile.make_default")}
        onChange={() => setIsDefault(!isDefault)}
      />

      <div className="d-flex justify-content-center">
        {isUpgradeSubscriptionPlan && (
          <Button
            className={cx(styles.buttonLight, "w-50")}
            disabled={!stripe || !elements?.getElement('payment')}
            onClick={() => onCancel()}
          >
            <GoBack />
            {t("premium.upgrade_subscription_plan.go_back")}
          </Button>
        )}
        <Button
          className={cx(styles.button, 'w-75')}
          type="submit"
          disabled={isLoading}
        >
          {isUpgradeSubscriptionPlan ? (
            t("premium.upgrade_subscription_plan.upgrade_now")
          ) : (
            t("user.profile.add_card")
          )}
        </Button>
      </div>
    </form>
  );
};

export default function PaymentElementInputForm({
  clientSecret,
  ...props
}) {
  const locale = useLocale();

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, loader: 'never', locale, appearance: APPEARANCE }}>
      <PaymentElementForm {...props} />
    </Elements>
  );
}

PaymentElementInputForm.propTypes = {
  clientSecret: PropTypes.string,
  isDefault: PropTypes.bool,
  isUpgradeSubscriptionPlan: PropTypes.bool,
  onCancel: PropTypes.func,
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setIsDefault: PropTypes.func.isRequired,
};

PaymentElementForm.propTypes = Object.fromEntries(Object.entries(PaymentElementInputForm.propTypes));
delete PaymentElementForm.propTypes.clientSecret;
