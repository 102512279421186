import { useMutation } from 'react-query';
import { importApi } from '@separate/utilities/modules';

const { getUserAddress, updateUserAddress, validateUserAddress } = await importApi('address');

// "Fetch" is distinct from "Query" in that it only fetches once upon an explicit trigger, whereas query will
// continually refresh if the cache gets invalidated.  One-time fetch is what we want for pre-populating a form
// so that any edits do not get clobbered by a refetch.
export function useAddressFetch({ onSuccess }) {
  const fetcher = useMutation(getUserAddress, { onSuccess });
  const { isLoading, mutate, error } = fetcher;
  return {
    isLoading,
    error: error?.message,
    fetchAddress: mutate,
  };
}

export function useAddressUpdate({ onSuccess }) {
  // TODO: We may need to invalidate or overwrite the cache upon success
  const fetcher = useMutation(({ pid, address }) => updateUserAddress(pid, address), { onSuccess });
  const { isLoading, mutate, error } = fetcher;
  return {
    isLoading,
    error: error?.message,
    updateAddress: mutate,
  };
}

export function useAddressValidate({ onSuccess }) {
  const { isLoading, mutate, error } = useMutation(({ pid, address }) => validateUserAddress(pid, address), { onSuccess });
  return {
    isLoading,
    error: error?.message,
    validateAddress: mutate,
  };
}
