const login = {
  sms: {
    text: "¿Le gustaría recibir mensajes de texto SMS sobre los siguientes servicios mencionados en SABEResPODER.com u otros materiales proporcionados por Welcome Technologies a un número de teléfono que nos proporcione?",
    bullet1: "Soporte sobre servicios y/o temas y consultas generales que tenga",
    bullet2: "Participación en encuestas y paneles de investigación",
    bullet3: "Información sobre productos y servicios exclusivos",
  },
  accept_terms: "La frecuencia de los mensajes varía. Responda AYUDA para obtener ayuda o STOP para cancelar. Al seleccionar la caja usted acepta nuestros",
  terms_and_conditions: "Términos y Condiciones y Política de Privacidad",
  congrats_in_joining: "¡Le damos la bienvenida a la comunidad!",
  we_are_ready_to_help: "¡Disfrute de beneficios exclusivos!",
  enter_the_confirmation_code: "Ingrese el código de 5 dígitos",
  exit: "Salir",
  back_to_login: "Volver",
  submit_confirmation: "Enviar código",
  resend_label: "¿No recibió un código?",
  resend_code: "Reenviar",
  register: "Crear cuenta",
  login: "Iniciar sesión",
  code: "Código"
};

export default login;
