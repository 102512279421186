import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@separate/components/Modal';
import Spinner from '@separate/components/Spinner';
import styles from './processingModal.module.scss';

export default function ProcessingModal({ container, show, size, title }) {
  return(
    <Modal
      backdrop
      backdropClassName={styles.backdrop}
      bodyClassName={styles.body}
      centered
      className={styles.processingModal}
      close={false}
      container={container}
      onHide={() => {}}
      show={show}
      size={size}
    >
      <div className={styles.body}>
        <Spinner />
        <div className={styles.header}>
          {title}
        </div>
      </div>
    </Modal>
  );
}

ProcessingModal.propTypes = {
  container: PropTypes.any,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  title: PropTypes.string,
};

ProcessingModal.defaultProps = {
  size: 'sm',
};
