import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import SubscriptionOptionRadioCard from '@separate/components/CTA/SubscriptionOptionRadioCard';
import PaymentMethods from '@separate/components/PaymentMethods';
import AddNewCardOffcanvas from '@separate/components/PaymentMethods/AddNewCardOffcanvas';
import AlertModal from '@separate/components/PodersaludUpgradeSubscriptionPlan/AlertModal';
import { ADDING_DENTAL_PRODUCT } from '@separate/constants/features';
import { PREMIUM_ANNUAL_MEMBERSHIP_PRICE } from '@separate/constants/premium';
import { PLAN_INFORMATION, UPDATE_YOUR_PLAN, PAYMENT_METHODS } from '@separate/constants/profile';
import { useSubscriptionBundleUpdate } from '@separate/hooks/premium';
import { useTranslation, useLocale } from '@separate/hooks/translation';
import { useInvalidateCurrentUser } from "@separate/hooks/user";
import { Diamond, Save } from '@separate/icons/index';
import { formatProfileDate, addMonths } from '@separate/utilities/datetime';
import { isFeatureEnabled } from '@separate/utilities/features';
import { asCurrency } from '@separate/utilities/string';
import PaymentPlanSelector from '../Payment/PaymentPlanSelector';
import styles from './podersaludUpgradeSubscriptionPlan.module.scss';
import PodersaludUpgradeSubscriptionPlanModal from './PodersaludUpgradeSubscriptionPlanModal';

export default function PodersaludUpgradeSubscriptionPlan({
  container,
  subscriptionQuery,
  onDismiss,
  primaryPaymentMethodId,
  pid,
  textButton,
  title,
  allowPremiumPlanUpgrades,
}) {
  const [showAlert, setShowAlert] = React.useState(false);
  const [view, setView] = React.useState(PLAN_INFORMATION);
  const [showNewCard, setShowNewCard] = React.useState(false);
  const [isRetryPayment, setIsRetryPayment] = React.useState(false);
  const [hidePaymentMethods, setHidePaymentMethods] = React.useState(false);
  const [selectedSubscriptionBundle, setSelectedSubscriptionBundle] = React.useState(null);
  const changeAllowed = allowPremiumPlanUpgrades && (subscriptionQuery?.internal_status == 'active' || subscriptionQuery?.internal_status == 'free_trial');

  const { t } = useTranslation();
  const locale = useLocale();

  const featureDentalProduct = isFeatureEnabled(ADDING_DENTAL_PRODUCT);
  const invalidateCurrentUser = useInvalidateCurrentUser();

  const { isLoading: isLoadingUpdateSubscriptionBundle, updateSubscriptionBundle } = useSubscriptionBundleUpdate({
    onSuccess: onUpdateSubscriptionBundleSuccess,
    onError: onUpdateSubscriptionBundleError,
    isUpdate: true,
  });

  function onUpdateSubscriptionBundleSuccess() {
    invalidateCurrentUser();
    setShowAlert({ success: true });
  }

  function onUpdateSubscriptionBundleError() {
    setShowAlert({ failed: true });
  }

  const handleClickUpgradeNow = () => {
    setView(UPDATE_YOUR_PLAN);
  };

  const handleClickUpdateYourPlan = () => {
    setShowNewCard(false);
    setHidePaymentMethods(false);
    setView(PAYMENT_METHODS);
  };

  const handleClickAddNewCard = () => {
    setHidePaymentMethods(true);
    setShowNewCard(true);
  };

  const handleClickSuccessPayment = () => {
    setView(PLAN_INFORMATION);
    if(featureDentalProduct) {
      updateSubscriptionBundle({ bundleId: selectedSubscriptionBundle.id, pid: pid });
    }
  };

  const handleClickRetryPaymentMethod = () => {
    setHidePaymentMethods(false);
    setShowAlert({ failed: false });
    setIsRetryPayment(true);
    setView(PAYMENT_METHODS);
  };

  const handleHidePaymentMethods = () => {
    setHidePaymentMethods(true);
  };

  const nextPaymentDate = () => {
    return formatProfileDate(addMonths(selectedSubscriptionBundle?.price_interval_count));
  };

  return (
    <>
      <div className={styles.poderSaludSubscriptionPlan}>
        <div className={styles.priceContainer}>
          <Diamond />
          <div className={styles.info}>
            <p>
              {
                `${locale === 'es' ?
                  subscriptionQuery.subscription_bundle_name_es :
                  subscriptionQuery.subscription_bundle_name_en} `
              }
              <span className={styles.currency}>{`${asCurrency(
                subscriptionQuery?.price / 100
              )}`}</span>
            </p>
            <p>
              {`${t('premium.upgrade_subscription_plan.next_billing_date')} `}
              <span className={styles.date}>{`${formatProfileDate(
                subscriptionQuery?.next_payment_at
              )}`}</span>
            </p>
          </div>
        </div>
        <hr />
        {changeAllowed && (
          <div className={styles.saveContainer}>
            <div className={styles.info}>
              <Save />
              <p>{title ? title : t('premium.upgrade_subscription_plan.save_more')}</p>
            </div>
            <Button onClick={handleClickUpgradeNow}>
              {textButton ? textButton : t('premium.upgrade_subscription_plan.upgrade_plan')}
            </Button>
          </div>
        )}
      </div>

      {view === UPDATE_YOUR_PLAN && (
        <PodersaludUpgradeSubscriptionPlanModal
          onHide={() => setView(false)}
          title={t('premium.upgrade_subscription_plan.update_your_plan')}
          subtitle={featureDentalProduct ? '' : t('premium.upgrade_subscription_plan.save_over_50_per_year')}
          description={featureDentalProduct ? '' : t(
            'premium.upgrade_subscription_plan.you_will_be_charge_today_the_full_year',
            { next_payment: nextPaymentDate() }
          )}
          buttonText={t('premium.upgrade_subscription_plan.proceed_to_payment')}
          onClick={handleClickUpdateYourPlan}
          container={container}
        >
          {featureDentalProduct ?
            (
              <PaymentPlanSelector
                setSelectedSubscriptionBundle={setSelectedSubscriptionBundle}
                selectedSubscriptionBundle={selectedSubscriptionBundle}
                currentSubscription={subscriptionQuery}
              />
            )
            :
            (
              <SubscriptionOptionRadioCard
                isAnnual
                annualPrice={PREMIUM_ANNUAL_MEMBERSHIP_PRICE}
                className={styles.subscriptionRadioCard}
              />
            )}
        </PodersaludUpgradeSubscriptionPlanModal>
      )}

      {view === PAYMENT_METHODS && (
        <PodersaludUpgradeSubscriptionPlanModal
          onHide={() => setView(false)}
          container={container}
          onClick={handleClickSuccessPayment}
          hidePaymentMethods={hidePaymentMethods}
          isRetryPayment={isRetryPayment}
          handleClickAddNewCard={handleClickAddNewCard}
          title={
            isRetryPayment
              ? t('premium.upgrade_subscription_plan.revise_your_payment_method')
              : t('premium.upgrade_subscription_plan.select_payment_option')
          }
          buttonText={
            isRetryPayment
              ? t('premium.upgrade_subscription_plan.retry_with_this_card')
              : locale === 'es'
                ? t('premium.payment.pay_now')
                : t('premium.upgrade_subscription_plan.process_payment')
          }
          subtitle={isRetryPayment && t('premium.upgrade_subscription_plan.you_can_try_again')}
        >
          <PaymentMethods
            container={container}
            onClickAddNewCard={handleClickAddNewCard}
            primaryPaymentMethodId={primaryPaymentMethodId}
            isRetryPayment={isRetryPayment}
            handleHidePaymentMethods={handleHidePaymentMethods}
            pid={pid}
          />
        </PodersaludUpgradeSubscriptionPlanModal>
      )}

      <AddNewCardOffcanvas
        container={container}
        onHide={() => setShowNewCard(false)}
        show={showNewCard}
        onSuccess={handleClickSuccessPayment}
        onCancel={handleClickUpdateYourPlan}
        isUpgradeSubscriptionPlan
        pid={pid}
        onCardDecline={onUpdateSubscriptionBundleError}
      />

      <AlertModal
        onHide={() => onDismiss()}
        container={container}
        isLoading={isLoadingUpdateSubscriptionBundle}
        success={showAlert.success}
        failed={showAlert.failed}
        onDismiss={onDismiss}
        retryPaymentMethods={handleClickRetryPaymentMethod}
        nextPayment={nextPaymentDate()}
      />
    </>
  );
}

PodersaludUpgradeSubscriptionPlan.propTypes = {
  container: PropTypes.any,
  subscriptionQuery: PropTypes.object,
  onDismiss: PropTypes.func,
  primaryPaymentMethodId: PropTypes.string,
  pid: PropTypes.string,  // for zendesk-sep-admin
  title: PropTypes.string,
  textButton: PropTypes.string,
  allowPremiumPlanUpgrades: PropTypes.bool,
};
