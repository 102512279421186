const login = {
  sms: {
    text: "Would you like to receive SMS text messages about the following topics mentioned on SABEResPODER.com or other materials provided by Welcome Technologies to a phone number provided?",
    bullet1: "Support about services and/or topics and general inquiries you may initiate",
    bullet2: "Participation in surveys and research panels",
    bullet3: "Information about select products and services",
  },
  accept_terms: "Message frequency varies. Reply HELP for help or STOP to cancel. You also agree to our",
  terms_and_conditions: "Terms and Conditions and Privacy Policy",
  congrats_in_joining: "Congrats on your interest in joining our community!",
  we_are_ready_to_help: "We are ready to help you with your specific needs.",
  enter_the_confirmation_code: "Enter the confirmation code",
  exit: "Exit",
  back_to_login: "Back",
  submit_confirmation: "Submit",
  resend_label: "Didn't receive a code?",
  resend_code: "Resend",
  register: "Register",
  login: "Login",
  code: "Code"
};

export default login;
