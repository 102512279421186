import React from 'react';
import { useRouter } from 'next/router';
import { useLocale } from '@separate/hooks/translation';

export const useGetUrl = () => {
  const router = useRouter();
  const locale = useLocale();

  const rootUrl = typeof window !== 'undefined' ? window.location.origin : '';
  const localePath = locale === 'en' ? '/en' : '';
  const canonicalUrl = `${rootUrl}${localePath}${router?.asPath === '/' ? '' : router?.asPath?.split("?")[0]}`;

  const fullyQualify = React.useCallback((href) => {
    return `${rootUrl}${localePath}${href}`;
  }, [rootUrl, localePath]);

  return { rootUrl, canonicalUrl, localePath, asPath: router?.asPath, fullyQualify };
};
