import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import PaymentMethods from '@separate/components/PaymentMethods';
import MissedPaymentFlowOffcanvas from '@separate/components/PaymentMethods/MissedPaymentFlowOffcanvas';
import PodersaludUpgradeSubscriptionPlan from '@separate/components/PodersaludUpgradeSubscriptionPlan';
import AlertModal from '@separate/components/PodersaludUpgradeSubscriptionPlan/AlertModal';
import { ADDING_DENTAL_PRODUCT, ALLOW_PREMIUM_PLAN_UPGRADES } from '@separate/constants/features';
import { useSubscriptionQuery } from '@separate/hooks/premium';
import { useTranslation } from '@separate/hooks/translation';
import { useQueryUserProfile } from '@separate/hooks/user';
import { formatLocalDateSimple } from '@separate/utilities/datetime';
import { isFeatureEnabled } from '@separate/utilities/features';
import { asCurrency } from '@separate/utilities/string';
import InfoRow from '../Main/BasicInfo/InfoRow';
import styles from './accountInfo.module.scss';

export default function AccountInfo({ onCancel, onDismiss, onSuccess, missedPayment }) {
  const [showAlert, setShowAlert] = React.useState(false);
  const { isLoading, subscription } = useSubscriptionQuery({ fastRefresh: true });
  const { t } = useTranslation();
  const { user } = useQueryUserProfile();
  const [isMonthIntervalActive, setIsMonthIntervalActive] = React.useState(false);

  const allowPremiumPlanUpgrades = isFeatureEnabled(ALLOW_PREMIUM_PLAN_UPGRADES);
  const featureDentalProduct = isFeatureEnabled(ADDING_DENTAL_PRODUCT);

  const intervalMapping = {
    year: {
      interval: t('user.profile.annual_interval'),
      plan: t('user.profile.annual_plan'),
    },
    month: {
      interval: t('user.profile.monthly_interval'),
      plan: t('user.profile.monthly_plan'),
    },
  };

  const planInterval = intervalMapping[subscription?.interval || 'month'];

  React.useEffect(() => {
    if(isLoading) return;

    if(subscription?.interval === 'month' && subscription?.status === 'active') {
      setIsMonthIntervalActive(true);
    }

  }, [isLoading, subscription?.interval, subscription?.status]);

  const ref = React.createRef();

  const handleFailure = () => {
    setShowAlert({ failed: true });
  }

  return (
    <div className={styles.accountInfo} ref={ref}>
      <div className='d-flex w-100 justify-content-between mb-3'>
        <h4 className={styles.ctaTitle}>
          {isMonthIntervalActive ? t("user.profile.plan_information") : t('user.profile.account_info')}
        </h4>
        <Button
          onClick={onCancel}
          className={styles.editCta}
        >
          {t("user.profile.cancel_edit_cta")}
        </Button>
      </div>

      {user && subscription && (
        <>
          {!isMonthIntervalActive && (
            <div className={styles.infoContainer}>
              <InfoRow label={t('user.profile.plan')}>
                {`${planInterval?.plan}`}
              </InfoRow>
              <InfoRow label={t('user.profile.price')}>
                {`${asCurrency(subscription.price / 100)} / ${planInterval?.interval}`}
              </InfoRow>
              {subscription.next_payment_at && (
                <InfoRow label={t('user.profile.next_billing')}>
                  {`${formatLocalDateSimple(subscription.next_payment_at)}`}
                </InfoRow>
              )}
            </div>
          )}

          {(isMonthIntervalActive || featureDentalProduct) && (
            <PodersaludUpgradeSubscriptionPlan
              container={ref}
              subscriptionQuery={subscription}
              onDismiss={onDismiss}
              primaryPaymentMethodId={subscription?.payment_method_id}
              allowPremiumPlanUpgrades={allowPremiumPlanUpgrades}
            />
          )}

          <PaymentMethods
            container={ref}
            primaryPaymentMethodId={subscription.payment_method_id}
            title={t('user.profile.payment_methods')}
            onCardDecline={handleFailure}
          />
          {missedPayment && (
            <MissedPaymentFlowOffcanvas
              container={ref}
              primaryPaymentMethodId={subscription.payment_method_id}
              show={missedPayment}
              onDismiss={onDismiss}
              onSuccess={onSuccess}
            />
          )}
          <AlertModal
            onHide={onDismiss}
            success={showAlert.success}
            failed={showAlert.failed}
            onDismiss={onDismiss}
            retryPaymentMethods={() => setShowAlert(false)}
          />
        </>
      )}
    </div>
  );
}

AccountInfo.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  onSuccess: PropTypes.func,
  missedPayment: PropTypes.bool,
};
