const menu = {
  home: 'Discover',
  earn: 'Earning',
  chat: 'Customer Support',
  articles: 'Knowledge Hub',
  premium: 'Health',
  farmaSEP: "farmaSEP",
  digital_banking: 'Banking',
  jobs_network: 'Jobs network',
  profile: 'Your account',
  about: 'About us'
};

export default menu;
