import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/v4/components/Button';
import ProfileModal from '../ProfileModal';
import { AvatarLight } from '@/icons';
import RenderLoginModal from '../RenderLoginModal';
import { PROFILE_VIEW } from '@separate/constants/analytics';
import { useCurrentUser } from '@separate/hooks/user';
import { logEvent } from '@separate/utilities/analytics';
import styles from './loginButton.module.scss';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useIsSingleSigningOn } from '@separate/hooks/singleSignOn';

export default function LoginButton(props) {
  const { className, ...extraProps } = props;
  const { isLoggedIn, user } = useCurrentUser();
  const { hasSingleSignedOn } = useIsSingleSigningOn();

  const router = useRouter();

  const [showLogin, setShowLogin] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);

  function setShow() {
    if(isLoggedIn) {
      logEvent(PROFILE_VIEW);
      setShowProfile(true);
    } else {
      setShowLogin(true);
    }
  }

  function hideLogin() {
    setShowLogin(false);
  }

  function hideProfile() {
    setShowProfile(false);
    if (router.query.showProfile === 'true') {
      router.replace(router.pathname);
    };
  }

  React.useEffect(() => {
    if (hasSingleSignedOn || isLoggedIn) {
      if (!user?.is_premium_member) return;
      if (router.query.showProfile === 'true') {
        setShowProfile(true);
      };
    }
  }, [hasSingleSignedOn]);

  return (
    <>
      <Button onClick={setShow} className={cx(styles.button, className)}>
        <AvatarLight className={styles.avatar} />
      </Button>
      {isLoggedIn
        ? <ProfileModal show={showProfile} onHide={hideProfile} />
        : <RenderLoginModal show={showLogin} onHide={hideLogin} />
      }
    </>
  );
}

LoginButton.propTypes = {
  className: PropTypes.string,
};
