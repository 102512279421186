const validations = {
  is_required: "{{ field }} no puede estar vacío",
  invalid: "{{ field }} no es válido",
  at_least_2_characters: "{{ field }} debe tener al menos 2 caracteres",
  at_most_35_characters: "{{ field }} debe tener como máximo 35 caracteres",
  invalid_characters: "{{ field }} contiene caracteres inválidos",
  exactly_5_characters: "{{ field }} debe tener exactamente 5 caracteres",
  numeric: "{{ field }} debe contener solo números",
  invalid_phone: "Favor de ingresar un número de teléfono válido",
  incorrect: "{{field}} incorrecto",
  invalid_confirmation_code: "Este campo no es válido",
  invalid_phone_number: "Favor de ingresar un número de teléfono válido",
  phone_already_taken: "Ese número ya ha sido registrado",
};

export default validations;
