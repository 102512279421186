import React from 'react';
import PropTypes from 'prop-types';
import SuccessModal from '@separate/components/SuccessModal';
import { useTranslation } from '@separate/hooks/translation';
import { DependentWarning } from '@separate/icons';
import styles from './index.module.scss';

export default function AddOrEditDependentModal({ show, onHide, container, description, title }) {
  const { t } = useTranslation();

  const handleHide = () => {
    onHide();
  };

  return (
    <SuccessModal
      show={show}
      onHide={handleHide}
      icon={<DependentWarning />}
      title={title}
      description={description}
      buttonText={t('close')}
      container={container}
      bodyClassName={styles.body}
    />
  );
}

AddOrEditDependentModal.propTypes = {
  container: PropTypes.any,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  title: PropTypes.string.isRequired,
};
