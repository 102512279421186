import dayjs from 'dayjs';
import custom from 'dayjs/plugin/customParseFormat';
import { presence } from '@separate/utilities/string';

dayjs.extend(custom);

export default function dateMonthYearValidator(value) {
  if (!presence(value)) return null;

  if (!dayjs(value, 'MM/YYYY', true).isValid()) {
    return 'invalid';
  }

  return null;
}
