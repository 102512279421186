export const client = typeof window !== "undefined";
export const UNAUTH_API_PATH = '/v1/unauth';
export const AUTH_API_PATH = '/v1/auth';
export const ADMIN_UNAUTH_ENDPOINT = `${process.env.NEXT_PUBLIC_ADMIN_URL}${UNAUTH_API_PATH}`;
export const ADMIN_AUTH_ENDPOINT = `${process.env.NEXT_PUBLIC_ADMIN_URL}${AUTH_API_PATH}`;

export const OPENID_IDLE_TIME = parseInt(process.env.NEXT_PUBLIC_OPENID_IDLE_TIME);

export const AWS_GATEWAY_CUSTOM_DOMAIN = process.env.NEXT_PUBLIC_AWS_GATEWAY_CUSTOM_DOMAIN;
export const AWS_GATEWAY_REGION = process.env.NEXT_PUBLIC_AWS_GATEWAY_REGION;
export const AWS_GATEWAY_SERVICE = process.env.NEXT_PUBLIC_AWS_GATEWAY_SERVICE;
export const AWS_STS_ROLE_ARN = process.env.NEXT_PUBLIC_AWS_STS_ROLE_ARN;
export const AWS_STS_SESSION_NAME = process.env.NEXT_PUBLIC_AWS_STS_SESSION_NAME;

export const CONTENT_STACK_ARTICLES = process.env.NEXT_PUBLIC_CONTENT_STACK_ARTICLES;
export const CONTENT_STACK_API_KEY = process.env.NEXT_PUBLIC_CONTENT_STACK_API_KEY;
export const CONTENT_STACK_DELIVERY_TOKEN = process.env.NEXT_PUBLIC_CONTENT_STACK_DELIVERY_TOKEN;
export const CONTENT_STACK_ENV = process.env.NEXT_PUBLIC_CONTENT_STACK_ENV;
export const CONTENT_STACK_LANDING_PAGES = process.env.NEXT_PUBLIC_CONTENT_STACK_LANDING_PAGES;
export const CONTENT_STACK_LEGAL = process.env.NEXT_PUBLIC_CONTENT_STACK_LEGAL || 'legal_disclosure';
export const CONTENT_STACK_PAGINATE_AFTER = parseInt(process.env.NEXT_PUBLIC_CONTENT_STACK_PAGINATE_AFTER || 10, 10);

export const STRIPE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
export const SEGMENT_URL = process.env.NEXT_PUBLIC_SEGMENT_URL;
export const BUGSNAG_KEY = process.env.NEXT_PUBLIC_BUGSNAG_KEY;

export const PODERCARD_LINK = process.env.NEXT_PUBLIC_PODERCARD_LINK;
export const CREDITCARD_BANNER_LINK = process.env.NEXT_PUBLIC_CREDITCARD_BANNER_LINK;

export const NUM_FEATURED_ARTICLES = parseInt(process.env.NUM_FEATURED_ARTICLES || 5, 10);

export const GOOGLE_TAG_KEY = process.env.NEXT_PUBLIC_GOOGLE_TAG_KEY;

export const FARAMASEP_ENDPOINT = process.env.NEXT_PUBLIC_FARMASEP_ENDPOINT;
export const BRAZE_API_KEY = process.env.NEXT_PUBLIC_BRAZE_API_KEY;
export const BRAZE_SDK_ENDPOINT_URL = process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT_URL;
export const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV;

export const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;

export const API_MOCKING = process.env.NEXT_PUBLIC_API_MOCKING;

export const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST;
export const POSTHOG_PROJECT_KEY = process.env.NEXT_PUBLIC_POSTHOG_PROJECT_KEY;
