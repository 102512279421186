const meta = {
  article_categories: {
    coronavirus: {
      title: '【Noticias y guías sobre el COVID-19 ✔️】',
      description: 'Aprenda a combatir al coronavirus con nuestras guías definitivas. ✔️ Los mejores consejos, artículos y noticias para el día a día.'
    },
    finance: {
      title: '【Guías y artículos de finanzas personales en EE.UU. ✔️】',
      description: '¿Quiere mejorar sus finanzas personales? ✔️ En SABEResPODER le traemos las mejores guías, trucos y consejos.'
    },
    health: {
      title: '【Consejos, guías y artículos de salud y bienestar ✔️】',
      description: 'Siga estos consejos y trucos para mejorar su salud y la de sus familiares y amigos. ✔️ ¡Los mejores artículos sobre salud y bienestar!'
    },
    education: {
      title: '【Guías educativas para usted y sus hijos ✔️ 】',
      description: 'Si reside en Estados Unidos, estos consejos sobre educación le interesan. ✔️ Invierta en la educación de sus hijos con estas guías.'
    },
    legal: {
      title: '【Información legal para latinos en EE.UU. ✔️】',
      description: 'En nuestra sección legal encontrará los mejores artículos y guías sobre temas legales para la comunidad latina en EE.UU. ✔️ ¡Entre ya!'
    }
  },
  articles: {
    title: '【Noticias, guías y ayuda para la comunidad latina en EE.UU.】',
    description: 'En la web de SABEResPODER encontrará la mejor ayuda para la comunidad latina en EE.UU. ✔️ ¡Descubra nuestros artículos y guías!'
  },
  earn: {
    title: '【Gane dinero con encuestas ✔️ Grupo de Opinión】',
    description: '¡Empiece ya a ganar dinero! ✔️ ¡Con los grupos de opinión de SABEResPODER, podrá ganar dinero completando encuestas a través de Internet.'
  },
  farmasep: {
    title: '【Descuentos para prescripciones en EE.UU. ✔️】',
    description: '¡Consiga descuento en sus compras de medicamentos! ✔️ Con FarmaSEP ahorre mientras cuida su salud y la de sus familiares.'
  },
  podercard: {
    title: '【Banca digital para la comunidad ✔️ PODERcard】',
    description: 'Con PODERcard, tome el control de sus finanzas. ✔️ Sin comisiones de mantenimiento. ✔️ Aplicación móvil gratuita.'
  },
  premium: {
    title: '【Plan de descuentos médicos ✔️  PODERsalud】',
    description: '¡Obtenga descuentos en servicios de salud! ✔️ Acceda a consultas médicas ilimitadas desde su celular y dentistas con PODERsalud.'
  }
};

export default meta;
