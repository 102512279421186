const about = {
  main: {
    header: "About us",
    subheader: "Best-in-class services built for you and your family",
    cta: "Sign up today!",
  },
  about: {
    header: "About",
    items: [
      "When it comes to immigration, many immigrants, and their multi-generational families, lack the critical education, tools and resources needed to successfully navigate life in the U.S. SABEResPODER was created to address their practical, everyday needs. Our offerings are in language, in culture, and available via mobile devices and apps.",
      "As members of first-generation immigrant households themselves, our founders, Raul Lomeli and Amir Hemmat understood that they had an opportunity, and obligation, to make the immigrant’s journey easier. SABEResPODER was created to meet the needs of immigrants in the U.S. facing language and cultural barriers as they attempt to make informed decisions.",
      "Today, over 4 million Latinos trust our platform as a credible resource for information, education and world-class services."
    ]
  },
  mission: {
    quote: "Our mission is to create a world where anyone can succeed anywhere.",
    span: "- Amir Hemmat, CEO"
  },
  help: {
    header: "How does SABEResPODER help you?",
    content: "Immigrating to a new country takes an inordinate amount of courage and fortitude. Once one arrives, adapting to a new language and culture can be challenging, isolating and filled with uncertainty. Our goal is to help relieve the uneasiness and restore predictability."
  },
  services: {
    header: "Explore Services",
    items: [
      {
        title: "Financial",
        span: "Managing your finances has never been easier",
        content: "PODERcard Debit is an all-in-one banking platform that makes managing your finances effortless.",
        mobileContent: "You have more access and visibility on your finances with the digital bank account and debit card that are free of monthly maintenance charges and hidden fees",
      },
      {
        title: "Rewards Center",
        span: "Earn Cash Rewards for Your Opinion",
        content: "Earn monetary rewards for participating in important surveys. Earn monetary rewards for participating in important surveys.",
        mobileContent: "Earn monetary rewards for participating in important surveys.",
      },
      {
        title: "Healthcare",
        span: "Access to English and Spanish - Speaking Doctors 24/7",
        content: "PODERsalud is an affordable health benefits offering for you and your family. Access telemedicine, dental, vision, and more.",
        mobileContent: "Our subscription offering allows you to pay a minimum monthly fee for yourself and your family, to secure medical services (including dental, lab-related and pharmaceutical) anytime and anywhere, from a mobile device or virtually. Our medical professionals speak Spanish as well.",
      },
      {
        title: "Educational Resources",
        span: "Expert assistance navigating U.S. services",
        content: "We have your back! Our in-depth Help Center and customer success agents are here to support you.",
        mobileContent: "The resource center offers guides on every important aspect of life, from your kids' healthcare, to starting your first business. In addition, our QA service will answer any question you may have - we are here to help!",
      },
    ],
    cta: "Learn More"
  },
  chat: {
    header: "Do you have any questions?",
    content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Chat with an expert"
  },
  faq: {
    header: "Frequently Asked Questions",
    items: [
      {
        title: "What is SABEResPODER?",
        content: "SABEResPODER serves as a trusted advisor for a community of over 4 million individuals, empowering them with the knowledge and tools required to make informed choices, enhance their quality of life and attain greater financial and social stability.",
      },
      {
        title: "How can SABEResPODER help me?",
        content: "We are here to promote improved wellbeing with best-in-class health resources, increased financial strength, income generation through online earning opportunities, and trusted information to achieve a more fulfilling life in the United States.",
      },
      {
        title: "Is SABEResPODER free?",
        content: "It is free to join our community, receive trusted information, and start earning. We provide other best-in-class services at affordable rates, promoting physical and financial wellbeing.",
      },
      {
        title: "Is SABEResPODER available outside of the US?",
        content: "Our trusted content is available outside of the United States, but services like our healthcare savings are available only in the U.S. territory. Contact an agent to get a full breakdown of where our services are offered and whether they are right for you.",
      }
    ],
    more: {
      title: "Do you have any questions?",
      content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Chat with an expert"
    }
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Refer to the Rewards and Redemption section of our terms and conditions for full details of the program offering.",
      "PODERcard account opening is subject to identity verification by Thread Bank. Welcome Tech dba SABEResPODER is not a bank. Banking services provided by Thread Bank; Member FDIC. The PODERcard Visa® Debit Card is issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted. Your funds are FDIC insured up to $250,000 through Thread Bank; Member FDIC. You can apply using your Passport, ITIN or SSN",
      "TBD"
    ]
  }
};

export default about;
