// Log-in events
export const RESEND_CODE_ERROR = 'resend_code_error_status';

export const CHECKOUT_STARTED = 'checkout_started';
export const CHECKOUT_STEP_COMPLETED = 'checkout_step_completed';
export const CHECKOUT_STEP_VIEWED = 'checkout_step_viewed';
export const PAYMENT_FAILED = 'payment_failed';
export const PAYMENT_INFO_SUBMITTED = 'payment_info_submitted';
export const PAYMENT_METHOD_ADDED = 'payment_method_added';
export const PAYMENT_METHOD_ADD_FAILURE = 'payment_method_add_failure';
export const PAYMENT_METHOD_DEFAULT_FAILURE = 'payment_method_default_failure';
export const PAYMENT_METHOD_DEFAULT_SUCCESS = 'payment_method_default_success';
export const PAYMENT_METHOD_DELETE_FAILURE = 'payment_method_delete_failure';
export const PAYMENT_METHOD_DELETE_SUCCESS = 'payment_method_delete_success';
export const CTA_CLICKED = 'cta_clicked';
export const HAMBURGER_MENU_CLICKED = 'hamburger_menu_clicked';
export const PROFILE_VIEW = 'profile_viewed';
export const PROFILE_UPDATED = 'profile_updated';

// Original events from utitlies/analytics
export const BENEFIT_ENGAGEMENT_COMPLETED = 'benefit_engagement_completed';
export const PRODUCT_INTEREST = 'product_interest';
export const SSO_SUCCESSFUL = 'sso_successful';

export const PANELIST_PRODUCT_ID = 4;
export const PARTICIPATION_PRODUCT_ID = 9;
export const PODERCARD_PRODUCT_ID = 6;
export const PODERSALUD_PRODUCT_ID = 10;
export const JOBS_PRODUCT_ID = 26;

// Events for Rewards:
export const LINK_CLICK = 'link_click';
export const MODAL_EXIT = 'modal_exit';
export const MODAL_OPEN = 'modal_open';
export const REWARDS_ENGAGEMENT_STARTED = 'rewards_engagement_started';
export const REWARDS_ENGAGEMENT_ENDED = 'rewards_engagement_ended';
export const REWARDS_OPPORTUNITY_VIEWED = 'rewards_opportunity_viewed';

// Events for PODERsalud
export const PODERSALUD_PAYMENT_INFO_SUBMITTED = 'payment_info_submitted';
export const CALL_REQUESTED_BY_USER = 'call_requested_by_user';

// Types of engagement:
export const SURVEY = 'survey';
export const STUDY = 'study';
export const LUCID = 'lucid';

// Events for Jobs:
export const JOBS_FETCH = 'jobs_fetch';
export const JOBS_PROFILE_STARTED = 'jobs_profile_started';
export const JOBS_PROFILE_EDITED = 'jobs_profile_edited';
export const JOBS_AD_CLICK_COMPLETED = 'job_ad_click_completed';
export const JOBS_AD_MODAL_VIEWED = 'job_ad_modal_viewed';
export const JOBS_AD_MODAL_CLOSED = 'job_ad_modal_closed';
export const JOBS_VIEW_ALL_CLICKED = 'jobs_view_all_clicked';
export const LOCATION_UPDATE_STARTED = 'location_update_started';
export const LOCATION_UPDATED = 'location_updated';

// Events for New Rewards Enrollment:
export const RACE_AND_ETHNICITY = 'race_and_ethnicity';
export const HISPANIC = 'hispanic';
export const ETHNICITY ='ethnicity';
export const LANGUAGE = 'language';
export const SURVEY_LANGUAGE_PREFERENCE = 'survey-language-preference';

// Events for Short Codes fetch and update:
export const SHORT_CODES = 'short_codes';

// Events for Dependents feature
export const DEPENDENTS_FETCH = 'dependents_fetch';

// Events for Refer a Friend
export const REFER_A_FRIEND_INITIATED = 'refer_a_friend_initiated';
export const REFER_A_FRIEND_MESSAGE_GENERATED = 'refer_a_friend_message_generated';

// Events for Add Dependent
export const ADD_DEPENDENT_INITIATED = 'add_dependent_initiated';
export const ADD_DEPENDENT_SUBMITTED = 'add_dependent_submitted';
export const ADD_DEPENDENT_COMPLETED = 'add_dependent_completed';
export const ADD_DEPENDENT_FAILED = 'add_dependent_failed';
