const login = {
  sms: {
    text: "Would you like to receive SMS text messages about the following topics mentioned on SABEResPODER.com or other materials provided by Welcome Technologies to a phone number provided?",
    new_text: "By joining our community, you are agreeing to receive SMS text messages about the following topics to the phone number provided: ",
    bullet1: "Support about services and/or topics and general inquiries you may initiate",
    bullet2: "Participation in surveys and research panels",
    bullet3: "Information about select products and services",
  },
  accept_terms: "Message frequency varies. Reply HELP for help or STOP to cancel. You also agree to our",
  terms_and_conditions: "Terms and Conditions and Privacy Policy",
  congrats_in_joining: "Congrats on your interest in joining our community!",
  we_are_ready_to_help: "We are ready to help you with your specific needs.",
  enter_the_confirmation_code: "Enter the confirmation code",
  exit: "Exit",
  back_to_login: "Back",
  submit_confirmation: "Submit",
  resend_label: "Didn’t get it?",
  resend_code: "Resend",
  register: "Register",
  login: "Login",
  code: "Code",
  header: {
    default_title: 'Welcome! Join our community!',
    default_title_two: 'Almost there!',
    premium_title: 'Premium Page Login',
    premium_title_two: 'Premium Page Register',
    subtitle: 'Get started by entering your mobile number to start benefiting from our many offerings.',
  },
  sign_up: 'Sign up',
  privacy: 'We take privacy very seriously, we will never share your phone number.',
  footer: {
    text: 'Got a question? Our customer support agents are more than happy to assist you! ',
    number: '(726) 202 1213',
  },
  default_final_step: 'Final step!',
  default_welcome_back: 'Welcome back!',
  premium_final_step: 'Premium Page Final step!',
  premium_welcome_back: 'Premium Page Welcome Back!',
  please_enter_5_digit_code: 'Please enter the 5-digit code that was sent to your mobile device.',
  enter_code: 'Verify code',
};

export default login;
