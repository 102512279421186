import React from 'react';
import { useSubscriptionQuery } from '@separate/hooks/premium';
import { useCurrentUser } from '@separate/hooks/user';
import { isSubscriptionPastDue } from '@separate/utilities/premium';
import { useDeepLinkUpdatePaymentModal } from './updatePayment';

export function useGetMissedPayment() {
  const { user } = useCurrentUser();

  const [pastDue, setPastDue] = React.useState(false);
  const [showMissedPaymentModal, setShowMissedPaymentModal] = React.useState(false);
  const [showMissedPaymentBanner, setShowMissedPaymentBanner] = React.useState(false);
  const [status, setStatus] = React.useState();

  const { subscription } = useSubscriptionQuery({});
  const { showDeepLinkUpdatePaymentModal } = useDeepLinkUpdatePaymentModal();

  const userHasMissedPayment = pastDue && user?.is_premium_member;

  React.useEffect(() => {
    if (!subscription) return;

    setPastDue(isSubscriptionPastDue(subscription));
    setStatus(subscription.status);
  }, [subscription]);

  React.useEffect(() => {
    const turnOffMissedPayment = () => {
      setShowMissedPaymentModal(false);
      setShowMissedPaymentBanner(false);
    };

    if (userHasMissedPayment && !showDeepLinkUpdatePaymentModal) {
      setShowMissedPaymentModal(true);
    } else {
      turnOffMissedPayment();
    }

    return () => {
      turnOffMissedPayment();
    };
  }, [pastDue, showDeepLinkUpdatePaymentModal, status, userHasMissedPayment]);

  return {
    userHasMissedPayment,
    showMissedPaymentModal,
    setShowMissedPaymentModal,
    showMissedPaymentBanner,
    setShowMissedPaymentBanner,
  };
}
