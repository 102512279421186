import React from 'react';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import { useTranslation } from '@separate/hooks/translation';
import styles from './deleteCardConfirmModal.module.scss';

export default function DeleteCardConfirmModal({ container, onDelete, onHide, show }) {
  const { t } = useTranslation();

  function handleDelete() {
    onDelete();
    handleHide();
  }

  function handleHide() {
    onHide();
  }

  return (
    <Modal
      backdrop
      backdropClassName={styles.backdrop}
      bodyClassName={styles.body}
      centered={true}
      className={styles.confirmModal}
      close={false}
      container={container}
      onHide={handleHide}
      show={show}
      size="sm"
    >
      <div className={styles.body}>
        <div className={styles.header}>
          {t('user.profile.delete_payment.header')}
        </div>
        <div className={styles.bodyText}>
          {t('user.profile.delete_payment.body')}
        </div>
        <div className={styles.buttonRow}>
          <Button className={styles.cancelButton} onClick={handleHide}>
            {t('user.profile.delete_payment.cancel')}
          </Button>
          <Button className={styles.deleteButton} onClick={handleDelete}>
            {t('user.profile.delete_payment.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DeleteCardConfirmModal.propTypes = {
  container: PropTypes.any,
  onDelete: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
