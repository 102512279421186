import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@separate/hooks/translation';
import { unkeyedErrorsType } from '@separate/types';
import { fieldErrorsToArray } from '@separate/utilities/validations';
import styles from './fieldErrorList.module.scss';
import cx from 'classnames';

export default function FieldErrorsList(props) {
  const { fieldErrors, unkeyedErrors } = props;

  const { t } = useTranslation();

  const errorMessages = fieldErrorsToArray(fieldErrors);

  function errorToString(error) {
    if (typeof error === 'string') {
      return error;
    }
    return t(`validations.${error.error}`, { field: t(error.field), interpolation: { escapeValue: false } });
  }

  return (
    <>
      {errorMessages.length > 0
        ? <>
            <ul className={cx(styles.list, 'mb-0')}>
              {errorMessages.map((error, index) => (
                <li className="text-danger" key={index}>
                  {errorToString(error)}
                </li>
              ))}
              {unkeyedErrors?.map((error) => (
                <li className="text-danger" key={error}>
                  {error}
                </li>
              ))}
            </ul>
          </>
        : <></>
      }
    </>
  );
}

FieldErrorsList.propTypes = {
  fieldErrors: PropTypes.object,
  unkeyedErrors: unkeyedErrorsType,
};

FieldErrorsList.defaultProps = {
  fieldErrors: {},
};
