import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Button from '@separate/components/Button';
import Modal from '@separate/components/Modal';
import styles from './successModal.module.scss';

export default function SuccessModal({
  buttonText,
  container,
  description,
  icon,
  onHide,
  show,
  size,
  title,
  bodyClassName,
  contentClassName,
}) {
  function handleHide() {
    onHide();
  }

  return(
    <Modal
      backdrop
      backdropClassName={styles.backdrop}
      bodyClassName={cx(styles.body, bodyClassName)}
      contentClassName={contentClassName}
      centered
      className={styles.successModal}
      close={false}
      container={container}
      onHide={handleHide}
      show={show}
      size={size}
    >
      <div className={styles.body}>
        {icon}
        <div className={styles.header}>
          {title}
        </div>
        <div className={styles.bodyText}>
          {description}
        </div>
        <Button className={styles.button} onClick={handleHide}>
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
}

SuccessModal.propTypes = {
  buttonText: PropTypes.string,
  container: PropTypes.any,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  icon: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  title: PropTypes.string,
  bodyClassName: PropTypes.string,
  contentClassName: PropTypes.string,
};

SuccessModal.defaultProps = {
  size: 'sm',
};
