import { useRouter } from 'next/router';
import { useTranslation as i18nUseTranslation } from 'react-i18next';
import i18n from '../i18n';

export function useV4Translation() {
  return i18nUseTranslation();
}

export function useLocale() {
  const router  = useRouter();

  return router ? router.locale : i18n.language;
}
